import api, { User, apiUtils } from '@api';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import {
    createContext, Dispatch, SetStateAction, useCallback, useMemo, useState
} from 'react';
import { mutate, preload } from 'swr';


export type AuthUser = Pick<User, 'id' | 'firstName' | 'lastName' | 'encompassUserEmail' | 'frameworkRole'>;

export interface AuthenticationContextValues {
    initializeSession: () => Promise<void>;
    logout: () => Promise<void>;
    user?: AuthUser;
    setUser: Dispatch<SetStateAction<AuthUser | undefined>>;
    isInitialized?: boolean;
}

export const AuthenticationContext = createContext<AuthenticationContextValues>({
    initializeSession: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    setUser: () => {}
});

/**
 * Helper function to capture exceptions that aren't related to the user not being logged in yet when preloading
 *
 * @param apiFunction - The api function to preload
 */
async function capturePreloadException<T>(apiFunction: () => Promise<T>) {
    try {
        return await apiFunction();
    } catch (error) {
        if (error !== 'No current user') {
            Sentry.captureException(error);
        }
    }
}

preload('/license', () => capturePreloadException(api.framework.getCurrentLicense));
preload('/tenant', () => capturePreloadException(api.framework.getCurrentTenant));

export function useAuthenticationContextValue(): AuthenticationContextValues {
    const [ isInitialized, setIsInitialized ] = useState(false);
    const [ user, setUser ] = useState<AuthUser>();

    const initializeSession = useCallback(async () => {
        try {
            const session = await Auth.currentSession();
            const { payload } = session.getIdToken();

            const { roles, firstName = 'First', lastName = 'Last' } = await apiUtils.decodeFrameworkToken();

            const user = {
                id: payload.sub,
                firstName,
                lastName,
                encompassUserEmail: payload.email,
                frameworkRole: (roles.includes('framework:admin')
                    ? 'admin' as const
                    : 'user' as const)
            };

            Sentry.setUser(user);
            setUser(user);
        } catch (error) {
            throw error;
        } finally {
            setIsInitialized(true);
        }

        api.webSocket.open();
    }, []);

    const logout = useCallback(async () => {
        await Auth.signOut();
        await mutate(() => true, undefined, { revalidate: false });

        Sentry.setUser(null);
        setUser(undefined);
        apiUtils.clearFrameworkAccessToken();
        api.webSocket.close();
    }, []);

    return useMemo(() => ({
        isInitialized,
        user,
        setUser,
        initializeSession,
        logout
    }), [
        isInitialized, user, initializeSession, logout
    ]);
}
