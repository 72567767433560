import {
    Alert, Authenticator, Button, PasswordField, TextField, useAuthenticator
} from '@aws-amplify/ui-react';
import { Header } from '@components';
import { Link, Typography } from '@mui/material';
import { useDispatch } from '@redux/store';
import { openSupportDialog } from '@redux/support';
import { Loader } from '@tsp-ui/components';
import { useRouteState, useAsyncEffect } from '@tsp-ui/utils';
import clsx from 'clsx';
import { Location, LocationDescriptor } from 'history';
import {
    ComponentProps, Dispatch, KeyboardEvent, SetStateAction, useCallback, useContext, useState
} from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import { AuthenticatorServiceFacade } from '../../../node_modules/@aws-amplify/ui/dist/types';
import { AuthenticationContext } from '../../AuthenticationContext';

import styles from './LoginPage.module.scss';
import GlobalErrorMessage from './components/GlobalErrorMessage';


interface LogInPageRouteState {
    redirectTo?: Location;
}

interface SignInFormValues {
    username: string;
    instanceID: string;
    password: string;
}

const requiredFieldOptions = {
    required: true
};

const components: ComponentProps<typeof Authenticator>['components'] = {
    SignIn: {
        Header() {
            const { error, isPending: loading, submitForm } = useAuthenticator(
                (context: AuthenticatorServiceFacade) => [
                    context.error, context.isPending, context.submitForm
                ]
            );

            const { register, handleSubmit } = useForm<SignInFormValues>({
                defaultValues: {
                    username: '',
                    instanceID: '',
                    password: ''
                }
            });

            const handleSignIn = handleSubmit(({ username, instanceID, password }) => {
                submitForm({
                    username: `${username}@${instanceID}`,
                    password
                });
            });

            return (
                <>
                    <Typography
                        variant="h6"
                        className="custom-sign-in-header"
                    >
                        Sign in to your account
                    </Typography>

                    <form
                        className={styles.usernameFields}
                        onSubmit={handleSignIn}
                    >
                        <TextField
                            label="Username"
                            {...register('username', requiredFieldOptions)}
                        />

                        <div className={styles.atSymbol}>
                            @
                        </div>

                        <TextField
                            label="Encompass Instance ID"
                            {...register('instanceID', requiredFieldOptions)}
                        />

                        <PasswordField
                            label="Password"
                            className={styles.fullWidth}
                            {...register('password', requiredFieldOptions)}
                        />

                        {error && (
                            <Alert
                                variation="error"
                                className={clsx(styles.fullWidth, styles.error)}
                                isDismissible
                            >
                                {error}
                            </Alert>
                        )}

                        <Button
                            type="submit"
                            variation="primary"
                            className={clsx(styles.fullWidth, styles.button)}
                            isLoading={loading}
                        >
                            Sign In
                        </Button>
                    </form>
                </>
            );
        }
    },
    ResetPassword: {
        Header() {
            const { submitForm, isPending: loading } = useAuthenticator(
                (context: AuthenticatorServiceFacade) => [ context.submitForm, context.isPending ]
            );

            const { register, handleSubmit } = useForm<Omit<SignInFormValues, 'password'>>({
                defaultValues: {
                    username: '',
                    instanceID: ''
                }
            });

            const handleSignIn = handleSubmit(({ username, instanceID }) => {
                submitForm({
                    username: `${username}@${instanceID}`
                });
            });

            function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
                if (event.key === 'Enter') {
                    event.preventDefault();

                    handleSignIn();
                }
            }

            return (
                <>
                    <Typography
                        variant="h6"
                    >
                        Reset your password
                    </Typography>

                    <div
                        className={styles.usernameFields}
                        style={{ padding: 0 }}
                    >
                        <TextField
                            label="Username"
                            {...register('username', requiredFieldOptions)}
                            onKeyDown={handleKeyDown}
                        />

                        <div className={styles.atSymbol}>
                            @
                        </div>

                        <TextField
                            label="Encompass Instance ID"
                            {...register('instanceID', requiredFieldOptions)}
                            onKeyDown={handleKeyDown}
                        />

                        <Button
                            variation="primary"
                            className={clsx(styles.fullWidth, styles.button)}
                            isLoading={loading}
                            onClick={handleSignIn}
                        >
                            Send code
                        </Button>
                    </div>
                </>
            );
        }
    }
};

export default function LogInPage() {
    const dispatch = useDispatch();
    const { isInitialized } = useContext(AuthenticationContext);

    const [ loading, setLoading ] = useState(false);

    return (
        <>
            <Header />

            {!isInitialized ? (
                <Loader
                    loading
                    className={styles.loader}
                />
            ) : (
                <Authenticator
                    components={components}
                    loginMechanisms={[ 'username' ]}
                >
                    {({ signOut }) => (
                        <AuthenticationRedirect
                            signOut={signOut}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    )}
                </Authenticator>
            )}

            {!loading && (
                <Typography
                    component="div"
                    align="center"
                    variant="caption"
                    color="textSecondary"
                    className={styles.havingTroubleLabel}
                >
                    Having trouble logging in?{' '}

                    <Link onClick={() => dispatch(openSupportDialog('Support'))}>
                        Contact us
                    </Link>
                    .
                </Typography>
            )}
        </>
    );
}

interface AuthenticationRedirectProps {
    signOut: ReturnType<typeof useAuthenticator>['signOut'] | undefined;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

function AuthenticationRedirect({ signOut, loading, setLoading }: AuthenticationRedirectProps) {
    const { initializeSession } = useContext(AuthenticationContext);

    const [ redirectTo, setRedirectTo ] = useState<LocationDescriptor>();
    const [ showError, setShowError ] = useState(false);

    const { redirectTo: customRedirect } = useRouteState<LogInPageRouteState>();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            await initializeSession();

            setRedirectTo(customRedirect || '/apps');
        } catch (error) {
            setShowError(true);
        } finally {
            setLoading(false);
        }
    }, [
        customRedirect, setLoading, initializeSession
    ]));

    return redirectTo ? (
        <Navigate to={redirectTo} />
    ) : showError ? (
        <GlobalErrorMessage onLogout={signOut} />
    ) : loading ? (
        <Loader
            loading
            className={styles.loader}
        />
    ) : null;
}
