import api, {
    Feed, FeedProduct, FeedResult, ProductGroup
} from '@api';
import { Button, Paper } from '@mui/material';
import { fetchFeeds } from '@redux/entities';
import { useDispatch } from '@redux/store';
import { PaperSaveLoader, useConfirm, usePageMessage } from '@tsp-ui/components';
import {
    Dispatch, SetStateAction, useCallback, useEffect, useRef, useState
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Form from '../../../../common/components/Form';
import FeedWidgetPreviewDialog  from '../FeedWidgetPreviewDialog';

import styles from './FeedForm.module.scss';
import FeedDetailsDisplayCard from './components/FeedDetailsDisplayCard';
import FeedDetailsFields from './components/FeedDetailsFields';
import FlatFeedFields from './components/FlatFeedFields';
import GroupedFeedFields from './components/GroupedFeedFields';


interface FeedFormProps {
    feed: Feed | undefined;
    setOnShowWidgetPreview: Dispatch<SetStateAction<(() => void) | undefined>>;
}

export interface FeedFormValues extends Feed {
    feedType: 'grouped' | 'flat' | '';
}

export function getFeedFormValues(feed: Feed | undefined): FeedFormValues | undefined {
    return feed && {
        ...feed,
        feedType: feed.productGroups[0].title ? 'grouped' : 'flat'
    };
}

export default function FeedForm({ feed, setOnShowWidgetPreview }: FeedFormProps) {
    // TODO make product group names unique
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    const formMethods = useForm<FeedFormValues>({
        defaultValues: getFeedFormValues(feed) || {
            feedType: '',
            productGroups: [ emptyProductGroup ]
        }
    });

    const { handleSubmit } = formMethods;

    const [ loading, setLoading ] = useState(false);
    const [ editFeedDetails, setEditFeedDetails ] = useState(!feed?.id);
    const [ showPreview, setShowPreview ] = useState(false);

    const saveFeed = handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            if (formValues.feedType === 'flat') {
                formValues.productGroups[0].title = null;
            }

            const updatedFeed = feed?.id
                ? await api.ppm.feed.updateFeed(formValues)
                : await api.ppm.feed.createFeed(formValues);

            await dispatch(fetchFeeds());

            navigate(`/apps/ppm/feeds/${updatedFeed.id}/details`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the feed', error);
            setLoading(false); // Only set on save,
        }
    });

    const saveFeedDetails = formMethods.handleSubmit(async ({ productGroups, feedType }) => {
        if (feedType === 'flat' && productGroups.length > 1) {
            if (await confirm('Changing this feed from grouped to flat will remove your existing product'
                    + ' group configurations. Are you sure you want to continue?')) {
                formMethods.setValue('productGroups', [ emptyProductGroup ]);
            } else {
                return; // Return early so edit feed details is still shown
            }
        }

        setEditFeedDetails(false);
    });

    // This ref is needed because the return type of handleSubmit is () => Promise<void>, so we can't pass the results
    // of that function directly to the widget, so we use the ref to store the results to be returned by the wrapper fn
    const fetchWidgetResultsRef = useRef<FeedResult>();
    const fetchWidgetResults = useCallback(async () => {
        fetchWidgetResultsRef.current = undefined;

        await handleSubmit(async (formValues) => {
            try {
                fetchWidgetResultsRef.current = await api.ppm.feed.testFeed(formValues);
            } catch (error) {
                pageMessage.handleApiError('An error occurred while testing the feed', error);
                throw error;
            }
        })();

        return fetchWidgetResultsRef.current!;
    }, [ handleSubmit, pageMessage ]);

    const feedName = formMethods.watch('name') || 'New feed';
    const feedType = formMethods.watch('feedType');

    const groupsArray = useFieldArray<FeedFormValues>({
        name: 'productGroups',
        control: formMethods.control
    });

    const flatProductsName = 'productGroups.0.products';
    const flatProductsArray = useFieldArray<FeedFormValues>({
        name: flatProductsName,
        control: formMethods.control
    });

    useEffect(() => {
        setOnShowWidgetPreview(() => () => {
            handleSubmit(() => setShowPreview(true))();
        });
    }, [ handleSubmit, setOnShowWidgetPreview ]);

    return (
        <Form
            onSubmit={saveFeed}
            formMethods={formMethods}
            loading={loading}
            className={styles.root}
        >
            {!editFeedDetails && (
                <FeedDetailsDisplayCard
                    feedName={feedName}
                    feedType={feedType}
                    onEditClick={() => setEditFeedDetails(true)}
                />
            )}

            <Paper
                variant="outlined"
                className={styles.formPaper}
            >
                {editFeedDetails ? (
                    <FeedDetailsFields feedName={feedName} />
                ) : (
                    <div className={styles.productOrGroupFields}>
                        {feedType === 'grouped' ? (
                            <GroupedFeedFields groupsArray={groupsArray} />
                        ) : (
                            <FlatFeedFields
                                flatProductsArray={flatProductsArray}
                                flatProductsName={flatProductsName}
                            />
                        )}
                    </div>
                )}

                <div className={styles.buttons}>
                    {editFeedDetails ? (
                        <Button
                            key="next"
                            variant="contained"
                            onClick={saveFeedDetails}
                        >
                            Next
                        </Button>
                    ) : (
                        <>
                            {feedType === 'grouped' ? (
                                <Button onClick={() => groupsArray.append(emptyProductGroup)}>
                                    Add group
                                </Button>
                            ) : (
                                <Button onClick={() => flatProductsArray.append(emptyFeedProduct)}>
                                    Add product
                                </Button>
                            )}

                            <Button
                                variant="contained"
                                type="submit"
                            >
                                Save feed
                            </Button>
                        </>
                    )}
                </div>

                <PaperSaveLoader loading={loading} />
            </Paper>

            <FeedWidgetPreviewDialog
                open={showPreview}
                onClose={() => setShowPreview(false)}
                widgetProps={{
                    fetchResults: fetchWidgetResults
                }}
            />
        </Form>
    );
}

export const emptyFeedProduct: FeedProduct = {
    productId: '',
    scenarioId: ''
};

export const emptyProductGroup: ProductGroup = {
    title: '',
    products: [ emptyFeedProduct ]
};
