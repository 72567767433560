import { Search } from '@mui/icons-material';
import {
    Button, InputAdornment, Link as MuiLink, TextField, Typography
} from '@mui/material';
import {
    getFeedsList, getProductsList, getScenariosList, useEntitySelector 
} from '@redux/entities';
import { SentryRoutes } from '@tsp-ui/components';
import { useState } from 'react';
import { Link, Route } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import Page from '../../common/components/Page';

import CreateEditFeedPage from './CreateEditFeedPage';
import FeedDetailPage from './FeedDetailPage';
import styles from './FeedsPage.module.scss';
import FeedCard from './components/FeedCard';


/**
 * Renders the feeds page
 *
 * @constructor
 */
export default function FeedsPage() {
    const feeds = useEntitySelector({
        entityType: 'feeds',
        selector: getFeedsList,
        forceRefresh: true
    });

    const scenarios = useEntitySelector({
        entityType: 'scenarios',
        selector: getScenariosList
    });

    const products = useEntitySelector({
        entityType: 'products',
        selector: getProductsList
    });

    const [ filterInputValue, setFilterInputValue ] = useState('');
    const [ debouncedFilterInputValue ] = useDebounce(filterInputValue, 250);
    const filteredFeeds = feeds?.filter((feed) => (
        feed.name.toLowerCase().includes(debouncedFilterInputValue.toLowerCase())
    ));

    return (
        <SentryRoutes>
            <Route
                path="/"
                element={(
                    <Page
                        header="Feeds"
                        className={styles.main}
                        loading={!feeds}
                        helpContent="A feed is a collection of one or more products with current rates
                            from any of your scenarios"
                        headerActions={(
                            <Button
                                component={Link}
                                to="/apps/ppm/feeds/create"
                                variant="contained"
                                disabled={!scenarios?.length || !products?.length}
                            >
                                Create feed
                            </Button>
                        )}
                        filterField={(
                            <TextField
                                variant="standard"
                                placeholder="Filter feeds"
                                value={filterInputValue}
                                onChange={(event) => setFilterInputValue(event.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    >
                        {!scenarios?.length ? (
                            <Typography color="textSecondary">
                                You need to create a scenario before configuring feeds.{' '}

                                <MuiLink
                                    component={Link}
                                    to="/apps/ppm/scenarios/create"
                                >
                                    Create a scenario{' '}
                                </MuiLink>

                                to get started.
                            </Typography>
                        ) : !products?.length ? (
                            <Typography color="textSecondary">
                                There are no product results for your current scenarios.{' '}

                                <MuiLink
                                    component={Link}
                                    to="/apps/ppm/scenarios"
                                >
                                    Edit your scenarios{' '}
                                </MuiLink>

                                to return product results that can be included in a feed.
                            </Typography>
                        ) : !feeds?.length ? (
                            <Typography color="textSecondary">
                                You don't have any feeds yet.{' '}

                                <MuiLink
                                    component={Link}
                                    to="/apps/ppm/feeds/create"
                                >
                                    Create a feed{' '}
                                </MuiLink>

                                to get started.
                            </Typography>
                        ) : (
                            <div className={styles.root}>
                                {filteredFeeds?.length ? (
                                    filteredFeeds?.map(feed => (
                                        <FeedCard
                                            key={feed.id}
                                            feed={feed}
                                        />
                                    ))
                                ) : (
                                    <Typography color="textSecondary">
                                        No feeds match your filter query.
                                    </Typography>
                                )}
                            </div>
                        )}
                    </Page>
                )} 
            />

            <Route
                path=":feedID/details"
                element={<FeedDetailPage />}
            />

            <Route
                path="create"
                element={<CreateEditFeedPage />}
            />

            <Route
                path=":feedID/edit"
                element={<CreateEditFeedPage />}
            />
        </SentryRoutes>
    );
}
