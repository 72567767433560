import { Button, DialogContent, Typography } from '@mui/material';
import { getAppConfig } from '@redux/config';
import { fetchPaymentInfo } from '@redux/payment-info';
import { useDispatch, useSelector } from '@redux/store';
import { Elements as ElementsProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Dialog, DialogActions } from '@tsp-ui/components';
import { useEffect, useState } from 'react';

import BankAccountForm, { bankAccountFormID } from '../../billing/components/BankAccountForm';
import CreditCardForm, { creditCardFormID } from '../../billing/components/CreditCardForm';
import SelectPaymentMethod from '../../billing/components/SelectPaymentMethod';


interface EditBillingInfoDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function EditBillingInfoDialog({ open, onClose }: EditBillingInfoDialogProps) {
    const dispatch = useDispatch();

    const [ stripePromise, setStripePromise ] = useState<null | ReturnType<typeof loadStripe>>(null);

    const [ showCreditCard, setShowCreditCard ] = useState(false);
    const [ showBankTransfer, setShowBankTransfer ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    async function handleSubmit() {
        await dispatch(fetchPaymentInfo());

        onClose();
        setLoading(false);
    }

    const { stripeKey } = useSelector(getAppConfig) || {};

    useEffect(() => {
        if (stripeKey) {
            setStripePromise(loadStripe(stripeKey));
        }
    }, [ stripeKey ]);

    return (
        <Dialog
            title="Update billing information"
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <ElementsProvider stripe={stripePromise}>
                <DialogContent>
                    {showCreditCard ? (
                        <CreditCardForm
                            update
                            setLoading={setLoading}
                            onSubmit={handleSubmit}
                        />
                    ) : showBankTransfer ? (
                        <BankAccountForm
                            update
                            setLoading={setLoading}
                            onSubmit={handleSubmit}
                        />
                    ) : (
                        <>
                            <Typography>
                                Select your new billing method
                            </Typography>

                            <SelectPaymentMethod
                                onCreditCardClick={() => setShowCreditCard(true)}
                                onBankClick={() => setShowBankTransfer(true)}
                            />
                        </>
                    )}
                </DialogContent>

                {(showCreditCard || showBankTransfer) && (
                    <DialogActions loading={loading}>
                        <Button
                            onClick={() => {
                                setShowBankTransfer(false);
                                setShowCreditCard(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            form={showCreditCard ? creditCardFormID : bankAccountFormID}
                            type="submit"
                            variant="contained"
                            disabled={loading}
                        >
                            Update
                        </Button>
                    </DialogActions>
                )}
            </ElementsProvider>
        </Dialog>
    );
}
