import api, {
    Associate, CalendarDayDetail, CloserCapacity, Loan, loanPurposeDisplay
} from '@api';
import { useAssociates, useCalendarInstance } from '@api/hooks/closing-calendar-api-hooks';
import {
    EventAvailable,
    ChevronRight,
    ChevronLeft,
    ExpandLess,
    ExpandMore,
    PunchClock,
    Approval,
    Upload,
    Event,
    Edit,
    Lock,
    Save,
    WarningAmber
} from '@mui/icons-material';
import {
    Link as MuiLink,
    Paper,
    Tooltip,
    MenuItem,
    TextField,
    Typography,
    DialogContent,
    LinearProgress
} from '@mui/material';
import {
    ExpandableCardTableRow,
    RoutedDialogImplProps,
    HorizontalLabeledValue,
    HorizontalLabelGroup,
    ValidatedDateField,
    ProgressIndicator,
    FilledSection,
    RoutedDialog,
    IconButton,
    CardTable,
    usePageMessage,
    useConfirm
} from '@tsp-ui/components';
import {
    useAsyncEffect, useParams, replaceItemById, removeItemById, toISO, formatCurrency
} from '@tsp-ui/utils';
import { addDays, format, parseISO } from 'date-fns';
import {
    useCallback, useEffect, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { getMonth } from '../ClosingCalendarPage';
import pageStyles from '../ClosingCalendarPage.module.scss';

import styles from './DayDetailsDialog.module.scss';


const cardTableHeaders = [
    '',
    'Loan Number',
    'Closer',
    'Borrower',
    'Loan Purpose',
    'Current Milestone',
    'Lock Expiration'
];

const listFormat = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction'
});

interface DayDetailsDialogParams {
    day: string;
}

export function DayDetailsDialog(props: RoutedDialogImplProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const { associates, isLoading: isAssociatesLoading, error } = useAssociates();
    pageMessage.useHandleApiError('An error occurred while fetching the available closers', error);

    const { day } = useParams<DayDetailsDialogParams>();

    const [ closersExpanded, setClosersExpanded ] = useState(false);
    const [ date, setDate ] = useState<Date | null>(parseISO(day));

    const [ debouncedDate ] = useDebounce(date, 300);

    const [ loading, setLoading ] = useState(true);
    const [ dayDetails, setDayDetails ] = useState<CalendarDayDetail>();
    const [ closerIdFilter, setCloserIdFilter ] = useState('All');

    const filteredLoans = dayDetails?.loans.filter(loan => (
        closerIdFilter === 'All' || loan.closerAssociateId === closerIdFilter
            || (closerIdFilter === 'Unknown' && !loan.closerAssociateId)
    ));

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setDayDetails(await api.closingCalendar.getDayDetails(day));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the details for this day', error);
        }

        setLoading(false);
    }, [ day, pageMessage ]));

    const closerCapacities: (
        CloserCapacity & { filledCapacity: number }
    )[] | undefined = dayDetails?.closerCapacities.map(
        closerCapacity => ({
            ...closerCapacity,
            filledCapacity: dayDetails.loans.filter(
                loan => loan.closerAssociateId === closerCapacity.closerAssociateId
            ).length
        })
    );

    const handleDateChange = useCallback((newDate: Date | null) => {
        if (newDate) {
            navigate(`../${toISO(newDate)}`);
        }
    }, [ navigate ]);

    useEffect(() => {
        setDate(parseISO(day));
    }, [ day ]);

    return (
        <RoutedDialog
            {...props}
            title={`Day Details — ${format(date!, 'MM/dd/yyyy')}`}
            maxWidth={false}
            loading={loading || (isAssociatesLoading && !associates)}
        >
            <DialogContent className={styles.dialogContent}>
                <div>
                    <div className={styles.contentHeader}>
                        <div className={styles.nextDayButtons}>
                            <ValidatedDateField
                                value={date}
                                onValidDateChange={handleDateChange}
                                className={pageStyles.calendarControl}
                                InputProps={{
                                    className: pageStyles.calendarControl_input
                                }}
                                pickerProps={{
                                    disableFuture: false,
                                    shouldDisableDate: day => day.getFullYear() > new Date().getFullYear() + 2
                                        || day.getFullYear() < new Date().getFullYear() - 1
                                }}
                            />

                            <IconButton
                                tooltip={`Go to ${format(addDays(debouncedDate!, -1), 'M/d/yyyy')}`}
                                onClick={() => setDate(addDays(debouncedDate!, -1))}
                                disabled={date?.getFullYear() === new Date().getFullYear() - 1 && getMonth(date) === 'January' && date.getDate() === 1}
                            >
                                <ChevronLeft color="secondary" />
                            </IconButton>

                            <IconButton
                                tooltip={`Go to ${format(addDays(debouncedDate!, 1), 'M/d/yyyy')}`}
                                onClick={() => setDate(addDays(debouncedDate!, 1))}
                                disabled={date?.getFullYear() === new Date().getFullYear() + 2 && getMonth(date) === 'December' && date.getDate() === 31}
                            >
                                <ChevronRight color="secondary" />
                            </IconButton>
                        </div>

                        <TextField
                            select
                            value={closerIdFilter}
                            onChange={newVal => setCloserIdFilter(newVal.target.value)}
                            className={pageStyles.calendarControl}
                            InputProps={{
                                className: pageStyles.calendarControl_input
                            }}
                            SelectProps={{
                                classes: {
                                    select: pageStyles.calendarControl_select
                                }
                            }}
                        >
                            <MenuItem value="All">All Closers</MenuItem>

                            {dayDetails?.closerCapacities.map(closerCapacity => {
                                const associate = associates?.find(
                                    associate => associate.id === closerCapacity.closerAssociateId
                                );

                                return (
                                    <MenuItem
                                        key={associate?.id}
                                        value={associate?.id || 'default'}
                                    >
                                        {associate?.name || '--'}
                                    </MenuItem>
                                );
                            })}

                            {dayDetails?.loans.some(({ closerAssociateId }) => !closerAssociateId) && (
                                <MenuItem value="Unknown">
                                    <div className={styles.loanAssociateName}>
                                        <WarningAmber
                                            color="warning"
                                            fontSize="small"
                                        />

                                        Unknown
                                    </div>
                                </MenuItem>
                            )}
                        </TextField>
                    </div>

                    <CardTable
                        headers={cardTableHeaders}
                        slim
                    >
                        {filteredLoans?.length ? filteredLoans.map(loan => (
                            <LoanTableRow
                                key={loan.id}
                                loan={loan}
                                associates={associates}
                                pageMessage={pageMessage}
                                updateLoans={getNewLoans => {
                                    setDayDetails(dayDetails ? {
                                        ...dayDetails,
                                        loans: getNewLoans(dayDetails?.loans)
                                    } : undefined);
                                }}
                            />
                        )) : (
                            <tr>
                                <td colSpan={7}>
                                    <Typography
                                        variant="body2"
                                        className={styles.noLoansFound}
                                    >
                                        No loans closing on this day
                                    </Typography>
                                </td>
                            </tr>
                        )}
                    </CardTable>
                </div>

                <FilledSection
                    header={(
                        <>
                            Closing capacity

                            <IconButton
                                onClick={() => setClosersExpanded(!closersExpanded)}
                                className={styles.expandButton}
                            >
                                {closersExpanded ? (
                                    <ExpandLess color="secondary" />
                                ) : (
                                    <ExpandMore color="secondary" />
                                )}
                            </IconButton>
                        </>
                    )}
                    headerTypographyProps={{
                        variant: 'body1',
                        fontWeight: 500
                    }}
                    className={styles.closingCapacitySection}
                    scrollable
                >
                    <div className={styles.closingCapacities}>
                        <CloserCapacityDisplay
                            label="All closers"
                            value={closerCapacities?.reduce(
                                (partialSum, closerCapacity) => partialSum + closerCapacity.filledCapacity, 0
                            ) || 0}
                            max={closerCapacities?.reduce(
                                (partialSum, closerCapacity) => partialSum + closerCapacity.capacity, 0
                            ) || 0}
                        />

                        {closersExpanded && closerCapacities?.map(closerCapacity => {
                            const associate = associates?.find(
                                associate => associate.id === closerCapacity.closerAssociateId
                            );

                            return (
                                <CloserCapacityDisplay
                                    key={associate?.id}
                                    label={associate?.name || '--'}
                                    value={closerCapacity.filledCapacity}
                                    max={closerCapacity.capacity}
                                />
                            );
                        })}
                    </div>
                </FilledSection>
            </DialogContent>
        </RoutedDialog>
    );
}

interface LoanTableRowProps {
    loan: Loan;
    associates: Associate[] | undefined;
    updateLoans: (fn: (loans: Loan[]) => Loan[]) => void;
    pageMessage: ReturnType<typeof usePageMessage>;
}

function LoanTableRow({
    loan, associates, updateLoans, pageMessage
}: LoanTableRowProps) {
    const confirm = useConfirm();
    const { instance } = useCalendarInstance();

    const [ editing, setEditing ] = useState(false);
    const [ closingDate, setClosingDate ] = useState<Date | null>(
        !loan.closingDate ? null : parseISO(loan.closingDate)
    );

    const [ closerID, setCloserID ] = useState(loan.closerAssociateId || 'invalid');
    const [ closerIdError, setCloserIdError ] = useState<string>();
    const [ loading, setLoading ] = useState(false);

    const clearToClose = !!loan.clearToCloseDate;

    async function handleSave() {
        if (closerID === 'invalid') {
            setCloserIdError('The selected closer is not active in CapraSuite');
            return;
        } else {
            setCloserIdError(undefined);
        }

        if (!closingDate) {
            return;
        }

        setLoading(true);

        try {
            await api.closingCalendar.updateClosing(loan.id, closerID, closingDate!);
            const isDateChange = !!closingDate && toISO(closingDate) !== (loan.closingDate ? toISO(parseISO(loan.closingDate)) : '');

            if (isDateChange) {
                updateLoans(loans => removeItemById(loans, loan.id));

                pageMessage.success(`Closing rescheduled for ${format(closingDate, 'M/d/yyyy')}`);
            } else {
                updateLoans(loans => replaceItemById(loans, {
                    ...loan,
                    closerAssociateId: closerID
                }));

                pageMessage.success('Closer updated');
            }

            setEditing(false);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving closing information', error);
        }

        setLoading(false);
    }

    const disableEdit = instance?.editableMilestoneNames
        .every((milestoneName) => milestoneName !== loan?.currentMilestone);

    return (
        <ExpandableCardTableRow
            slim
            expandedContent={(
                <div className={styles.loanTableExpandedContent}>
                    <div className={styles.loanDetails}>
                        <HorizontalLabelGroup>
                            <HorizontalLabeledValue
                                label="Loan program:"
                                value={loan.loanProgramName}
                            />

                            <HorizontalLabeledValue
                                label="Rate:"
                                value={!loan.interestRate ? '--' : `${loan.interestRate.toFixed(3)}%`}
                            />

                            <HorizontalLabeledValue
                                label="Loan amount:"
                                value={formatCurrency(loan.loanAmount) || '--'}
                            />

                            <HorizontalLabeledValue
                                label="Lock period:"
                                value={!loan.lockPeriod ? '--' : `${loan.lockPeriod} days`}
                            />

                            <HorizontalLabeledValue
                                label="Property address"
                                value={`${loan.propertyAddress.street}, ${loan.propertyAddress.state}`}
                            />
                        </HorizontalLabelGroup>

                        <HorizontalLabelGroup>
                            <HorizontalLabeledValue
                                label="Processor:"
                                value={loan.processorAssociateName}
                            />

                            <HorizontalLabeledValue
                                label="Underwriter:"
                                value={loan.underwriterAssociateName}
                            />

                            <HorizontalLabeledValue
                                label="Title Company:"
                                value={loan.titleCompany}
                            />
                        </HorizontalLabelGroup>

                        <div>
                            <Paper
                                elevation={0}
                                className={styles.closingDetailsPaper}
                            >
                                {editing ? (
                                    <>

                                        <HorizontalLabelGroup>
                                            <HorizontalLabeledValue
                                                label="Closing on:"
                                                classNames={{ label: styles.alignCenter }}
                                                variant="caption"
                                                value={(
                                                    <ValidatedDateField
                                                        variant="standard"
                                                        value={closingDate}
                                                        onValidDateChange={date => setClosingDate(date)}
                                                        pickerProps={{ disablePast: true }}
                                                        InputProps={{
                                                            className: styles.dateField
                                                        }}
                                                    />
                                                )}
                                            />

                                            <HorizontalLabeledValue
                                                label="Closing with:"
                                                classNames={{ label: styles.alignCenter }}
                                                variant="caption"
                                                value={(
                                                    <>
                                                        <TextField
                                                            variant="standard"
                                                            SelectProps={{
                                                                classes: { select: styles.closingWith }
                                                            }}
                                                            onChange={event => setCloserID(event.target.value)}
                                                            value={closerID}
                                                            select
                                                            className={pageStyles.calendarControl}
                                                            InputProps={{
                                                                className: pageStyles.calendarControl_input
                                                            }}
                                                        >
                                                            {!loan.closerAssociateId && (
                                                                <MenuItem
                                                                    key="invalid"
                                                                    value="invalid"
                                                                >
                                                                    <LoanAssociateNameDisplay
                                                                        associates={associates}
                                                                        loan={loan}
                                                                    />
                                                                </MenuItem>
                                                            )}

                                                            {associates?.map(associate => (
                                                                <MenuItem
                                                                    key={associate?.id}
                                                                    value={associate?.id || 'default'}
                                                                >
                                                                    {associate.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                        {closerIdError && (
                                                            <Typography
                                                                variant="caption"
                                                                color="error"
                                                                component="div"
                                                            >
                                                                {closerIdError}
                                                            </Typography>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </HorizontalLabelGroup>

                                        {loading ? (
                                            <ProgressIndicator
                                                size={24}
                                                className={styles.progressIndicator}
                                            />
                                        ) : (
                                            <IconButton
                                                onClick={handleSave}
                                                tooltip="Save"
                                            >
                                                <Save color="secondary" />
                                            </IconButton>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <HorizontalLabelGroup>
                                            <HorizontalLabeledValue
                                                label="Closing on:"
                                                value={!closingDate ? '--' : format(closingDate, 'MM/dd/yyyy')}
                                            />

                                            <HorizontalLabeledValue
                                                label="Closing with:"
                                                value={(
                                                    <LoanAssociateNameDisplay
                                                        associates={associates}
                                                        loan={loan}
                                                    />
                                                )}
                                            />
                                        </HorizontalLabelGroup>

                                        <IconButton
                                            tooltip={disableEdit
                                                ? `Closing information can only be edited for loans in
                                             the ${listFormat.format(instance?.editableMilestoneNames.map(m => `"${m}"`) ?? [])} milestones`
                                                : 'Edit'}
                                            disabled={disableEdit}
                                            onClick={async () => {
                                                if (loan.closerAssociateId || !loan.closerFullName
                                                    || await confirm('The closer currently assigned to this loan'
                                                        + ' is not configured within CapraSuite. If you change this'
                                                        + ' closer, you will be unable to reassign them within'
                                                        + ' CapraSuite. Are you sure you want to reassign?')) {
                                                    setEditing(true);
                                                }
                                            }}
                                        >
                                            <Edit color="secondary" />
                                        </IconButton>
                                    </>
                                )}
                            </Paper>

                            {!closingDate && loan?.estimatedClosingDate && (
                                <HorizontalLabelGroup>
                                    <HorizontalLabeledValue
                                        label="Estimated closing:"
                                        value={format(parseISO(loan?.estimatedClosingDate), 'MM/dd/yyyy')}
                                    />
                                </HorizontalLabelGroup>
                            )}
                        </div>
                    </div>

                    <div className={styles.dateTimeline}>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            Date timeline
                        </Typography>

                        <div className={styles.icons}>
                            <Tooltip
                                title="Lock"
                                enterDelay={0}
                            >
                                <Lock color="inherit" />
                            </Tooltip>

                            <Tooltip
                                title="Application"
                                enterDelay={0}
                            >
                                <Upload color="inherit" />
                            </Tooltip>

                            <Tooltip
                                title="Approved"
                                enterDelay={0}
                            >
                                <Approval color="inherit" />
                            </Tooltip>

                            {!clearToClose && (
                                <Tooltip
                                    title="Today"
                                    enterDelay={0}
                                >
                                    <Event color="secondary" />
                                </Tooltip>
                            )}

                            <Tooltip
                                title="Clear to close"
                                enterDelay={0}
                            >
                                <EventAvailable color="inherit" />
                            </Tooltip>

                            {clearToClose && (
                                <Tooltip
                                    title="Today"
                                    enterDelay={0}
                                >
                                    <Event color="secondary" />
                                </Tooltip>
                            )}

                            <Tooltip
                                title="Estimated closing"
                                enterDelay={0}
                            >
                                <PunchClock color="inherit" />
                            </Tooltip>
                        </div>

                        <div className={styles.dateProgress}>
                            <LinearProgress
                                variant="determinate"
                                color="secondary"
                                value={80}
                            />

                            <div className={styles.dateLine} />

                            <div className={styles.dateLine} />

                            <div className={styles.dateLine} />

                            <div className={styles.dateLine} />

                            <div className={styles.dateLine} />

                            <div className={styles.dateLine} />
                        </div>

                        <div className={styles.dates}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                {formatTimelineDate(loan.lockDate)}
                            </Typography>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                {formatTimelineDate(loan.applicationDate)}
                            </Typography>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                {formatTimelineDate(loan.approvedDate)}
                            </Typography>

                            {!clearToClose && (
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    {format(new Date(), 'MM/dd')}
                                </Typography>
                            )}

                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                {formatTimelineDate(loan.clearToCloseDate)}
                            </Typography>

                            {clearToClose && (
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    {format(new Date(), 'MM/dd')}
                                </Typography>
                            )}

                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                {formatTimelineDate(loan.estimatedClosingDate)}
                            </Typography>
                        </div>
                    </div>
                </div>
            )}
        >
            <td>
                <MuiLink
                    // component={Link} TODO databridge
                    // to={`/apps/${productRoutesMap[FrameworkProduct.DATA_BRIDGE]}/loans/${loan.id}`}
                    // color="primary"
                    variant="body2"
                >
                    {loan.loanNumber}
                </MuiLink>
            </td>

            <Typography
                component="td"
                variant="body2"
            >
                <LoanAssociateNameDisplay
                    associates={associates}
                    loan={loan}
                />
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {loan.borrowerNames.join(', ')}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {loanPurposeDisplay[loan.loanPurpose]}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {loan.currentMilestone}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {!loan.lockExpiration ? '--' : format(parseISO(loan.lockExpiration), 'MM/dd/yyyy')}
            </Typography>
        </ExpandableCardTableRow>
    );
}

interface LoanAssociateNameDisplayProps {
    associates: Associate[] | undefined;
    loan: Loan;
}

function LoanAssociateNameDisplay({ associates, loan }: LoanAssociateNameDisplayProps) {
    const associate = associates?.find(({ id }) => id === loan.closerAssociateId);

    return (
        <span className={styles.loanAssociateName}>
            {loan.closerFullName && !associate && (
                <Tooltip title="This closer is not configured within CapraSuite">
                    <WarningAmber
                        color="warning"
                        fontSize="small"
                    />
                </Tooltip>
            )}

            {associate?.name || loan.closerFullName || '--'}
        </span>
    );
}

function formatTimelineDate(dateStr: string | undefined | null) {
    return !dateStr ? '--' : format(parseISO(dateStr), 'MM/dd');
}

interface CloserCapacityDisplayProps {
    label: string;
    value: number;
    max: number;
}

function CloserCapacityDisplay({ label, value, max }: CloserCapacityDisplayProps) {
    return (
        <Paper
            elevation={0}
            className={styles.capacityPaper}
        >
            <LinearProgress
                variant="determinate"
                color={value > max ? 'error' : 'primary'}
                value={(value / max) * 100}
            />

            <div className={styles.captions}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {label}
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {`${value}/${max}`}
                </Typography>
            </div>
        </Paper>
    );
}
