import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { Toolbar, Tooltip, Typography } from '@mui/material';
import { Loader, PageMessage } from '@tsp-ui/components';
import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './Page.module.scss';


export interface PageProps {
    children: ReactNode;
    className?: string;
    classNames?: Partial<{
        header: string;
    }>;
    header: ReactNode;
    headerActions?: ReactNode;
    filterField?: ReactNode;
    helpContent?: ReactNode;
    loading?: boolean;
    variant?: 'centered' | 'full';
}

export default function Page({
    children, className, classNames, header, headerActions, filterField, helpContent, loading, variant
}: PageProps) {
    return (
        <main
            className={clsx(styles.main, className, {
                [styles.centered]: variant === 'centered'
            })}
        >
            <Toolbar
                className={styles.toolbar}
                disableGutters
            >
                <Typography
                    variant="h4"
                    component="h1"
                    className={clsx(styles.header, classNames?.header)}
                >
                    {header}

                    {filterField}
                </Typography>

                <PageMessage className={styles.pageMessageContainer} />

                <div className={styles.headerActionsContainer}>
                    {helpContent && (
                        <Tooltip title={helpContent}>
                            <HelpOutlineIcon
                                color="primary"
                                className={styles.help}
                            />
                        </Tooltip>
                    )}

                    {headerActions}
                </div>
            </Toolbar>

            {loading ? (
                <Loader
                    loading
                    className={clsx({
                        [styles.centeredLoader]: variant === 'centered'
                    })}
                />
            ) : children}
        </main>
    );
}

Page.defaultProps = {
    variant: 'full'
};
