import { apiUtils } from '@api';
import {
    HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState
} from '@microsoft/signalr';
import * as Sentry from '@sentry/react';

import { BulkUploadJob } from '../bulk-updater';

import { WebSocketEvent } from './WebSocketEvent';
import { WebSocketEventType } from './WebSocketEventType';


type WebSocketSubscriptionCallback<TData> = (data: TData) => void;

const HUB_METHOD_NAME = 'frameWorkNotification';


/**
 * Websocket for CapraSuite
 */
export class CapraSuiteWebSocket {
    private readonly _eventTarget: EventTarget = new EventTarget();
    private _connection: HubConnection | undefined;

    constructor() {
        this._handleMessage = this._handleMessage.bind(this);
    }

    /**
     * Recreates the underlying HubConnection with the given url. If the connection already exists, it will be closed
     * before creating the new instance.
     *
     * @param url - The url of the websocket to connect to
     */
    set url(url: string) {
        try {
            if (this._connection) {
                this.close();
            }

            this._connection = new HubConnectionBuilder()
                .withUrl(url, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                    accessTokenFactory: () => apiUtils.getFrameworkAccessToken()
                })
                .withAutomaticReconnect()
                .build();

            this._connection.on(HUB_METHOD_NAME, this._handleMessage);
        } catch (error) {
            Sentry.captureException(error, {
                extra: { url }
            });
        }
    }

    /**
     * Gets the url of the underlying connection
     */
    get url() {
        return this._connection?.baseUrl || '';
    }

    /**
     * Opens the underlying websocket
     */
    open() {
        if (this._connection && this._connection.state === HubConnectionState.Disconnected) {
            const subscribe = async () => {
                const { instanceId } = await apiUtils.decodeFrameworkToken();

                this._connection?.send('AddUserToGroup', instanceId);
            };

            this._connection.start().then(subscribe);
            this._connection.onreconnected(subscribe);
        }
    }

    /**
     * Closes the underlying websocket
     */
    close() {
        this._connection?.stop();
    }

    /**
     * When a message is received from the underlying RetryWebSocket, dispatch an event on this instance with the event
     * type set to the WebSocketEventType from the message data.
     *
     * @param type - The WebSocketEventType for this event
     * @param data - The payload data from the socket
     */
    private _handleMessage(type: WebSocketEventType, data: any) {
        this._eventTarget.dispatchEvent(new WebSocketEvent(type, data));
    }

    /**
     * Wraps the given WebSocketSubscriptionCallback to call it with the data from the WebSocketEvent
     *
     * @param callback - The WebSocketSubscriptionCallback to wrap
     */
    private _wrapCallback<TData>(callback: WebSocketSubscriptionCallback<TData>): EventListener {
        return (event) => {
            if (event instanceof WebSocketEvent) { // Should always be true, but necessary for TS
                callback(event.data);
            }
        };
    }

    /**
     * Overload for user bulk updater job completion events
     */
    public subscribe(
        type: WebSocketEventType.BULK_UPDATER_UPDATE,
        callback: WebSocketSubscriptionCallback<Pick<BulkUploadJob, 'jobId'>>
    ): () => void;

    /**
     * Overload for user notification events
     */
    public subscribe(
        type: WebSocketEventType.CLOSING_CALENDAR_UPDATE,
        callback: WebSocketSubscriptionCallback<{ closingDate: string }>
    ): () => void;

    /**
     * Overload for
     */
    public subscribe(
        type: WebSocketEventType.DATA_BRIDGE_UPDATE,
        callback: WebSocketSubscriptionCallback<unknown>
    ): () => void;

    /**
     * Subscribes to the given WebSocketEventType. Overloads for specific WebSocketEventTypes should be
     * defined above, hence the `never` argument passed to the actual implementation.
     *
     * @param   type     - The WebSocketEventType to subscribe to
     * @param   callback - The event listener callback
     * @returns an unsubscribe function
     */
    public subscribe(
        type: WebSocketEventType,
        callback: WebSocketSubscriptionCallback<never>
    ) {
        const wrappedCallback = this._wrapCallback(callback);

        this._eventTarget.addEventListener(type, wrappedCallback);

        return () => {
            this._eventTarget.removeEventListener(type, wrappedCallback);
        };
    };

    public simulateCalendarUpdate(closingDate: string) {
        this._eventTarget.dispatchEvent(
            new WebSocketEvent(WebSocketEventType.CLOSING_CALENDAR_UPDATE, { closingDate })
        );
    }
}
