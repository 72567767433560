import api, { User } from '@api';
import { mutateUsers } from '@api/hooks';
import {
    Checkbox, FormControlLabel
} from '@mui/material';
import { FilterTextField, useConfirm, usePageMessage } from '@tsp-ui/components';
import UserDialog from '@views/ppm/users/components/UserDialog';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import Page from '../../common/components/Page';

import styles from './UsersPage.module.scss';
import UserSection from './components/UserSection';


/**
 * Renders the user management page
 *
 * @constructor
 */
export default function UsersPage() {
    const confirm = useConfirm();
    const pageMessage = usePageMessage(250);

    const [ filterInputValue, setFilterInputValue ] = useState('');
    const [ debouncedFilterInputValue ] = useDebounce(filterInputValue, 250);

    const [ userToEdit, setUserToEdit ] = useState<User>();
    const [ showInactiveEncompassUsers, setShowInactiveEncompassUsers ] = useState(false);

    async function handleDeleteUser(user: User) {
        if (await confirm('Are you sure you want to deactivate this user?')) {
            try {
                await api.ppm.user.deleteUser(user.id);

                pageMessage.success(`User ${user.encompassUserId} deactivated`);

                await mutateUsers();
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deactivating the user', error);
            }
        }
    }

    return (
        <Page
            header="Users"
            helpContent="Users are grouped by active status. A user is considered fully inactive if they are not activated within Encompass"
            className={styles.main}
            filterField={(
                <FilterTextField
                    placeholder="Filter users"
                    helperText="Filter by name, username, or email"
                    value={filterInputValue}
                    onChange={(event) => setFilterInputValue(event.target.value)}
                    className={styles.filterField}
                />
            )}
        >
            <div className={styles.root}>
                <UserSection
                    header="Active"
                    onEditUser={setUserToEdit}
                    onDeleteUser={handleDeleteUser}
                    noResultsBody="No active users match your filter criteria"
                    noResultsCaption="Check the inactive user list to find the user you searched for"
                    queryParams={{
                        active: true,
                        pageSize: 20,
                        searchTerm: debouncedFilterInputValue
                    }}
                />

                <UserSection
                    header={(
                        <>
                            Inactive

                            <FormControlLabel
                                label="Include fully inactive"
                                control={(
                                    <Checkbox
                                        className={styles.checkbox}
                                        onChange={() => {
                                            setShowInactiveEncompassUsers(!showInactiveEncompassUsers);
                                        }}
                                    />
                                )}
                            />
                        </>
                    )}
                    onEditUser={setUserToEdit}
                    onDeleteUser={handleDeleteUser}
                    noResultsBody="No active users match your filter criteria"
                    noResultsCaption={showInactiveEncompassUsers
                        ? 'Check the active user list to find the user you are looking for'
                        : 'Try including fully inactive users'}
                    queryParams={{
                        active: false,
                        includeInactiveEncompassUsers: showInactiveEncompassUsers,
                        pageSize: 10,
                        searchTerm: debouncedFilterInputValue
                    }}
                />

                {userToEdit && (
                    <UserDialog
                        user={userToEdit}
                        onClose={() => {
                            setUserToEdit(undefined);
                        }}
                    />
                )}
            </div>
        </Page>
    );
}
