import { Typography } from '@mui/material';
import React from 'react';

import styles from './Logo.module.scss';


export function Logo() {
    return (
        <div className={styles.root}>
            <img
                src="/logo.svg"
                alt="CapraSuite Logo"
            />

            <Typography
                component="div"
                variant="h5"
                fontSize={28}
            >
                CapraSuite
            </Typography>
        </div>
    );
}
