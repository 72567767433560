import { apiUtils } from '../api-utils';


export function getProducts(): Promise<Product[]> {
    return apiUtils.get('/products/ppm/product');
}

export function createProductConfig(productID: string, config: ProductConfig): Promise<ProductConfig> {
    return apiUtils.post(`/products/ppm/product/${productID}/config`, config);
}

export function updateProductConfig(productID: string, config: ProductConfig): Promise<ProductConfig> {
    return apiUtils.put(`/products/ppm/product/${productID}/config`, config);
}

export async function deleteProductConfig(productID: string): Promise<void> {
    await apiUtils.delete(`/products/ppm/product/${productID}/config`);
}

export interface Product {
    id: string;
    programId: number;
    programName: string;
    availableScenarioIds?: string[];
    config?: ProductConfig;
}

export interface ProductConfig {
    displayName: string;
    description: string;
}
