import api, { LoanAuditEntry, WebSocketEventType } from '@api';
import * as Sentry from '@sentry/react';
import { RoutedDialogManager, usePageMessage } from '@tsp-ui/components';
import { useAsyncEffect } from '@tsp-ui/utils';
import Page from '@views/common/components/Page';
import { LoanAuditDialog } from '@views/loan-auditor/components/LoanAuditDialog';
import { LoanAuditEntryCard } from '@views/loan-auditor/components/LoanAuditEntryCard';
import { useCallback, useEffect, useState } from 'react';

import styles from './LoanAuditPage.module.scss';


export default function LoanAuditPage() {
    const pageMessage = usePageMessage();
    const [ loading, setLoading ] = useState(true);
    const [ auditEntries, setAuditEntries ] = useState<LoanAuditEntry[]>();

    useEffect(() => (
        api.webSocket.subscribe(WebSocketEventType.DATA_BRIDGE_UPDATE, async () => {
            try {
                setAuditEntries(await api.loanAuditor.getLoanAuditEntries());

                pageMessage.success('Data updated');
            } catch (error) {
                Sentry.captureException(error);
            }
        })
    ), [ pageMessage ]);

    useAsyncEffect(useCallback(async () => {
        try {
            setAuditEntries(await api.loanAuditor.getLoanAuditEntries());
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan audit entries', error);
        }

        setLoading(false);
    }, [ pageMessage ]));

    return (
        <Page
            header="Loan Auditor"
            variant="centered"
            className={styles.main}
            loading={loading}
        >
            {auditEntries?.map((entry) => (
                <LoanAuditEntryCard
                    key={entry.id}
                    entry={entry}
                />
            ))}

            <RoutedDialogManager routes={dialogRoutes} />
        </Page>
    );
}

const dialogRoutes = {
    'loans/:loanID': LoanAuditDialog
};

