import { LoanAuditEntry } from '@api';
import { Link as MuiLink, Tooltip, Typography } from '@mui/material';
import { CardTable, ExpandableCard } from '@tsp-ui/components';
import diffStyles from '@tsp-ui/core/styles/Diff/Diff.module.css';
import { formatDistanceToNowStrict } from '@tsp-ui/utils';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './LoanAuditEntryCard.module.scss';


interface LoanAuditEntryCardProps {
    entry: LoanAuditEntry;
    hideLoanNumber?: boolean;
}

const cardTableHeaders = [
    'Field ID', 'Old value', 'New value'
];

export function LoanAuditEntryCard({ entry, hideLoanNumber }: LoanAuditEntryCardProps) {
    return (
        <ExpandableCard
            className={styles.card}
            classNames={{ expandedRow: styles.expandedRow }}
            expandedContent={(
                <>
                    <Typography
                        color="textSecondary"
                        fontWeight={500}
                    >
                        Fields modified
                    </Typography>

                    <CardTable headers={cardTableHeaders}>
                        {entry.changeSets.map(({ field, oldValue, newValue }) => (
                            <tr key={field}>
                                <td>{field}</td>

                                <td className={diffStyles.oldDiffValue}>{oldValue}</td>

                                <td className={diffStyles.newDiffValue}>{newValue}</td>
                            </tr>
                        ))}
                    </CardTable>
                </>
            )}
        >
            {!hideLoanNumber && (
                <Typography component="span">
                    Loan #

                    <Tooltip title="Show Loan Audit Log">
                        <MuiLink
                            component={Link}
                            to={`loans/${entry.loanId}`}
                        >
                            {entry.friendlyLoanNumber}
                        </MuiLink>
                    </Tooltip>
                </Typography>
            )}

            <Typography
                className={styles.changeSummary}
                component="span"
            >
                <b className={styles.alignRight}>
                    {entry.changeSets.length}
                </b>

                <span className={styles.alignCenter}>
                    field{entry.changeSets.length > 1 ? 's' : ''} changed by
                </span>

                <b>{entry.changeBy}</b>

                <TimeDisplay
                    key={entry.created}
                    dateStr={entry.created}
                />
            </Typography>
        </ExpandableCard>
    );
}

interface TimeDisplayProps {
    dateStr: string;
}

function TimeDisplay({ dateStr }: TimeDisplayProps) {
    const [ _, setTimeRefreshCount ] = useState(0); // eslint-disable-line @typescript-eslint/no-unused-vars

    useEffect(() => {
        const interval = setInterval(() => (
            setTimeRefreshCount(timeRefreshCount => timeRefreshCount + 1)
        ), 10000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Typography
            color="textSecondary"
            className={styles.alignRight}
        >
            {formatDistanceToNowStrict(parseISO(dateStr), { addSuffix: true })}
        </Typography>
    );
}
