import { FeedResult, WidgetTheme } from '@api';
import { DialogContent } from '@mui/material';
import { Dialog, DialogProps } from '@tsp-ui/components';
import {
    useEffect, useMemo, useRef
} from 'react';


interface FeedWidgetPreviewDialogProps extends Omit<DialogProps, 'title' | 'classes'> {
    widgetProps: RateWidgetProps;
}

export default function FeedWidgetPreviewDialog({
    open,
    widgetProps: { fetchResults },
    ...props
}: FeedWidgetPreviewDialogProps) {
    const widgetRootRef = useRef<HTMLDivElement>();

    const memoWidgetProps = useMemo(() => ({
        fetchResults
    }), [ fetchResults ]);

    useEffect(() => {
        if (open && widgetRootRef.current) {
            window.PPMWidgets.initRateWidget(widgetRootRef.current, memoWidgetProps);

            const refNode = widgetRootRef.current;

            return () => window.PPMWidgets.unmount(refNode, 200);
        }
    }, [ open, memoWidgetProps ]);

    return (
        <Dialog
            {...props}
            title="Widget preview"
            open={open}
            keepMounted
        >
            <DialogContent ref={widgetRootRef} />
        </Dialog>
    );
}

declare global {
    interface Window {
        PPMWidgets: {
            registerAssetsToAwait: (...promises: Promise<unknown>[]) => void;
            configure: (config: Partial<WidgetsConfig>) => void;
            initCalculator: (node: Element, props: CalculatorWidgetProps) => Promise<void>;
            initRateWidget: (node: Element, props: RateWidgetProps) => Promise<void>;
            unmount: (node: Element | undefined | null, delay?: number) => void;
        };
    }
}

interface WidgetsConfig {
    theme: WidgetTheme;
    urlBase?: string;
}

interface RateWidgetProps {
    id?: string;
    fetchResults?: () => Promise<FeedResult>;
}

interface CalculatorWidgetProps {
    id: string;
}
