import { Button, ButtonProps, Tooltip } from '@mui/material';
import clsx from 'clsx';

import styles from './SelectableButton.module.scss';


interface SelectableButtonProps extends ButtonProps {
    selected?: boolean;
    title?: string;
}

export function SelectableButton({
    className, children, selected, title, ...props
}: SelectableButtonProps) {
    return (
        <Tooltip title={title}>
            <span>
                <Button
                    variant="outlined"
                    {...props}
                    fullWidth
                    color={selected ? 'secondary' : 'primary'}
                    className={clsx(className, styles.root, {
                        [styles.selected]: selected
                    })}
                >
                    {children}
                </Button>
            </span>
        </Tooltip>
    );
}
