import {
    Address, LicenseStatus, User, apiUtils
} from '@api';


export function getCurrentTenant(): Promise<Tenant> {
    return apiUtils.get('/tenant/me');
}

export function getCurrentLicense(): Promise<License> {
    // return ApiUtils.get('/license/me'); TODO license
    return Promise.resolve({
        status: 3,
        expirationDate: '2051-11-01T18:21:22.7271813+00:00'
    });
}

export interface Tenant extends Address {
    id?: string;
    name: string;
    phoneNumber: string;
    invoiceEmail: string;
    primaryContact: User;
    verified: boolean;
}

export interface License {
    status: LicenseStatus;
    expirationDate: string;
}
