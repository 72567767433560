import { DiffContainer, ArrayDiffValue, LabeledValue } from '@tsp-ui/components';
import { useState } from 'react';

import { extractActivityLogEntities } from '../utils/extractActivityLogEntities';

import { ActivityLogCard, ActivityLogCardImplProps } from './ActivityLogCard';


export function UserMappingActivityLogCard({ activityLog, usersMap }: ActivityLogCardImplProps<string[]>) {
    const [ userMapping = [], updatedUserMapping = [] ] = extractActivityLogEntities(activityLog);

    const [ hideUnchanged, setHideUnchanged ] = useState(true);

    return (
        <ActivityLogCard
            activityLog={activityLog}
            usersMap={usersMap}
            showExpand
            entityNameDisplay={null}
        >
            <DiffContainer
                hideUnchanged={hideUnchanged}
                setHideUnchanged={setHideUnchanged}
                allowHideUnchanged
            >
                <LabeledValue
                    variants={{ label: 'body2' }}
                    label="Mapped users"
                    value={(
                        <ArrayDiffValue
                            originalValue={userMapping}
                            updatedValue={updatedUserMapping}
                            isCreate={!userMapping}
                            isDelete={!updatedUserMapping}
                            hideIfUnchanged={hideUnchanged}
                        />
                    )}
                />
            </DiffContainer>
        </ActivityLogCard>
    );
}
