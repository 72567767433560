import { useCallback, useEffect, useRef } from 'react';
import { Middleware, SWRResponse } from 'swr';

/**
 * SWR middleware to keep returning the previous data even if the key changes
 */
export const laggyMiddleware: Middleware = (useSWRNext) => (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config);
    const laggyDataRef = useRef<typeof swr.data>();

    useEffect(() => {
        if (swr.data !== undefined) {
            laggyDataRef.current = swr.data;
        }
    }, [ swr.data ]);

    const resetLaggy = useCallback(() => {
        laggyDataRef.current = undefined;
    }, []);

    return {
        ...swr,
        data: swr.data === undefined ? laggyDataRef.current : swr.data,
        isLagging: swr.data === undefined && laggyDataRef.current !== undefined,
        resetLaggy
    };
};

export type WithLaggy<TResponse extends SWRResponse = SWRResponse> = TResponse & {
    isLagging: boolean,
    resetLaggy: () => void;
};
