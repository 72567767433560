import api, { PaymentType } from '@api';
import { MenuItem } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import { CreateTokenBankAccountData } from '@stripe/stripe-js';
import { TextField, usePageMessage } from '@tsp-ui/components';
import { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';


import styles from './BankAccountForm.module.scss';


export const bankAccountFormID = 'bank-account-form';

interface BankAccountFormProps {
    update?: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    onSubmit: () => Promise<void>;
}

export default function BankAccountForm({ update, setLoading, onSubmit }: BankAccountFormProps) {
    const pageMessage = usePageMessage(250);
    const stripe = useStripe();
    const formMethods = useForm<CreateTokenBankAccountData>();

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        formValues.currency = 'usd';
        formValues.country = 'US';

        if (stripe) {
            setLoading(true);
            const { error, token } = await stripe.createToken('bank_account', formValues);

            if (error) {
                pageMessage.error(error.message || 'An error occurred');
            } else if (token) {
                try {
                    const apiMethod = update
                        ? api.ppm.payment.updatePaymentMethod
                        : api.ppm.payment.addPaymentMethod;

                    await apiMethod({
                        paymentToken: token.id,
                        paymentType: PaymentType.BankTransfer
                    });

                    await api.ppm.payment.createSubscription();

                    await onSubmit();
                    return; // Return early so setLoading doesn't fire, the modal will be unmounted at this point
                } catch (error) {
                    pageMessage.handleApiError('An error occurred while submitting bank account information', error);
                }
            }

            setLoading(false);
        }
    });

    return (
        <form
            noValidate
            onSubmit={handleSubmit}
            id={bankAccountFormID}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <TextField<CreateTokenBankAccountData>
                    name="account_holder_name"
                    label="Account holder name"
                    className={styles.fullWidth}
                    required
                />

                <TextField<CreateTokenBankAccountData>
                    name="account_holder_type"
                    label="Account holder type"
                    defaultValue="company"
                    required
                    select
                >
                    <MenuItem value="company">
                        Company
                    </MenuItem>

                    <MenuItem value="individual">
                        Individual
                    </MenuItem>
                </TextField>

                <TextField<CreateTokenBankAccountData>
                    name="account_number"
                    label="Account number"
                    required
                />

                <TextField<CreateTokenBankAccountData>
                    name="routing_number"
                    label="Routing number"
                    required
                />
            </FormProvider>
        </form>
    );
}
