import { FeedProduct, FeedResult } from '@api';
import { Check, ErrorOutline } from '@mui/icons-material';
import {
    CircularProgress, Link as MuiLink, Tooltip, Typography 
} from '@mui/material';
import { getProducts, getScenarios, useEntitySelector } from '@redux/entities';
import { formatProductName } from '@views/ppm/feeds/components/FeedForm/components/FeedProductCard';
import { Link } from 'react-router-dom';

import styles from './FeedDetailProductCard.module.scss';
import HeadedBorderCard from './FeedForm/components/HeadedBorderCard';


interface FeedDetailProductCardProps {
    product: FeedProduct;
    feedResult: FeedResult | undefined;
    feedResultLoading: boolean;
}

export default function FeedDetailProductCard({
    product: { productId, scenarioId },
    feedResult,
    feedResultLoading
}: FeedDetailProductCardProps) {
    const products = useEntitySelector({
        entityType: 'products',
        selector: getProducts
    });

    const scenarios = useEntitySelector({
        entityType: 'scenarios',
        selector: getScenarios
    });

    const productResults = feedResult?.productGroupResults
        .flatMap(({ productResults }) => productResults);

    const resultsProductIDSet = new Set<string>(
        productResults?.filter(({ loanProgram }) => loanProgram.rateDetails)
            .map(({ productId }) => productId)
    );

    const ineligibleProductIDSet = new Set<string>(
        productResults?.filter(({ loanProgram }) => !loanProgram.rateDetails && !loanProgram.filteredRates)
            .map(({ productId }) => productId)
    );

    if (!resultsProductIDSet.has(productId)) { // If the product id is not in the results, it's ineligible
        ineligibleProductIDSet.add(productId);
    }

    const isScenarioMissing = scenarios && !scenarios?.[scenarioId];
    const isProductMissing = feedResult && !resultsProductIDSet.has(productId);
    const missingReason = isScenarioMissing
        ? 'because the scenario for this selection no longer exists'
        : ineligibleProductIDSet.has(productId)
            ? 'because the product is ineligible'
            : 'because no rates match the scenario\'s par rate criteria';

    return (
        <HeadedBorderCard
            key={`${scenarioId}${productId}`}
            header={products && formatProductName(products[productId])}
            headerVariant="body2"
            classes={{
                actions: styles.actions
            }}
            actions={feedResultLoading ? (
                <Tooltip title="Checking feed results">
                    <CircularProgress size={18} />
                </Tooltip>
            ) : !feedResult ? (
                <Tooltip title="We encountered an error while testing this feed. Try again later.">
                    <ErrorOutline
                        fontSize="small"
                        color="error"
                    />
                </Tooltip>
            ) : isProductMissing ? (
                <Tooltip title={`This product is not showing on your public feed ${missingReason}`}>
                    <ErrorOutline
                        fontSize="small"
                        color="error"
                    />
                </Tooltip>
            ) : (
                <Tooltip title="This product is showing on your public feed">
                    <Check
                        fontSize="small"
                        color="success"
                    />
                </Tooltip>
            )}
        >
            <Typography
                color="textSecondary"
                align="center"
            >
                {isScenarioMissing ? (
                    'The scenario chosen for this product no longer exists'
                ) : (
                    <>
                        {isProductMissing ? 'Not s' : 'S'}howing rates from

                        {' '}

                        {scenarios?.[scenarioId] && (
                            <Tooltip title="Click to edit scenario">
                                <MuiLink
                                    component={Link}
                                    to={`/apps/ppm/scenarios/${scenarioId}/edit`}
                                >
                                    {scenarios?.[scenarioId].name}
                                </MuiLink>
                            </Tooltip>
                        )}

                        {isProductMissing && (
                            <>
                                <br />

                                {missingReason}
                            </>
                        )}
                    </>
                )}
            </Typography>
        </HeadedBorderCard>
    );
}
