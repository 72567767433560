import api, {
    Feed, FeedResult, Product, Scenario
} from '@api';
import {
    Check, Edit as EditIcon, ErrorOutline, RemoveCircleOutline
} from '@mui/icons-material';
import {
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Paper,
    Tooltip,
    Typography
} from '@mui/material';
import { deleteFeed } from '@redux/entities';
import { useDispatch } from '@redux/store';
import { useConfirm, usePageMessage } from '@tsp-ui/components';
import utilStyles from '@tsp-ui/core/sass/style-utils.module.scss';
import { useAsyncEffect, formatDistanceToNowStrict, parseAndFormatISO } from '@tsp-ui/utils';
import { formatProductName } from '@views/ppm/feeds/components/FeedForm/components/FeedProductCard';
import { parseISO } from 'date-fns';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import EntitiesLink from '../../components/EntitiesLinks';

import styles from './FeedCard.module.scss';


interface FeedCardProps {
    feed: Feed;
}

export default function FeedCard({ feed }: FeedCardProps) {
    const dispatch = useDispatch();
    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<string>();
    const [ feedResult, setFeedResult ] = useState<FeedResult>();

    useAsyncEffect(useCallback(async () => {
        try {
            setFeedResult(await api.ppm.feed.testFeed(feed));
        } catch (error) {
            setError('We ran into an error testing this feed. Please try again later or contact'
                + ' support if this persists.');
        }
    }, [ feed ]));

    const resultsProductIDSet = new Set<string>(
        feedResult?.productGroupResults
            .flatMap(({ productResults }) => productResults)
            .filter(({ loanProgram }) => loanProgram.rateDetails)
            .map(({ productId }) => productId)
    );

    const missingProductIDSet = new Set<string>();
    const productIDSet = new Set<string>();
    const scenarioIDSet = new Set<string>();

    feed.productGroups.flatMap(({ products }) => products).forEach(({ productId, scenarioId }) => {
        if (!feedResult || resultsProductIDSet.has(productId)) {
            productIDSet.add(productId);
            scenarioIDSet.add(scenarioId);
        } else {
            missingProductIDSet.add(productId);
        }
    });

    const missingProductsText = `${missingProductIDSet.size} ${missingProductIDSet.size === 1
        ? 'product is not displaying on your public feed because it is ineligible or doesn\'t'
        : 'products are not displaying on your public feed because they are ineligible or don\'t'}
         have rates that meet the par rate criteria set on the scenario`;

    const lastAccessed = !feed.lastAccessedDate
        ? 'not accessed yet'
        : `last accessed ${formatDistanceToNowStrict(
            parseISO(feed.lastAccessedDate),
            { addSuffix: true }
        )}`;

    async function handleDeleteFeed() {
        if (await confirm('Are you sure you want to delete this feed?')) {
            setLoading(true);
            const result = await dispatch(deleteFeed(feed.id));

            if (deleteFeed.rejected.match(result)) {
                pageMessage.error(result.error.message || 'An error occurred');
                setLoading(false); // Only set loading on failure, the card unmounts on success.
            }
        }
    }

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            <Typography
                variant="h6"
                className={styles.name}
            >
                {feed.name}
            </Typography>

            <div className={styles.iconButtons}>
                <Tooltip title="Edit feed">
                    <IconButton
                        component={Link}
                        to={`/apps/ppm/feeds/${feed.id}/edit`}
                        disabled={loading}
                    >
                        <EditIcon color={loading ? 'disabled' : 'secondary'} />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Delete feed">
                    <IconButton
                        edge="end"
                        onClick={handleDeleteFeed}
                        disabled={loading}
                    >
                        <RemoveCircleOutline color={loading ? 'disabled' : 'error'} />
                    </IconButton>
                </Tooltip>
            </div>

            <Divider className={styles.divider} />

            <div className={styles.productsInfoContainer}>
                <Typography
                    color="textSecondary"
                    className={utilStyles.fullWidth}
                >
                    Displaying{' '}

                    <EntitiesLink
                        entityType="products"
                        entityIDs={[ ...productIDSet ]}
                        formatEntity={(product: Product) => formatProductName(product)}
                    />{' '}

                    from{' '}

                    <EntitiesLink
                        entityType="scenarios"
                        entityIDs={[ ...scenarioIDSet ]}
                        formatEntity={(scenario: Scenario) => scenario.name}
                    />
                </Typography>

                {error ? (
                    <Tooltip title={error}>
                        <ErrorOutline color="error" />
                    </Tooltip>
                ) : !feedResult ? (
                    <Tooltip title="Checking feed results">
                        <CircularProgress size={22} />
                    </Tooltip>
                ) : missingProductIDSet.size ? (
                    <Tooltip title={missingProductsText}>
                        <ErrorOutline color="error" />
                    </Tooltip>
                ) : (
                    <Tooltip title="All products are displaying on your public feed">
                        <Check color="success" />
                    </Tooltip>
                )}
            </div>

            <Typography
                variant="body2"
                color="textSecondary"
                className={utilStyles.fullWidth}
            >
                Feed {lastAccessed}
            </Typography>

            <Button
                component={Link}
                to={`/apps/ppm/feeds/${feed.id}/details`}
                className={styles.button}
            >
                View feed details
            </Button>

            <Typography
                variant="caption"
                color="textSecondary"
                className={styles.createdDate}
            >
                created{' '}

                {parseAndFormatISO(feed.createdDate)}
            </Typography>
        </Paper>
    );
}
