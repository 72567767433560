import api, { License } from '@api';
import { Button, DialogContent, Typography } from '@mui/material';
import { Dialog, DialogActions } from '@tsp-ui/components';
import { useState } from 'react';

import BankAccountForm, { bankAccountFormID } from './BankAccountForm';


interface BillingInfoDialogProps {
    onClose: (updatedLicense?: License) => void;
    open: boolean;
}

/**
 * Renders a Dialog to gather bank information
 *
 * @param onClose - A callback function to execute when the modal closes
 * @param open    - Whether the dialog is currently open
 * @constructor
 */
export default function BankAccountDialog({ onClose, open }: BillingInfoDialogProps) {
    const [ loading, setLoading ] = useState(false);
    const [ updatedLicense, setUpdatedLicense ] = useState<License>();

    async function handleSubmit() {
        setUpdatedLicense(await api.ppm.payment.pollForUpdatedLicense());
        setLoading(false);
    }

    function handleClose() {
        onClose(updatedLicense);
        setUpdatedLicense(undefined);
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            title="Enter bank information"
        >
            <DialogContent>
                {updatedLicense ? (
                    <>
                        <Typography>
                            We have sent two test deposits to verify your bank account. They that should arrive in{' '}
                            1-2 business days.
                        </Typography><br />

                        <Typography>
                            You will be able to use the application for 7 days to allow time for account verification.
                        </Typography>
                    </>
                ) : (
                    <BankAccountForm
                        setLoading={setLoading}
                        onSubmit={handleSubmit}
                    />
                )}
            </DialogContent>

            <DialogActions loading={loading}>
                {updatedLicense ? (
                    <Button
                        variant="contained"
                        onClick={handleClose}
                    >
                        Ok
                    </Button>
                ) : (
                    <Button
                        form={bankAccountFormID}
                        type="submit"
                        variant="contained"
                        disabled={loading}
                    >
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
