import { PaginatedGetQueryParams } from '@tsp-ui/core';

import { apiUtils } from '../api-utils';

import { CurrentPaymentInfo } from './payment-api';


interface GetAuditLogsQueryParams extends Pick<PaginatedGetQueryParams, 'pageNumber'> {
    startDate: string | null;
    endDate: string | null;
    type: ActivityLogType | null;
}

export function getAuditLogs(queryParams: GetAuditLogsQueryParams): Promise<PaginatedResponse<ActivityLog>> {
    return apiUtils.get('/products/ppm/auditlog', {
        ...queryParams,
        pageSize: 25
    });
}

export interface PaginatedResponse<TData> {
    totalRecords: number;
    totalPages: number;
    pageNumber: number;
    pageSize: number;
    data: TData[];
}

export interface ActivityLog<TEntity = unknown> {
    id: string;
    type: ActivityLogType;
    data: TEntity | ActivityLogUpdate<TEntity>;
    date: string;
    userId: string | null;
}

export interface ActivityLogUpdate<TEntity = unknown> {
    before: TEntity;
    after: TEntity;
}

export type PaymentMethodUpdate = Pick<CurrentPaymentInfo, 'last4' | 'paymentType'>;

export function isActivityLogUpdate<TEntity>(
    data: ActivityLog<TEntity>['data']
): data is ActivityLogUpdate<TEntity> {
    return data instanceof Object && data.hasOwnProperty('before') && data.hasOwnProperty('after');
}

export enum ActivityLogType {
    FEED_CREATED = 'FEED_CREATED',
    FEED_UPDATED = 'FEED_UPDATED',
    FEED_DELETED = 'FEED_DELETED',
    SCENARIO_CREATED = 'SCENARIO_CREATED',
    SCENARIO_UPDATED = 'SCENARIO_UPDATED',
    SCENARIO_DELETED = 'SCENARIO_DELETED',
    TENANT_CREATED = 'TENANT_CREATED',
    TENANT_UPDATED = 'TENANT_UPDATED',
    TENANT_DELETED = 'TENANT_DELETED',
    ELLIE_USER_MAPPING_UPDATED = 'ELLIE_USER_MAPPING_UPDATED',
    ELLIE_CONFIGURATION_CREATED = 'ELLIE_CONFIGURATION_CREATED',
    ELLIE_CONFIGURATION_UPDATED = 'ELLIE_CONFIGURATION_UPDATED',
    PAYMENT_METHOD_UPDATED = 'PAYMENT_METHOD_UPDATED'
}

const {
    FEED_CREATED,
    FEED_UPDATED,
    FEED_DELETED,
    SCENARIO_CREATED,
    SCENARIO_UPDATED,
    SCENARIO_DELETED,
    TENANT_CREATED,
    TENANT_UPDATED,
    TENANT_DELETED,
    ELLIE_USER_MAPPING_UPDATED,
    ELLIE_CONFIGURATION_CREATED,
    ELLIE_CONFIGURATION_UPDATED,
    PAYMENT_METHOD_UPDATED
} = ActivityLogType;

export const activityLogTypeDisplay = {
    [FEED_CREATED]: 'Feed created',
    [FEED_UPDATED]: 'Feed updated',
    [FEED_DELETED]: 'Feed deleted',
    [SCENARIO_CREATED]: 'Scenario created',
    [SCENARIO_UPDATED]: 'Scenario updated',
    [SCENARIO_DELETED]: 'Scenario deleted',
    [TENANT_CREATED]: 'Account created',
    [TENANT_UPDATED]: 'Account updated',
    [TENANT_DELETED]: 'Account deleted',
    [ELLIE_USER_MAPPING_UPDATED]: 'Ellie user mapping updated',
    [ELLIE_CONFIGURATION_CREATED]: 'Ellie configuration created',
    [ELLIE_CONFIGURATION_UPDATED]: 'Ellie configuration updated',
    [PAYMENT_METHOD_UPDATED]: 'Payment method updated'
};
