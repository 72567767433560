import { ErrorMessage } from '@hookform/error-message';
import { Edit, RemoveCircleOutline, Save } from '@mui/icons-material';
import {
    Button, IconButton, Tooltip, Typography
} from '@mui/material';
import { TextField } from '@tsp-ui/components';
import { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';


import { emptyFeedProduct, FeedFormValues } from '../FeedForm';

import FeedProductCard from './FeedProductCard';
import HeadedBorderCard from './HeadedBorderCard';
import styles from './ProductGroupCard.module.scss';
import UpDownArrows from './UpDownArrows';


interface ProductGroupProps {
    index: number;
    onUpClick?: () => void;
    onDownClick?: () => void;
    onDeleteClick: () => void;
}

export default function ProductGroupCard({
    index, onUpClick, onDownClick, onDeleteClick 
}: ProductGroupProps) {
    const productGroups = useWatch({
        name: 'productGroups',
        defaultValue: []
    });

    const {
        trigger, watch, register, clearErrors 
    } = useFormContext<FeedFormValues>();

    const nameBase: `productGroups.${number}` = `productGroups.${index}`;
    const productsArray = useFieldArray<FeedFormValues>({
        name: `${nameBase}.products`
    });

    const productGroup = watch(nameBase);
    const [ editGroupName, setEditGroupName ] = useState(!productGroup.title);

    return (
        <HeadedBorderCard
            header={productGroups[index]?.title || 'New product group'}
            className={styles.root}
            actions={(
                <div className={styles.buttonContainer}>
                    <UpDownArrows
                        variant="group"
                        onUpClick={onUpClick}
                        onDownClick={onDownClick}
                    />

                    {!editGroupName && (
                        <Tooltip title="Edit group name">
                            <IconButton
                                size="small"
                                onClick={() => setEditGroupName(true)}
                            >
                                <Edit
                                    fontSize="small"
                                    color="secondary"
                                />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="Remove group">
                        <IconButton
                            size="small"
                            onClick={onDeleteClick}
                        >
                            <RemoveCircleOutline
                                fontSize="small"
                                color="error"
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        >
            {editGroupName ? (
                <div className={styles.titleInputContainer}>
                    <TextField<FeedFormValues>
                        name={`${nameBase}.title`}
                        label="Group title"
                        size="small"
                        helperText="This will be shown in the feed widget display"
                        required
                        autoFocus
                    />

                    <IconButton
                        className={styles.saveButton}
                        onClick={async () => {
                            if (await trigger(`${nameBase}.title`, {
                                shouldFocus: true
                            })) {
                                setEditGroupName(false);
                                clearErrors(`${nameBase}.products`);
                            }
                        }}
                    >
                        <Save color="secondary" />
                    </IconButton>

                    <input
                        type="hidden"
                        {...register(`${nameBase}.products`, {
                            validate: () => !editGroupName
                        })}
                    />

                    <ErrorMessage
                        name={`${nameBase}.products`}
                        render={() => (
                            <Typography
                                variant="caption"
                                color="error"
                            >
                                Save your changes to the group title before saving this feed
                            </Typography>
                        )}
                    />
                </div>
            ) : (
                <div className={styles.feedProductCardContainer}>
                    {productsArray.fields.map((field, index) => (
                        <FeedProductCard
                            key={field.id}
                            index={index}
                            productsNameBase={`${nameBase}.products`}
                            onDeleteClick={productsArray.fields.length > 1
                                ? () => productsArray.remove(index)
                                : () => productsArray.update(index, emptyFeedProduct)}
                            onUpClick={index === 0
                                ? undefined
                                : () => productsArray.swap(index, index - 1)}
                            onDownClick={index === productsArray.fields.length - 1
                                ? undefined
                                : () => productsArray.swap(index, index + 1)}
                        />
                    ))}

                    <Button
                        className={styles.addProductButton}
                        onClick={() => productsArray.append(emptyFeedProduct)}
                    >
                        Add product
                    </Button>
                </div>
            )}
        </HeadedBorderCard>
    );
}
