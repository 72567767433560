import { Product } from '@api';
import { Typography } from '@mui/material';
import { FilledSection } from '@tsp-ui/components';
import { ReactNode } from 'react';

import ProductCard from './ProductCard';
import styles from './ProductSection.module.scss';


interface ProductSectionProps {
    header: ReactNode;
    products: Product[] | undefined;
    noResultsBody: ReactNode;
    noResultsCaption: ReactNode;
}

export default function ProductSection({
    header,
    products,
    noResultsBody,
    noResultsCaption
}: ProductSectionProps) {
    return (
        <FilledSection
            header={header}
            scrollable
        >
            {!products?.length ? (
                <>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        {noResultsBody}
                    </Typography>

                    {noResultsCaption && (
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            {noResultsCaption}
                        </Typography>
                    )}
                </>
            ) : (
                <div className={styles.cardContainer}>
                    {products.map(product => (
                        <ProductCard
                            key={product.programId}
                            product={product}
                        />
                    ))}
                </div>
            )}
        </FilledSection>
    );
}
