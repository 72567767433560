import api, { Associate, CapacityOverride } from '@api';
import { mutateAssociates, useAssociates, useCalendarInstance } from '@api/hooks/closing-calendar-api-hooks';
import {
    Close, Edit, Refresh, RemoveCircleOutline
} from '@mui/icons-material';
import {
    Button, Divider, Paper, Tooltip, Typography
} from '@mui/material';
import {
    CardTable,
    ExpandableCard,
    FilterTextField,
    IconButton,
    RoutedDialogManager,
    useConfirm,
    usePageMessage
} from '@tsp-ui/components';
import { format } from '@tsp-ui/utils';
import CapacityDialog from '@views/closing-calendar/components/CapacityDialog';
import CapacityOverrideDialog from '@views/closing-calendar/components/CapacityOverrideDialog';
import Page from '@views/common/components/Page';
import { parseISO } from 'date-fns';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';


import styles from './ClosersPage.module.scss';


/**
 * Renders the user management page
 *
 * @constructor
 */
export default function ClosersPage() {
    const pageMessage = usePageMessage();

    const [ filterInputValue, setFilterInputValue ] = useState('');
    const [ debouncedFilterInputValue ] = useDebounce(filterInputValue, 250);

    const {
        associates, isLoading, error, mutate
    } = useAssociates();
    pageMessage.useHandleApiError('An error occurred while fetching the available closers', error);

    const filteredAssociates = associates?.filter((associate) => (
        associate.name.toLowerCase().includes(debouncedFilterInputValue.toLowerCase())
        || associate.encompassUserId.toLowerCase().includes(debouncedFilterInputValue.toLowerCase())
    ));

    const [ loading, setLoading ] = useState(false);

    async function syncClosers() {
        try {
            setLoading(true);
            await api.closingCalendar.syncClosers();

            await mutate();

            pageMessage.success('Closers refreshed');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while refreshing the closer list', error);
        }

        setLoading(false);
    }

    const { instance } = useCalendarInstance();

    return (
        <Page
            header="Closers"
            helpContent={`Only users with the "${instance?.roleName}" role in Encompass will appear here. Use the
                refresh button to refresh the closer list after user role changes within Encompass.`}
            className={styles.main}
            loading={loading || (isLoading && associates === undefined)}
            filterField={(
                <FilterTextField
                    placeholder="Filter closers"
                    helperText="Filter by name or username"
                    value={filterInputValue}
                    onChange={(event) => setFilterInputValue(event.target.value)}
                    autoFocus
                />
            )}
            headerActions={(
                <IconButton
                    tooltip="Refresh closers"
                    onClick={syncClosers}
                >
                    <Refresh color="secondary" />
                </IconButton>
            )}
        >
            <div className={styles.root}>
                {filteredAssociates?.map((associate) => (
                    <CloserCapacityCard
                        key={associate.id}
                        associate={associate}
                    />
                ))}
            </div>

            <RoutedDialogManager routes={dialogRoutes} />
        </Page>
    );
}

const dialogRoutes = {
    ':associateId/capacity': CapacityDialog,
    ':associateId/overrides/add': CapacityOverrideDialog,
    ':associateId/overrides/:overrideId': CapacityOverrideDialog
};

interface CloserCapacityCardProps {
    associate: Associate;
}

function CloserCapacityCard({
    associate: {
        id, capacities, capacityOverrides, name, encompassUserId
    }
}: CloserCapacityCardProps) {
    return (
        <ExpandableCard
            classNames={{
                expandedRow: styles.expandedRow
            }}
            expandedContent={(
                <CapacityOverridesSection
                    associateId={id}
                    capacityOverrides={capacityOverrides}
                />
            )}
        >
            <div className={styles.card}>
                <div>
                    <Typography>
                        {name}
                    </Typography>

                    <Typography variant="body2">
                        {encompassUserId}
                    </Typography>
                </div>

                <div className={styles.capacities}>
                    <LabeledCapacityDisplay
                        label="Su"
                        capacity={capacities.sunday}
                    />

                    <LabeledCapacityDisplay
                        label="Mo"
                        capacity={capacities.monday}
                    />

                    <LabeledCapacityDisplay
                        label="Tu"
                        capacity={capacities.tuesday}
                    />

                    <LabeledCapacityDisplay
                        label="We"
                        capacity={capacities.wednesday}
                    />

                    <LabeledCapacityDisplay
                        label="Th"
                        capacity={capacities.thursday}
                    />

                    <LabeledCapacityDisplay
                        label="Fr"
                        capacity={capacities.friday}
                    />

                    <LabeledCapacityDisplay
                        label="Sa"
                        capacity={capacities.saturday}
                    />
                </div>

                <div className={styles.buttons}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {capacityOverrides?.length || 'No'}

                        {' '}capacity override

                        {capacityOverrides?.length === 1 ? '' : 's'}
                    </Typography>

                    <IconButton
                        component={Link}
                        to={`${id}/capacity`}
                        tooltip="Edit closer capacities"
                    >
                        <Edit color="secondary" />
                    </IconButton>
                </div>
            </div>
        </ExpandableCard>
    );
}

const tableHeaders = [
    'Date',
    'Capacity',
    'Override reason',
    ''
];


interface CapacityOverridesSectionProps {
    associateId: string;
    capacityOverrides: CapacityOverride[] | undefined;
}

function CapacityOverridesSection({ associateId, capacityOverrides }: CapacityOverridesSectionProps) {
    return (
        <div className={styles.capacityOverridesSection}>
            <Typography
                fontWeight={500}
                color="textSecondary"
                className={styles.capacityOverridesHeader}
            >
                Capacity Overrides

                <Button
                    component={Link}
                    to={`${associateId}/overrides/add`}
                    size="small"
                >
                    Add override
                </Button>
            </Typography>

            <Divider className={styles.divider} />

            {capacityOverrides?.length ? (
                <CardTable
                    headers={tableHeaders}
                    className={styles.table}
                >
                    {capacityOverrides?.map((override) => (
                        <OverrideTableRow
                            key={override.id}
                            override={override}
                        />
                    ))}
                </CardTable>
            ) : (
                <Paper
                    variant="outlined"
                    className={styles.noOverridesPaper}
                >
                    <Typography
                        variant="body2"
                        align="center"
                    >
                        There are no capacity overrides for this closer yet
                    </Typography>
                </Paper>
            )}
        </div>
    );
}

interface LabeledCapacityDisplayProps {
    label: ReactNode;
    capacity: number;
}

function LabeledCapacityDisplay({ label, capacity }: LabeledCapacityDisplayProps) {
    return (
        <div className={styles.capacity}>
            <Typography
                variant="caption"
                color="textSecondary"
            >
                {label}
            </Typography>

            <CapacityDisplay capacity={capacity} />
        </div>
    );
}

interface CapacityDisplayProps {
    capacity: number;
}

function CapacityDisplay({ capacity }: CapacityDisplayProps) {
    return capacity === 0 ? (
        <div className={styles.iconContainer}>
            <Tooltip title="This closer has no capacity on this day">
                <Close
                    fontSize="inherit"
                    color="error"
                />
            </Tooltip>
        </div>
    ) : (
        <Typography variant="inherit">
            {capacity}
        </Typography>
    );
}

interface OverrideTableRowProps {
    override: CapacityOverride;
}

function OverrideTableRow({ override }: OverrideTableRowProps) {
    const confirm = useConfirm();
    const pageMessage = usePageMessage();

    async function handleDelete() {
        if (await confirm('Are you sure you want to delete this capacity override?')) {
            try {
                await api.closingCalendar.deleteCapacityOverride(override);

                await mutateAssociates();
                pageMessage.success('Capacity override deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the capacity override', error);
            }
        }
    }

    return (
        <tr>
            <Typography
                component="td"
                variant="body2"
            >
                {format(parseISO(override.overrideDate))}
            </Typography>

            <td align="center">
                <CapacityDisplay capacity={override.value} />
            </td>

            <Typography
                component="td"
                variant="body2"
                width="100%"
            >
                {override.reason}
            </Typography>

            <td className={styles.overrideButtons}>
                <IconButton
                    component={Link}
                    to={`${override.associateId}/overrides/${override.id}`}
                    tooltip="Edit override"
                >
                    <Edit
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>

                <IconButton
                    tooltip="Delete override"
                    onClick={handleDelete}
                >
                    <RemoveCircleOutline
                        color="error"
                        fontSize="small"
                    />
                </IconButton>
            </td>
        </tr>
    );
}
