import { Button, DialogContent } from '@mui/material';
import { getScenario, useEntitySelector } from '@redux/entities';
import {
    DialogActions, RoutedDialog, RoutedDialogImplProps, useConfirm
} from '@tsp-ui/components';
import { useParams } from '@tsp-ui/utils';
import { useEPPSLookups } from '@views/common/hooks/useEPPSLookups';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import styles from './AddEditScenarioDialog.module.scss';
import ScenarioForm from './components/ScenarioForm';


interface EditScenarioPageParams {
    scenarioID: string;
}

/**
 * Renders the edit scenario page
 *
 * @constructor
 */
export default function AddEditScenarioDialog(props: RoutedDialogImplProps) {
    const confirm = useConfirm();
    const { scenarioID } = useParams<EditScenarioPageParams>();
    const isCreate = !scenarioID;

    const { lookups, isLoading } = useEPPSLookups();

    const scenario = useEntitySelector({
        entityType: 'scenarios',
        selector: (state) => (scenarioID ? getScenario(state, scenarioID) : undefined),
        disable: isCreate
    });

    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ isFormDirty, setIsFormDirty ] = useState(false);

    const title = isCreate ? 'Create scenario' : 'Edit scenario';
    const [ debouncedTitle ] = useDebounce(title, 300);

    return (
        <RoutedDialog
            {...props}
            classes={{ paper: styles.dialogPaper }}
            maxWidth={false}
            title={isCreate ? debouncedTitle : title}
            confirmClose={!isFormDirty
                ? undefined
                : () => confirm('You have unsaved changes that will be lost.'
                    + ' Are you sure you want to continue?')}
            loading={isLoading}
        >
            {(isCreate || !!scenario) && (
                <DialogContent className={styles.content}>
                    <ScenarioForm
                        scenario={scenario}
                        setLoading={setSaveLoading}
                        setIsFormDirty={setIsFormDirty}
                        lookups={lookups}
                    />
                </DialogContent>
            )}

            <DialogActions loading={saveLoading}>
                <Button
                    form={ScenarioForm.formID}
                    type="submit"
                    variant="contained"
                    disabled={saveLoading}
                >
                    Save scenario
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
