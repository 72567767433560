import { Search } from '@mui/icons-material';
import {
    Button, InputAdornment, Link as MuiLink, TextField, Typography
} from '@mui/material';
import { getScenariosList, useEntitySelector } from '@redux/entities';
import { RoutedDialogManager } from '@tsp-ui/components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import Page from '../../common/components/Page';

import AddEditScenarioDialog from './AddEditScenarioDialog';
import styles from './ScenariosPage.module.scss';
import ScenarioCard from './components/ScenarioCard';


/**
 * Renders the scenarios page
 *
 * @constructor
 */
export default function ScenariosPage() {
    const scenarios = useEntitySelector({
        entityType: 'scenarios',
        selector: getScenariosList,
        forceRefresh: true
    });

    const [ filterInputValue, setFilterInputValue ] = useState('');
    const [ debouncedFilterInputValue ] = useDebounce(filterInputValue, 250);
    const filteredScenarios = scenarios?.filter((scenario) => (
        scenario.name.toLowerCase().includes(debouncedFilterInputValue.toLowerCase())
    ));

    return (
        <Page
            header="Scenarios"
            helpContent="Access current rates in EPPS by creating scenario specific pricing inquiries.
                            Products with scenario specific pricing will be available for configuration and placement
                            within feeds."
            loading={!scenarios}
            headerActions={(
                <Button
                    component={Link}
                    to="/apps/ppm/scenarios/create"
                    variant="contained"
                >
                    Create scenario
                </Button>
            )}
            filterField={(
                <TextField
                    variant="standard"
                    placeholder="Filter scenarios"
                    value={filterInputValue}
                    onChange={(event) => setFilterInputValue(event.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            )}
        >
            {!scenarios?.length ? (
                <Typography color="textSecondary">
                    You don't have any scenarios yet.{' '}

                    <MuiLink
                        component={Link}
                        to="/apps/ppm/scenarios/create"
                    >
                        Create a scenario{' '}
                    </MuiLink>

                    to get started.
                </Typography>
            ) : (
                <div className={styles.root}>
                    {filteredScenarios?.length ? (
                        filteredScenarios?.map(scenario => (
                            <ScenarioCard
                                key={scenario.id}
                                scenario={scenario}
                            />
                        ))
                    ) : (
                        <Typography color="textSecondary">
                            No scenarios match your filter query.
                        </Typography>
                    )}
                </div>
            )}

            <RoutedDialogManager routes={dialogRoutes} />
        </Page>
    );
}

const dialogRoutes = {
    create: AddEditScenarioDialog,
    ':scenarioID/edit': AddEditScenarioDialog
};
