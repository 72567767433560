import { Typography } from '@mui/material';

import Page from './components/Page';


export default function NoPermissionPage() {
    return (
        <Page header="Unauthorized">
            <Typography>
                You don't have permission to view this page.
            </Typography>
        </Page>
    );
}
