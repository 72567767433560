import { PaymentType } from '@api';
import { AccountBalance, CreditCard, LocalAtm } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';


interface PaymentTypeIconProps {
    paymentType: PaymentType;
}

export default function PaymentTypeIcon({ paymentType }: PaymentTypeIconProps) {
    return paymentType === PaymentType.CreditCard ? (
        <Tooltip title="Credit card">
            <CreditCard color="primary" />
        </Tooltip>
    ) : paymentType === PaymentType.BankTransfer ? (
        <Tooltip title="ACH">
            <AccountBalance color="primary" />
        </Tooltip>
    ) : (
        <Tooltip title="Check">
            <LocalAtm color="primary" />
        </Tooltip>
    );
}
