import {
    ActivityLog, ActivityLogType, PaymentMethodUpdate, EllieConfiguration, Feed, Scenario, Tenant
} from '@api';

import { AccountActivityLogCard } from './AccountActivityLogCard';
import { ActivityLogCardImplProps } from './ActivityLogCard';
import { EllieConfigActivityLogCard } from './EllieConfigActivityLogCard';
import { FeedActivityLogCard } from './FeedActivityLogCard/FeedActivityLogCard';
import { PaymentMethodActivityLogCard } from './PaymentMethodActivityLogCard';
import { ScenarioActivityLogCard } from './ScenarioActivityLogCard';
import { UserMappingActivityLogCard } from './UserMappingActivityLogCard';


export function UnknownActivityLogCard(props: ActivityLogCardImplProps<unknown>) {
    const { activityLog } = props;

    return isFeed(activityLog) ? (
        <FeedActivityLogCard
            {...props}
            activityLog={activityLog}
        />
    ) : isScenario(activityLog) ? (
        <ScenarioActivityLogCard
            {...props}
            activityLog={activityLog}
        />
    ) : isTenant(activityLog) ? (
        <AccountActivityLogCard
            {...props}
            activityLog={activityLog}
        />
    ) : isUserMapping(activityLog) ? (
        <UserMappingActivityLogCard
            {...props}
            activityLog={activityLog}
        />
    ) : isEllieConfig(activityLog) ? (
        <EllieConfigActivityLogCard
            {...props}
            activityLog={activityLog}
        />
    ) : isPaymentMethod(activityLog) ? (
        <PaymentMethodActivityLogCard
            {...props}
            activityLog={activityLog}
        />
    ) : null;
}

const {
    FEED_CREATED, FEED_UPDATED, FEED_DELETED, SCENARIO_CREATED, SCENARIO_UPDATED, SCENARIO_DELETED,
    TENANT_CREATED, TENANT_UPDATED, TENANT_DELETED, ELLIE_USER_MAPPING_UPDATED, ELLIE_CONFIGURATION_UPDATED,
    ELLIE_CONFIGURATION_CREATED, PAYMENT_METHOD_UPDATED
} = ActivityLogType;

function isFeed(activityLog: ActivityLog): activityLog is ActivityLog<Feed> {
    const { type } = activityLog;
    return type === FEED_CREATED || type === FEED_UPDATED || type === FEED_DELETED;
}

function isScenario(activityLog: ActivityLog): activityLog is ActivityLog<Scenario> {
    const { type } = activityLog;
    return type === SCENARIO_CREATED || type === SCENARIO_UPDATED || type === SCENARIO_DELETED;
}

function isTenant(activityLog: ActivityLog): activityLog is ActivityLog<Tenant> {
    const { type } = activityLog;
    return type === TENANT_CREATED || type === TENANT_UPDATED || type === TENANT_DELETED;
}

function isUserMapping(activityLog: ActivityLog): activityLog is ActivityLog<string[]> {
    return activityLog.type === ELLIE_USER_MAPPING_UPDATED;
}

function isEllieConfig(activityLog: ActivityLog): activityLog is ActivityLog<EllieConfiguration> {
    const { type } = activityLog;
    return type === ELLIE_CONFIGURATION_CREATED || type === ELLIE_CONFIGURATION_UPDATED;
}

function isPaymentMethod(activityLog: ActivityLog): activityLog is ActivityLog<PaymentMethodUpdate> {
    return activityLog.type === PAYMENT_METHOD_UPDATED;
}
