import { useHasProduct } from '@api';
import { AppsButtons } from '@views/common/components/AppsButton';

import styles from './AppSelectionPage.module.scss';
import Page from './components/Page';


export default function AppSelectionPage() {
    const { isLoading } = useHasProduct();

    return (
        <Page
            variant="centered"
            header="Available Apps"
            loading={isLoading}
        >
            <div className={styles.root}>
                <AppsButtons />
            </div>
        </Page>
    );
}
