import api, { CalendarDay, WebSocketEventType } from '@api';
import { ChevronLeft, ChevronRight, Settings } from '@mui/icons-material';
import { MenuItem, TextField, Typography } from '@mui/material';
import {
    IconButton, ProgressIndicator, RoutedDialogManager, usePageMessage
} from '@tsp-ui/components';
import {
    useAsyncEffect, toISO
} from '@tsp-ui/utils';
import {
    addDays, isAfter, isBefore, parseISO
} from 'date-fns';
import {
    useCallback, useState, useEffect, useRef
} from 'react';
import { useDebounce } from 'use-debounce';

import styles from './ClosingCalendarPage.module.scss';
import { ClosingCalendarDay } from './components/ClosingCalendarDay';
import { DayDetailsDialog } from './components/DayDetailsDialog';


export function ClosingCalendarPage() {
    const pageMessage = usePageMessage();

    const thisMonth = getMonth(new Date());
    const thisYear = new Date().getFullYear();

    const [ month, setMonth ] = useState<Month>(thisMonth);
    const [ year, setYear ] = useState(String(thisYear));
    const [ days, setDays ] = useState<(Partial<CalendarDay> & { date: string })[]>(getCalendarDays(month, year).map(
        date => ({ date: toISO(date) })
    ));

    const [ alerts, setAlerts ] = useState<string[]>([]);
    const [ loading, setLoading ] = useState(true);
    const [ changeLoading, setChangeLoading ] = useState(false);
    const debouncedChangeLoading = useDebounce(changeLoading, 300)[0] && changeLoading;

    const stateRef = useRef({
        month,
        year
    });

    stateRef.current = { // need to do this to have current values in the subscription callback
        month,
        year
    };

    const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    // effect to handle calendar updates
    useEffect(() => {
        api.webSocket.subscribe(
            WebSocketEventType.CLOSING_CALENDAR_UPDATE,
            ({ closingDate }) => {
                const date = parseISO(closingDate);
                const [ start, end ] = getDayRange(month, year);

                if (!isBefore(date, start) && !isAfter(date, end)) {
                    clearTimeout(timeoutRef.current);
                    timeoutRef.current = setTimeout(async () => {
                        const newDays = await api.closingCalendar.getDays(start, end);

                        if (stateRef.current.month === month && stateRef.current.year === year) {
                            const newLoansClosing = getLoansClosing(newDays, date);
                            const oldLoansClosing = getLoansClosing(days, date);

                            if (newLoansClosing > oldLoansClosing) {
                                setAlerts(alerts => [ ...alerts, toISO(date) ]);
                            }

                            setDays(newDays);
                        }
                    }, 1000);
                }
            }
        );
        return () => clearTimeout(timeoutRef.current);
    }, [
        month, year, days
    ]);

    // effect to populate initial data
    useAsyncEffect(useCallback(async () => {
        const [ startDay, endDay ] = getDayRange(thisMonth, String(thisYear));

        try {
            setDays(await api.closingCalendar.getDays(startDay, endDay));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching calendar data', error);
        }

        setLoading(false);
    }, [
        pageMessage, thisYear, thisMonth
    ]));

    // effect to reset alerts when calendar changes
    useEffect(() => {
        setAlerts([]);
    }, [ month, year ]);

    async function onPeriodChange(newMonth?: Month, newYear?: string) {
        setChangeLoading(true);

        try {
            const [ startDay, endDay ] = getDayRange(newMonth || month, newYear || year);
            setDays(await api.closingCalendar.getDays(startDay, endDay));

            if (newMonth) {
                setMonth(newMonth);
            }

            if (newYear) {
                setYear(newYear);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching calendar data', error);
        }

        setChangeLoading(false);
    }

    const availableYears = [
        String(thisYear - 1), String(thisYear), String(thisYear + 1), String(thisYear + 2)
    ];

    const prevMonthDisabled = (month === 'January' && year === availableYears[0]) || changeLoading;
    const nextMonthDisabled = (month === 'December' && year === availableYears[availableYears.length - 1]) || changeLoading;

    return (
        <main className={styles.root}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <Typography
                        variant="h4"
                        component="h1"
                    >
                        Closing Calendar
                    </Typography>

                    {debouncedChangeLoading && (
                        <ProgressIndicator />
                    )}
                </div>

                <div className={styles.calendarControls}>
                    <IconButton
                        tooltip="Previous month"
                        onClick={() => {
                            if (month === 'January') {
                                onPeriodChange('December', String(parseInt(year) - 1));
                            } else {
                                onPeriodChange(months[months.indexOf(month) - 1]);
                            }
                        }}
                        disabled={prevMonthDisabled}
                    >
                        <ChevronLeft color="secondary" />
                    </IconButton>

                    <TextField
                        select
                        value={month}
                        onChange={event => onPeriodChange(event.target.value as Month)}
                        className={styles.calendarControl}
                        InputProps={{
                            className: styles.calendarControl_input
                        }}
                        SelectProps={{
                            classes: {
                                select: styles.calendarControl_select
                            }
                        }}
                        disabled={changeLoading}
                    >
                        {months.map(month => (
                            <MenuItem
                                value={month}
                                key={month}
                            >
                                {month}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        value={year}
                        onChange={event => onPeriodChange(undefined, event.target.value)}
                        className={styles.calendarControl}
                        InputProps={{
                            className: styles.calendarControl_input
                        }}
                        SelectProps={{
                            classes: {
                                select: styles.calendarControl_select
                            }
                        }}
                        disabled={changeLoading}
                    >
                        {availableYears.map(year => (
                            <MenuItem
                                value={year}
                                key={year}
                            >
                                {year}
                            </MenuItem>
                        ))}
                    </TextField>

                    <IconButton
                        tooltip="Next month"
                        onClick={() => {
                            if (month === 'December') {
                                onPeriodChange('January', String(parseInt(year) + 1));
                            } else {
                                onPeriodChange(months[months.indexOf(month) + 1]);
                            }
                        }}
                        disabled={nextMonthDisabled}
                    >
                        <ChevronRight color="secondary" />
                    </IconButton>
                </div>

                <IconButton className={styles.settingsButton}>
                    <Settings color="secondary" />
                </IconButton>
            </div>

            <div className={styles.dayHeader}>
                <div>Sunday</div>

                <div>Monday</div>

                <div>Tuesday</div>

                <div>Wednesday</div>

                <div>Thursday</div>

                <div>Friday</div>

                <div>Saturday</div>
            </div>

            <div className={styles.calendar}>
                {days.map((day, index, days) => (
                    <ClosingCalendarDay
                        key={index} // eslint-disable-line react/no-array-index-key
                        alerts={alerts}
                        day={day}
                        loading={loading}
                        isOtherMonth={getMonth(parseISO(day.date)) !== getMonth(parseISO(days[10].date))}
                    />
                ))}
            </div>

            <RoutedDialogManager routes={dialogRoutes} />
        </main>
    );
}

const dialogRoutes = {
    ':day': DayDetailsDialog
};


/**
 * Returns an array of every day that should be displayed in the calendar for the given date.
 * Starts with the sunday on or preceding the 1st of the month, and ends with the saturday
 * on or after the last of the month
 */
export function getCalendarDays(month: Month, year: string) {
    const [ startingDay, endingDay ] = getDayRange(month, year);
    const daysToDisplay = [ startingDay ];
    const iteratorDate = new Date(startingDay);
    while (iteratorDate.getTime() < endingDay.getTime()) {
        iteratorDate.setDate(iteratorDate.getDate() + 1);
        daysToDisplay.push(new Date(iteratorDate));
    }

    return daysToDisplay;
}

function getDayRange(month: Month, year: string) {
    const firstOfMonth = new Date(`${month} 1, ${year}`);
    const lastOfMonth = new Date(firstOfMonth.getFullYear(), firstOfMonth.getMonth() + 1, 0);
    const startingDay = addDays(firstOfMonth, -firstOfMonth.getDay()); // sunday on or preceding the 1st of the month
    const endingDay = addDays(lastOfMonth, 6 - lastOfMonth.getDay()); // saturday on or after the last of the month

    return [ startingDay, endingDay ];
}

const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'
] as const;
export type Month = typeof months[number];

export function getMonth(date: Date) {
    return date.toLocaleString('default', { month: 'long' }) as Month;
}

function getLoansClosing(days: (Partial<CalendarDay> & { date: string })[], closingDate: Date) {
    return days.find(
        ({ date }) => date === toISO(closingDate)
    )?.loansClosing || 0;
}
