import { AppState } from '@redux/store';
import { createAction, createReducer } from '@reduxjs/toolkit';


export const openSupportDialog = createAction<SupportTeamOption>('support/openSupportDialog');
export const closeSupportDialog = createAction('support/closeSupportDialog');

type SupportTeamOption = 'Support' | 'Sales';

interface SupportState {
    open: boolean;
    defaultTeam: SupportTeamOption;
}

const initialState: SupportState = {
    open: false,
    defaultTeam: 'Support'
};

const reducer = createReducer(initialState, builder => builder
    .addCase(openSupportDialog, (state, { payload }) => {
        state.open = true;
        state.defaultTeam = payload;
    })
    .addCase(closeSupportDialog, (state) => {
        state.open = false;
    }));

export default reducer;

export function isSupportModalOpen(state: AppState) {
    return state.support.open;
}

export function getDefaultSupportTeam(state: AppState) {
    return state.support.defaultTeam;
}
