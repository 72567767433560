import { FrameworkProduct, productRoutesMap } from '@api';
import {
    BarChart,
    CalendarMonth,
    DynamicFeed,
    Group,
    Groups,
    Inventory,
    Settings,
    Upload,
    WebStories
} from '@mui/icons-material';
import {
    Drawer, List, ListItem, Toolbar, Typography
} from '@mui/material';
import { useRouteMatch } from '@tsp-ui/utils';
import AppsButton from '@views/common/components/AppsButton';
import { useHasAdminPermission, useHasPermission } from '@views/common/hooks/useHasPermission';
import { cloneElement, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './MainNav.module.scss';


const {
    BULK_UPDATER, CLOSING_CALENDAR, DATA_BRIDGE, PPM
} = FrameworkProduct;

export default function MainNav() {
    const { isFrameworkAdmin } = useHasPermission();
    const [ isClosingCalendarAdmin ] = useHasAdminPermission(CLOSING_CALENDAR);

    const match = useRouteMatch<{ app: string; }>('/apps/:app/*');

    return (
        <Drawer
            variant="permanent"
            classes={{ paper: styles.drawer }}
        >
            <Toolbar className={styles.logoToolbar}>
                <AppsButton />
            </Toolbar>

            <List disablePadding>
                {!match ? (
                    isFrameworkAdmin && (
                        <NavItem
                            to="/users"
                            label="Users"
                            icon={<Group />}
                        />
                    )
                ) : match.params.app === productRoutesMap[PPM] ? (
                    <>
                        <NavItem
                            to={`/apps/${productRoutesMap[PPM]}/scenarios`}
                            label="Scenarios"
                            icon={<BarChart />}
                        />

                        <NavItem
                            to={`/apps/${productRoutesMap[PPM]}/products`}
                            label="Products"
                            icon={<Inventory />}
                        />

                        <NavItem
                            to={`/apps/${productRoutesMap[PPM]}/feeds`}
                            label="Feeds"
                            icon={<DynamicFeed />}
                        />

                        <NavItem
                            to={`/apps/${productRoutesMap[PPM]}/widget-settings`}
                            label="Widget Settings"
                            icon={<Settings />}
                        />
                    </>
                ) : match.params.app === productRoutesMap[CLOSING_CALENDAR] ? (
                    <>
                        <NavItem
                            to={`/apps/${productRoutesMap[CLOSING_CALENDAR]}/calendar`}
                            label="Closing Calendar"
                            icon={<CalendarMonth />}
                        />

                        {isClosingCalendarAdmin && (
                            <NavItem
                                to={`/apps/${productRoutesMap[CLOSING_CALENDAR]}/closers`}
                                label="Closers"
                                icon={<Groups />}
                            />
                        )}
                    </>
                ) : match.params.app === productRoutesMap[BULK_UPDATER] ? (
                    <NavItem
                        to={`/apps/${productRoutesMap[BULK_UPDATER]}`}
                        label="Bulk Updater"
                        icon={<Upload />}
                    />
                ) : match.params.app === productRoutesMap[DATA_BRIDGE] ? (
                    <NavItem
                        to={`/apps/${productRoutesMap[DATA_BRIDGE]}`}
                        label="Loan Auditor"
                        icon={<WebStories className={styles.auditorIcon} />}
                    />
                ) : null}
            </List>
        </Drawer>
    );
}

interface NavItemProps {
    to: string;
    icon: ReactElement;
    label: string;
}

function NavItem({ to, icon, label }: NavItemProps) {
    const { pathname } = useLocation();

    return (
        <ListItem
            button
            component={Link}
            to={to}
            selected={pathname.includes(to)}
            classes={{ selected: styles.selected }}
            className={styles.listItem}
        >
            {cloneElement(icon, { style: { fontSize: 28 } })}

            <Typography
                variant="caption"
                align="center"
                className={styles.label}
            >
                {label}
            </Typography>
        </ListItem>
    );
}
