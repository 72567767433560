import { Product } from '@api';
import { Edit, RemoveCircleOutline, Save } from '@mui/icons-material';
import {
    Divider, IconButton, MenuItem, Tooltip, Typography
} from '@mui/material';
import { getProducts, getScenarios, useEntitySelector } from '@redux/entities';
import { TextField } from '@tsp-ui/components';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FeedFormValues } from '../FeedForm';

import styles from './FeedProductCard.module.scss';
import FeedProductSelect from './FeedProductSelect';
import HeadedBorderCard from './HeadedBorderCard';
import UpDownArrows from './UpDownArrows';


interface FeedProductCardProps {
    index: number;
    productsNameBase: `productGroups.${number}.products`;
    onDeleteClick: () => void;
    onUpClick?: () => void;
    onDownClick?: () => void;
}

export default function FeedProductCard({
    index, productsNameBase, onDeleteClick, onUpClick, onDownClick
}: FeedProductCardProps) {
    const scenarios = useEntitySelector({
        entityType: 'scenarios',
        selector: getScenarios
    });

    const products = useEntitySelector({
        entityType: 'products',
        selector: getProducts
    });

    const nameBase: `${typeof productsNameBase}.${number}` = `${productsNameBase}.${index}`;

    const { watch, setValue } = useFormContext<FeedFormValues>();
    const { productId, scenarioId } = watch(nameBase) || {};

    const selectedProduct = products?.[productId];
    const selectedScenario = scenarios?.[scenarioId];

    useEffect(() => {
        const { availableScenarioIds } = selectedProduct || {};

        if (availableScenarioIds?.length === 1) {
            setValue(`${nameBase}.scenarioId`, selectedProduct?.availableScenarioIds?.[0] || '');
        }
    }, [
        selectedProduct, scenarioId, setValue, nameBase
    ]);

    const [ showEditView, setShowEditView ] = useState(false);

    const scenarioOptions = renderScenarioOptions(scenarios, selectedProduct);

    const scenarioField = scenarioOptions?.length ? (
        <TextField<FeedFormValues>
            key="scenario"
            name={`${nameBase}.scenarioId`}
            label="Scenario"
            autoFocus={!showEditView}
            size="small"
            required
            select
            className={styles.selectField}
            SelectProps={{
                defaultOpen: !showEditView
            }}
        >
            {scenarioOptions}
        </TextField>
    ) : (
        <Typography variant="body2">
            The selected product is no longer available in any scenarios. Select a different product to show here{' '}
            or remove this item from the feed.
        </Typography>
    );

    const scenarioDeleted = scenarios && !selectedScenario;
    const disableEdit = Boolean(scenarioDeleted && !selectedProduct?.availableScenarioIds?.length);

    return (
        <HeadedBorderCard
            className={styles.root}
            headerVariant="body2"
            header={selectedProduct
                ? formatProductName(selectedProduct)
                : 'New product display'}
            actions={(
                <div className={styles.buttonContainer}>
                    <UpDownArrows
                        variant="product"
                        onUpClick={onUpClick}
                        onDownClick={onDownClick}
                    />

                    {productId && !showEditView && (
                        <Tooltip
                            title={disableEdit
                                ? 'This product is not available in any scenarios. Remove this product or reconfigure'
                                    + ' your scenarios to allow selection of a different scenario for this product.'
                                : 'Edit product selection'}
                        >
                            <span>
                                <IconButton
                                    size="small"
                                    onClick={() => setShowEditView(true)}
                                    disabled={disableEdit}
                                >
                                    <Edit
                                        fontSize="small"
                                        color={disableEdit ? 'disabled' : 'secondary'}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}

                    <Tooltip title="Remove product">
                        <IconButton
                            size="small"
                            onClick={onDeleteClick}
                        >
                            <RemoveCircleOutline
                                color="error"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        >
            {showEditView ? (
                <div className={styles.editView}>
                    <div className={styles.fields}>
                        <FeedProductSelect nameBase={nameBase} />

                        {scenarioField}
                    </div>

                    <IconButton
                        className={styles.saveButton}
                        onClick={() => setShowEditView(false)}
                    >
                        <Save color="secondary" />
                    </IconButton>
                </div>
            ) : (
                <>
                    {productId && (
                        <Typography color="textSecondary">
                            {scenarioDeleted ? (
                                'The selected scenario for this product no longer exists'
                            ) : (
                                <>
                                    Show rates from{!scenarioId ? (
                                        '...'
                                    ) : (
                                        <>
                                            {' '}

                                            <Typography
                                                component="span"
                                                color="textPrimary"
                                                fontWeight={500}
                                            >
                                                {selectedScenario?.name}
                                            </Typography>
                                        </>
                                    )}
                                </>
                            )}
                        </Typography>
                    )}

                    {!productId ? (
                        <>
                            <Typography
                                color="textSecondary"
                            >
                                Which product do you want to add?
                            </Typography>

                            <FeedProductSelect nameBase={nameBase} />
                        </>
                    ) : !scenarioId ? (
                        <>
                            <Divider className={styles.divider} />

                            <Typography
                                color="textSecondary"
                                align="center"
                            >
                                This product is available in multiple scenarios.<br />
                                Which scenario do you want to use?
                            </Typography>

                            {scenarioField}
                        </>
                    ) : null}
                </>
            )}
        </HeadedBorderCard>
    );
}

function renderScenarioOptions(scenarios: ReturnType<typeof getScenarios>, selectedProduct: Product | undefined) {
    return scenarios && selectedProduct?.availableScenarioIds?.map((scenarioID) => {
        const scenario = scenarios?.[scenarioID];

        return (
            <MenuItem
                key={scenario.id}
                value={scenario.id}
            >
                {scenario.name}
            </MenuItem>
        );
    });
}

export function formatProductName(product: Product) {
    return product.config?.displayName || product.programName;
}
