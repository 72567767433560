import api, { AssociateCapacity } from '@api';
import { mutateAssociates, useAssociates } from '@api/hooks/closing-calendar-api-hooks';
import { HelpOutline } from '@mui/icons-material';
import {
    Button, DialogContent, Tooltip, Typography
} from '@mui/material';
import {
    DialogActions, RoutedDialog, RoutedDialogImplProps, TextField, usePageMessage
} from '@tsp-ui/components';
import { useParams } from '@tsp-ui/utils';
import Form from '@views/common/components/Form';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './CapacityDialog.module.scss';


interface CapacityDialogParams {
    associateId: string;
}

export default function CapacityDialog(props: RoutedDialogImplProps) {
    const { associateId } = useParams<CapacityDialogParams>();

    const { associates, isLoading } = useAssociates();
    const { capacities, name } = associates?.find(({ id }) => id === associateId) || {};

    const [ loading, setLoading ] = useState(false);

    return (
        <RoutedDialog
            {...props}
            maxWidth={false}
            title="Edit closing capacity"
            loading={isLoading && !associates}
        >
            <DialogContent>
                <Typography
                    fontWeight={500}
                    color="textSecondary"
                    className={styles.helpHeader}
                >
                    Default closing capacity for {name}

                    <Tooltip
                        title={`This is the default capacity for this closer,
                         it can be overridden for specific day(s) by creating a capacity override`}
                    >
                        <HelpOutline color="primary" />
                    </Tooltip>
                </Typography>

                <CapacityForm
                    key={capacities?.associateId}
                    associateId={associateId}
                    capacities={capacities}
                    setLoading={setLoading}
                />
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    form={CapacityForm.formID}
                    type="submit"
                    variant="contained"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface CapacityFormProps {
    associateId: string;
    capacities: AssociateCapacity | undefined;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

function CapacityForm({ associateId, capacities, setLoading }: CapacityFormProps) {
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const formMethods = useForm<AssociateCapacity>({
        defaultValues: capacities || { associateId }
    });

    const saveOverride = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            await api.closingCalendar.updateAssociateCapacity(formValues);

            await mutateAssociates();

            navigate('..');
            pageMessage.success('Closer capacity saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the capacity', error);
        }

        setLoading(false);
    });

    const commonProps = {
        type: 'number',
        required: true,
        rules: {
            min: {
                value: 0,
                message: 'The capacity cannot be negative'
            }
        }
    };

    return (
        <Form
            id={CapacityForm.formID}
            onSubmit={saveOverride}
            formMethods={formMethods}
            className={styles.form}
        >
            <TextField<AssociateCapacity>
                name="sunday"
                label="Sunday"
                {...commonProps}
            />

            <TextField<AssociateCapacity>
                name="monday"
                label="Monday"
                {...commonProps}
            />

            <TextField<AssociateCapacity>
                name="tuesday"
                label="Tuesday"
                {...commonProps}
            />

            <TextField<AssociateCapacity>
                name="wednesday"
                label="Wednesday"
                {...commonProps}
            />

            <TextField<AssociateCapacity>
                name="thursday"
                label="Thursday"
                {...commonProps}
            />

            <TextField<AssociateCapacity>
                name="friday"
                label="Friday"
                {...commonProps}
            />

            <TextField<AssociateCapacity>
                name="saturday"
                label="Saturday"
                {...commonProps}
            />
        </Form>
    );
}

CapacityForm.formID = 'capacity-form';
