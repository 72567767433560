import { createTheme } from '@mui/material';


/**
 * The mui theme for the ppm project. Any variables that change here should
 * alo be changed within app-theme.scss.
 */
export const theme = createTheme({
    palette: {
        primary: {
            main: '#46779B'
        },
        secondary: {
            main: '#9b6946'
        },
        background: {
            default: '#fafafa'
        }
    },

    components: {
        MuiAlert: {
            styleOverrides: {
                root: {
                    padding: '0 12px',
                    alignItems: 'center'
                },
                icon: {
                    padding: 0
                },
                action: {
                    padding: '0 0 0 8px',
                    alignItems: 'center'
                },
                standardSuccess: {
                    border: '1px solid #4CAF50'
                },
                standardError: {
                    border: '1px solid #D32F2F'
                },
                standardInfo: {
                    border: '1px solid #0288d1'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    marginTop: 0,
                    marginBottom: 0
                },
                scrollPaper: {
                    alignItems: 'flex-start',
                    paddingTop: 64
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '16px 24px 16px 24px'
                },
                spacing: {
                    '& > :not(:first-of-type)': {
                        marginLeft: 16
                    }
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover'
            },
            styleOverrides: {
                root: {
                    cursor: 'pointer'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                // The default is -12px, which makes the icon misaligned...
                edgeEnd: { marginRight: -8 },
                edgeStart: { marginLeft: -8 }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    ':last-child > td': {
                        border: 'none'
                    }
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled'
            }
        },
        MuiTooltip: {
            defaultProps: {
                enterDelay: 500,
                disableInteractive: true
            }
        }
    }
});
