import { PaginatedResponse, PaginatedGetQueryParams } from '@tsp-ui/core';
import { Auth } from 'aws-amplify';

import { apiUtils, FrameworkProduct, frameworkProductDisplay } from '../api-utils';


export async function getUsers(queryParams: GetUsersQueryParams): Promise<PaginatedResponse<User>> {
    const usersResponse = await apiUtils.get('/user', queryParams) as PaginatedResponse<User>;

    usersResponse.data.forEach(user => {
        user.productRoles.sort((productRoleA, productRoleB) => (
            frameworkProductDisplay[productRoleA.product].localeCompare(
                frameworkProductDisplay[productRoleB.product]
            )
        ));
    });

    return usersResponse;
}

export function updateUser(user: User): Promise<User> {
    return apiUtils.put('/user', user);
}

export function deleteUser(userId: string) {
    return apiUtils.delete(`/user/${userId}`);
}

export async function sendEmailVerificationCode() {
    return apiUtils.post('/user/sendVerification', {
        accessToken: await (await Auth.currentSession()).getAccessToken()
    },
    {
        emptyResponse: true
    });
}

export interface GetUsersQueryParams extends PaginatedGetQueryParams {
    active: boolean;
    includeInactiveEncompassUsers?: boolean;
    searchTerm?: string;
}

export interface User {
    id: string;
    active: boolean;
    activeInEncompass: boolean;
    cognitoUserId: string;
    encompassUserId: string;
    encompassUserEmail: string;
    encompassInstanceId: string;
    firstName: string;
    lastName: string;
    frameworkRole: 'user' | 'admin';
    productRoles: ProductRole[];
    activatedDate?: string;
    verified?: boolean;
}

export interface ProductRole {
    product: FrameworkProduct;
    roles: ProductRoleDef[];
}

interface ProductRoleDef {
    active: boolean;
    roleId: string;
    roleName: 'user' | 'admin';
}
