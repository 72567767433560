import api, { License } from '@api';
import { Button, DialogContent } from '@mui/material';
import { Dialog, DialogActions } from '@tsp-ui/components';
import { useState } from 'react';

import CreditCardForm, { creditCardFormID } from './CreditCardForm';


interface CreditCardDialogProps {
    onClose: (updatedLicense?: License) => void;
    open: boolean;
}

/**
 * Renders a Dialog to gather billing information
 *
 * @param onClose - A callback function to execute when the modal closes
 * @param open    - Whether the dialog is currently open
 * @constructor
 */
export default function CreditCardDialog({ onClose, open }: CreditCardDialogProps) {
    const [ loading, setLoading ] = useState(false);

    async function handleSubmit() {
        onClose(await api.ppm.payment.pollForUpdatedLicense());
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={() => onClose()}
            title="Enter credit card information"
        >
            <DialogContent>
                <CreditCardForm
                    setLoading={setLoading}
                    onSubmit={handleSubmit}
                />
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    form={creditCardFormID}
                    type="submit"
                    variant="contained"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
