import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Divider, IconButton, Tooltip } from '@mui/material';
import React from 'react';

import styles from './UpDownArrows.module.scss';


interface UpDownArrowsProps {
    onUpClick?: () => void;
    onDownClick?: () => void;
    variant: 'product' | 'group';
}

export default function UpDownArrows({ onUpClick, onDownClick, variant }: UpDownArrowsProps) {
    return (
        <>
            <Tooltip title={onUpClick ? `Move ${variant} up` : ''}>
                <IconButton
                    size="small"
                    onClick={onUpClick}
                    disabled={!onUpClick}
                >
                    <ArrowUpward
                        fontSize="small"
                        color={onUpClick ? 'secondary' : 'disabled'}
                    />
                </IconButton>
            </Tooltip>

            <Tooltip title={onDownClick ? `Move ${variant} down` : ''}>
                <IconButton
                    size="small"
                    onClick={onDownClick}
                    disabled={!onDownClick}
                >
                    <ArrowDownward
                        fontSize="small"
                        color={onDownClick ? 'secondary' : 'disabled'}
                    />
                </IconButton>
            </Tooltip>

            <div className={styles.dividerContainer}>
                <Divider />
            </div>
        </>
    );
}
