import api, {
    frameworkProductDisplay, ProductRole, User
} from '@api';
import { mutateUsers } from '@api/hooks';
import { InfoOutlined } from '@mui/icons-material';
import {
    Button, DialogContent, Paper, Typography
} from '@mui/material';
import {
    Checkbox, DialogActions, FormDialog, Switch, usePageMessage, IconTypography
} from '@tsp-ui/components';
import UserDetails from '@views/ppm/users/components/UserDetails';
import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import styles from './UserDialog.module.scss';


interface UserDialogProps {
    user: User;
    onClose: () => void;
}

interface UserFormValues extends Omit<User, 'productRoles'> {
    isSystemAdmin: boolean;
    productRoles: ProductRoleFormValues[];
}

interface ProductRoleFormValues extends ProductRole {
    isNoneChecked: boolean;
}

function getFormValuesFromUser(user: User): UserFormValues {
    return {
        ...user,
        active: true, // Always set to active when the dialog opens
        productRoles: user.productRoles.map(productRole => ({
            ...productRole,
            isNoneChecked: productRole.roles.every(role => !role.active)
        })),
        isSystemAdmin: user.frameworkRole === 'admin'
    };
}

/**
 * Renders a Dialog to create or edit a user
 *
 * @param onClose             - A callback function to execute when the modal closes
 * @param user                - The user to edit
 * @constructor
 */
export default function UserDialog({ onClose, user }: UserDialogProps) {
    const pageMessage = usePageMessage(250);
    const [ loading, setLoading ] = useState(false);

    const formMethods = useForm<UserFormValues>({
        defaultValues: getFormValuesFromUser(user)
    });

    const handleSubmit = formMethods.handleSubmit(async ({ isSystemAdmin, ...formValues }) => {
        setLoading(true);

        // The email input is disabled so the email address doesn't come through on
        // the new form values. It's not editable, so copy the original user's email.
        if (!formValues.encompassUserEmail) {
            formValues.encompassUserEmail = user!.encompassUserEmail;
        }

        try {
            await api.ppm.user.updateUser({
                ...formValues,
                frameworkRole: isSystemAdmin ? 'admin' : 'user',

                ...(!active && {
                    frameworkRole: 'user',
                    productRoles: formValues.productRoles.map(productRole => ({
                        ...productRole,
                        roles: productRole.roles.map(role => ({
                            ...role,
                            active: false
                        }))
                    }))
                })
            });

            onClose();
            pageMessage.success(!user.active ? 'User activated' : 'Save successful');

            await mutateUsers();
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the user', error);
        } finally {
            setLoading(false);
        }
    });

    const [ tempProductRoles, setTempProductRoles ] = useState<ProductRoleFormValues[]>();
    const isSystemAdmin = formMethods.watch('isSystemAdmin');
    const active = formMethods.watch('active');

    return (
        <FormDialog
            maxWidth="xs"
            classes={{
                paper: styles.dialogPaper
            }}
            open
            onClose={onClose}
            title={!user.active ? 'Activate user' : 'Edit user'}
            onSubmit={handleSubmit}
        >
            <DialogContent className={styles.content}>
                <FormProvider {...formMethods}>
                    <Paper
                        variant="outlined"
                        className={styles.userDetailsContainer}
                    >
                        <div>
                            <UserDetails
                                hideSystemAdminBadge
                                user={user}
                            />
                        </div>

                        <Switch<UserFormValues>
                            name="active"
                            label="Active"
                            disabled={!user.active}
                        />
                    </Paper>

                    {!active ? (
                        <IconTypography
                            icon={(
                                <InfoOutlined
                                    color="primary"
                                    fontSize="small"
                                />
                            )}
                            variant="caption"
                            fontWeight={400}
                            className={styles.info}
                        >
                            Role configuration is unavailable for inactive users
                        </IconTypography>
                    ) : (
                        <>
                            <Typography
                                variant="h6"
                                className={styles.rolesHeader}
                            >
                                Roles

                                <Switch<UserFormValues>
                                    name="isSystemAdmin"
                                    label="System admin"
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            const { productRoles } = formMethods.getValues();
                                            setTempProductRoles(productRoles);

                                            formMethods.setValue('productRoles', productRoles.map(productRole => ({
                                                ...productRole,
                                                isNoneChecked: false,
                                                roles: productRole.roles.map(role => ({
                                                    ...role,
                                                    active: true
                                                }))
                                            })));
                                        } else if (tempProductRoles) {
                                            formMethods.setValue('productRoles', tempProductRoles);
                                            setTempProductRoles(undefined);
                                        }
                                    }}
                                />
                            </Typography>

                            {isSystemAdmin ? (
                                <IconTypography
                                    icon={(
                                        <InfoOutlined
                                            color="primary"
                                            fontSize="small"
                                        />
                                    )}
                                    variant="caption"
                                    fontWeight={400}
                                    className={styles.info}
                                >
                                    System admins have admin rights to all products
                                </IconTypography>
                            ) : (
                                <div className={styles.roleCheckboxContainer}>
                                    <div className={styles.roleCheckboxHeader}>
                                        <Typography fontWeight={500}>
                                            Product Roles
                                        </Typography>

                                        <Typography>
                                            None
                                        </Typography>

                                        <Typography>
                                            User
                                        </Typography>

                                        <Typography>
                                            Admin
                                        </Typography>
                                    </div>

                                    {user?.productRoles.map((productRole, productIndex) => (
                                        <>
                                            <Typography className={styles.productName}>
                                                {frameworkProductDisplay[productRole.product]}
                                            </Typography>

                                            <Checkbox<UserFormValues>
                                                name={`productRoles.${productIndex}.isNoneChecked`}
                                                disabled={isSystemAdmin}
                                                className={styles.checkbox}
                                                onChange={event => {
                                                    if (event.target.checked) {
                                                        productRole.roles.forEach((_, index) => {
                                                            formMethods.setValue(
                                                                `productRoles.${productIndex}.roles.${index}.active`,
                                                                false
                                                            );
                                                        });
                                                    }
                                                }}
                                            />

                                            {productRole.roles.map((role, index) => (
                                                <Checkbox<UserFormValues>
                                                    name={`productRoles.${productIndex}.roles.${index}.active`}
                                                    disabled={isSystemAdmin}
                                                    className={styles.checkbox}
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            if (role.roleName === 'admin') {
                                                                const userRoleIndex = productRole.roles.findIndex(
                                                                    (role) => role.roleName === 'user'
                                                                );

                                                                formMethods.setValue(
                                                                    `productRoles.${productIndex}.roles.${userRoleIndex}.active`,
                                                                    true
                                                                );
                                                            }

                                                            formMethods.setValue(
                                                                `productRoles.${productIndex}.isNoneChecked`,
                                                                false
                                                            );
                                                        } else if (role.roleName === 'user') {
                                                            const adminRoleIndex = productRole.roles.findIndex(
                                                                (role) => role.roleName === 'admin'
                                                            );

                                                            formMethods.setValue(
                                                                `productRoles.${productIndex}.roles.${adminRoleIndex}.active`,
                                                                false
                                                            );
                                                        }
                                                    }}
                                                />
                                            ))}
                                        </>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                >
                    {!user.active ? 'Activate' : 'Save'}
                </Button>
            </DialogActions>
        </FormDialog>
    );
}
