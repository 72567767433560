import * as auditLog from './audit-log';
import * as calculator from './calculator-api';
import * as feed from './feed-api';
import * as lookup from './lookup-api';
import * as payment from './payment-api';
import * as product from './product-api';
import * as scenario from './scenario-api';
import * as tenant from './tenant-api';
import * as user from './user-api';
import * as widget from './widget-api';


const ppmApi = {
    auditLog,
    calculator,
    feed,
    lookup,
    payment,
    product,
    scenario,
    tenant,
    user,
    widget
};

export default ppmApi;
export * from './audit-log';
export * from './calculator-api';
export * from './feed-api';
export * from './lookup-api';
export * from './payment-api';
export * from './product-api';
export * from './scenario-api';
export * from './tenant-api';
export * from './user-api';
export * from './lookups';
export * from './widget-api';
