import { Header } from '@components';
import { Link as MuiLink, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthenticationContext } from '../../AuthenticationContext';

import Page from './components/Page';


export default function NotFoundPage() {
    const { user } = useContext(AuthenticationContext);

    return user ? (
        <Page header="Page not found">
            <Typography>
                Click an item on the menu to find what you're looking for.
            </Typography>
        </Page>
    ) : (
        <>
            <Header />

            <Page header="Page not found">
                <Typography>
                    We couldn't find the page you were looking for.
                </Typography>

                <MuiLink
                    component={Link}
                    to="/"
                >
                    Return home
                </MuiLink>
            </Page>
        </>
    );
}
