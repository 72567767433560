import api, { WebSocketEventType } from '@api';
import {
    Check, Close, MoreHoriz, PriorityHigh
} from '@mui/icons-material';
import {
    Button, CircularProgress, Grow, GrowProps, Paper, Typography
} from '@mui/material';
import {
    IconButton, useConfirm, FileInput, usePageMessage 
} from '@tsp-ui/components';
import Page from '@views/common/components/Page';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useDebounce } from 'use-debounce';

import styles from './BulkUpdaterPage.module.scss';


interface FileEntry {
    id: string;
    file: File;
    status: 'pending' | 'loading' | 'complete' | 'error';
    jobId?: string;
}

let fileId = 0;

export default function BulkUpdaterPage() {
    const confirm = useConfirm();
    const pageMessage = usePageMessage(0);

    const [ fileEntries, setFileEntries ] = useState<FileEntry[]>();

    useEffect(() => (
        api.webSocket.subscribe(WebSocketEventType.BULK_UPDATER_UPDATE, ({ jobId }) => {
            setFileEntries(fileEntries => fileEntries?.map(fileEntry => (
                fileEntry.jobId !== jobId ? fileEntry : {
                    ...fileEntry,
                    status: 'complete'
                }
            )));
        })
    ), []);

    async function handleRemoveFile(fileToRemove: FileEntry) {
        if (fileToRemove.status === 'complete' || await confirm('Are you sure you want to remove this file?')) {
            setFileEntries((fileEntries) => (
                fileEntries?.filter((fileEntry) => fileEntry.id !== fileToRemove.id)
            ));
        }
    }

    async function handleUpload() {
        setFileEntries((fileEntries) => fileEntries?.map((fileEntry) => ({
            ...fileEntry,
            status: 'loading'
        })));

        const formData = new FormData();
        fileEntries?.filter(entry => entry.status === 'pending')
            .forEach(({ file }) => formData.append('files', file));

        try {
            const jobs = await api.bulkUpdater.submitBulkUpload(formData);

            for (const job of jobs) {
                setFileEntries(fileEntries => (
                    fileEntries?.map(fileEntry => (
                        // If the name matches and we already have a jobId, proceed to the next
                        // match in the array because this indicates a duplicate file name
                        (fileEntry.file.name === job.fileName && !fileEntry.jobId)
                            ? {
                                ...fileEntry,
                                jobId: job.jobId
                            }
                            : fileEntry
                    ))
                ));
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading file(s)', error);

            setFileEntries(fileEntries => fileEntries?.map(fileEntry => ({
                ...fileEntry,
                status: 'error'
            })));
        }
    }

    return (
        <Page
            header="Bulk Updater"
            variant="centered"
        >
            <FileInput
                title="file"
                acceptedFileTypes={[ 'xlsx' ]}
                onAddFiles={(newFiles) => {
                    setFileEntries(fileEntries => (fileEntries || []).concat(newFiles.map(file => ({
                        id: `${fileId++}`,
                        file,
                        status: 'pending'
                    }))));
                }}
            />

            <TransitionGroup className={styles.fileContainer}>
                {fileEntries?.map(fileEntry => (
                    <FileCard
                        key={fileEntry.id}
                        fileEntry={fileEntry}
                        onRemoveFile={handleRemoveFile}
                    />
                ))}
            </TransitionGroup>

            {!!fileEntries?.length && (
                <Button
                    variant="contained"
                    onClick={handleUpload}
                    disabled={!!fileEntries?.filter((entry) => entry.status !== 'pending').length}
                >
                    Submit files
                </Button>
            )}
        </Page>
    );
}

type FileCardProps = Omit<GrowProps, 'children'> & {
    fileEntry: FileEntry;
    onRemoveFile: (fileToRemove: FileEntry) => void;
};

function FileCard({ fileEntry, onRemoveFile, ...props }: FileCardProps) {
    const { status } = fileEntry;
    const [ debouncedCompleted ] = useDebounce(status === 'complete', 250);
    const [ debouncedError ] = useDebounce(status === 'error', 250);

    return (
        <Grow {...props}>
            <Paper
                variant="outlined"
                className={clsx(styles.fileCard, {
                    [styles.done]: debouncedCompleted
                })}
            >
                <IconButton
                    className={styles.closeButton}
                    tooltip={status === 'pending' ? 'Remove file' : 'Dismiss'}
                    onClick={() => onRemoveFile(fileEntry)}
                    disabled={status === 'loading'}
                >
                    <Close color="action" />
                </IconButton>

                <Typography
                    fontWeight={500}
                    align="center"
                >
                    {fileEntry.file.name}
                </Typography>

                <div className={styles.progressContainer}>
                    {status === 'pending' ? (
                        <CircularProgress
                            color="inherit"
                            variant="determinate"
                            value={100}
                        />
                    ) : status === 'loading' ? (
                        <CircularProgress />
                    ) : status === 'error' ? (
                        <CircularProgress
                            color="error"
                            variant="determinate"
                            value={100}
                        />
                    ) : (
                        <CircularProgress
                            color="success"
                            variant="determinate"
                            value={!debouncedCompleted ? 0 : 100}
                        />
                    )}

                    <div className={styles.successIcon}>
                        {status === 'pending' ? (
                            <MoreHoriz color="inherit" />
                        ) : status === 'error' ? (
                            <Grow in={debouncedError}>
                                <PriorityHigh color="error" />
                            </Grow>
                        ) : (
                            <Grow in={debouncedCompleted}>
                                <Check color="success" />
                            </Grow>
                        )}
                    </div>
                </div>

                <Typography variant="body2">
                    {status === 'pending'
                        ? 'Awaiting submission'
                        : debouncedCompleted
                            ? 'Loans Updated'
                            : debouncedError
                                ? 'An error occurred'
                                : 'Updating loans'}
                </Typography>

                <div className={styles.doneGrowContainer}>
                    <div
                        className={clsx(styles.doneGrow, {
                            [styles.show]: debouncedCompleted
                        })}
                    />
                </div>
            </Paper>
        </Grow>
    );
}

// function GenerateFileDialog(props: Omit<DialogProps, 'title'>) {
//     const pageMessage = usePageMessage(250);
//     const formMethods = useForm<FormValues>();
//     const fieldLabels: { [ fieldId: string ]: string; } = {
//         4001: 'Borrower First Name',
//         4000: 'Borrower Last Name'
//     };
//
//     const options = [
//         '4001',
//         '4000'
//     ];
//
//     return (
//         <Dialog
//             title="Generate template file"
//             {...props}
//         >
//             <DialogContent>
//                 <FormProvider {...formMethods}>
//                     <Autocomplete
//                         name="fieldIDs"
//                         label="Loan Fields"
//                         multiple
//                         getOptionLabel={(fieldId) => fieldLabels[fieldId]}
//                         options={options}
//                     />
//
//                     <FormHelperText className={styles.helperText}>
//                         Select field IDs to include them in the generated template file
//                     </FormHelperText>
//                 </FormProvider>
//             </DialogContent>
//
//             <DialogActions>
//                 <Button
//                     variant="contained"
//                     onClick={(event) => {
//                         props.onClose?.(event, 'escapeKeyDown');
//                         pageMessage.success('File template downloaded');
//                     }}
//                 >
//                     Generate Template
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     );
// }
//
// interface FormValues {
//     fieldIds: string[];
// }
