import { apiUtils } from '../api-utils';


export async function createSupportTicket(payload: CreateSupportTicketPayload) {
    await apiUtils.post('/zoho/ticket', payload, { emptyResponse: true });
}

export async function createSupportTicketPublic(payload: CreateSupportTicketPublicPayload) {
    await apiUtils.post('/zoho/ticket', payload, {
        emptyResponse: true,
        public: true
    });
}

export function getSupportTeams(): Promise<SupportTeam[]> {
    return apiUtils.get('/zoho/team', null, { public: true });
}

export interface CreateSupportTicketPayload {
    subject: string;
    description: string;
    teamId: string;
    contact: {
        firstName: string;
        lastName: string;
        email: string;
    };
}

export interface CreateSupportTicketPublicPayload extends CreateSupportTicketPayload {
    captchaToken: string;
}

export interface SupportTeam {
    id: string;
    name: string;
}
