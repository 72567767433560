import { User } from '@api';
import {
    Edit as EditIcon,
    RemoveCircleOutline as RemoveCircleOutlineIcon
} from '@mui/icons-material';
import {
    Button,
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { parseAndFormatISO } from '@tsp-ui/utils';
import UserDetails from '@views/ppm/users/components/UserDetails';
import clsx from 'clsx';
import React, { useContext } from 'react';

import { AuthenticationContext } from '../../../../AuthenticationContext';

import styles from './UserCard.module.scss';


interface UserCardProps {
    user: User;
    onEditClick: (user: User) => void;
    onDeleteClick: (user: User) => void;
}

/**
 * Renders a card to display user information
 *
 * @param user          - The user to render
 * @param onEditClick   - Click handler for the edit button
 * @param onDeleteClick - Click handler for the delete button
 * @constructor
 */
export default function UserCard({ user, onEditClick, onDeleteClick }: UserCardProps) {
    const { user: currentUser } = useContext(AuthenticationContext);
    const disableDelete = user.encompassUserEmail === currentUser?.encompassUserEmail;

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div
                className={clsx(styles.container, {
                    [styles.strike]: !user.activeInEncompass
                })}
            >
                <UserDetails user={user} />
            </div>

            <div className={clsx(styles.container, styles.actionsContainer)}>
                {!user.active ? (
                    <Tooltip
                        title={user.activeInEncompass
                            ? ''
                            : 'User must be activated in Encompass before being activated in CapraSuite'}
                    >
                        <span>
                            <Button
                                onClick={() => onEditClick(user)}
                                disabled={!user.activeInEncompass}
                            >
                                Activate
                            </Button>
                        </span>
                    </Tooltip>
                ) : (
                    <div className={styles.actions}>
                        <Tooltip title="Edit user">
                            <IconButton onClick={() => onEditClick(user)}>
                                <EditIcon color="secondary" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            enterDelay={100}
                            title={disableDelete
                                ? 'You cannot deactivate yourself'
                                : 'Deactivate user'}
                        >
                            <span>
                                <IconButton
                                    onClick={() => onDeleteClick(user)}
                                    disabled={disableDelete}
                                    edge="end"
                                >
                                    <RemoveCircleOutlineIcon
                                        color={disableDelete ? 'disabled' : 'error'}
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                )}

                {user.active && (
                    <Typography
                        color="textSecondary"
                        variant="caption"
                        className={styles.createdDate}
                    >
                        {!user.activatedDate ? (
                            'No activated date'
                        ) : (
                            `activated ${parseAndFormatISO(user.activatedDate)}`
                        )}
                    </Typography>
                )}
            </div>
        </Paper>
    );
}
