import api, {
    ActivityLog, ActivityLogType, activityLogTypeDisplay, PaginatedResponse, User
} from '@api';
import { TextField } from '@mui/material';
import {
    DialogProps, GroupedTimelineDialog, renderEnumOptions, ValidatedDateField
} from '@tsp-ui/components';
import { usePageMessage } from '@tsp-ui/components';
import { usePaginatedQueryParams, useAsyncEffect } from '@tsp-ui/utils';
import { endOfDay, startOfDay } from 'date-fns';
import {
    useCallback, useState
} from 'react';


import styles from './ActivityLogDialog.module.scss';
import { UnknownActivityLogCard } from './components/UnknownActivityLogCard';


export default function ActivityLogDialog({ open, ...props }: Omit<DialogProps, 'title'>) {
    const pageMessage = usePageMessage(250);

    const [ pageLoading, setPageLoading ] = useState(true);

    const [ activityLogResponse, setActivityLogResponse ] = useState<PaginatedResponse<ActivityLog>>();
    const [ usersMap ] = useState<Map<string, User>>();

    const [ startDate, setStartDate ] = useState<Date | null>(null);
    const [ endDate, setEndDate ] = useState<Date | null>(null);
    const [ type, setType ] = useState<ActivityLogType | 'all'>('all');

    const [ queryParams, setPageNumber ] = usePaginatedQueryParams({
        startDate: startDate && startOfDay(startDate).toISOString(),
        endDate: endDate && endOfDay(endDate).toISOString(),
        type: type === 'all' ? null : type
    });

    useAsyncEffect(useCallback(async () => {
        if (open) {
            setPageLoading(true);

            try {
                setActivityLogResponse(await api.ppm.auditLog.getAuditLogs(queryParams));
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching the activity log', error);
            } finally {
                setPageLoading(false);
            }
        }
    }, [
        open, queryParams, pageMessage
    ]));

    // useAsyncEffect(useCallback(async () => {
    //     if (open) {
    //         try {
    //             setUsersMap(new Map(
    //                 (await api.ppm.user.getUsers()).map((user) => (
    //                     [ user.id, user ]
    //                 ))
    //             ));
    //         } catch (error) {
    //             pageMessage.handleApiError('An error occurred while fetching users', error);
    //         }
    //     }
    // }, [ open, pageMessage ]));

    const renderActivityLog = useCallback((activityLog: ActivityLog) => (
        <UnknownActivityLogCard
            key={activityLog.id}
            activityLog={activityLog}
            usersMap={usersMap}
        />
    ), [ usersMap ]);

    return (
        <GroupedTimelineDialog
            title="Activity Log"
            loading={!usersMap}
            open={open}
            paginatedItems={activityLogResponse}
            onPageChange={setPageNumber}
            pageLoading={pageLoading}
            dateKey="date"
            renderItem={renderActivityLog}
            filterForm={(
                <form className={styles.form}>
                    <ValidatedDateField
                        label="Start date"
                        value={startDate}
                        onValidDateChange={setStartDate}
                        pickerProps={{
                            disableFuture: true
                        }}
                    />

                    <ValidatedDateField
                        label="End date"
                        value={endDate}
                        onValidDateChange={setEndDate}
                        pickerProps={{
                            disableFuture: true,
                            minDate: startDate || undefined
                        }}
                    />

                    <TextField
                        label="Event type"
                        select
                        value={type}
                        onChange={(event) => setType(event.target.value as ActivityLogType)}
                    >
                        {renderEnumOptions(activityLogTypeDisplay, true)}
                    </TextField>
                </form>
            )}
            {...props}
        />
    );
}
