import { PaymentType } from '@api';
import { AppState } from '@redux/store';


export function getCurrentPaymentInfo(state: AppState) {
    return state.paymentInfo;
}

export function needsACHVerification(state: AppState) {
    const { paymentInfo } = state;

    return paymentInfo?.paymentType === PaymentType.BankTransfer && !paymentInfo?.isACHVerified;
}
