import api, { CapacityOverride, CreateCapacityOverrideRequest } from '@api';
import { mutateAssociates, useAssociates } from '@api/hooks/closing-calendar-api-hooks';
import { Button, DialogContent } from '@mui/material';
import {
    DateField, DialogActions, isValidDate, RoutedDialog, RoutedDialogImplProps, TextField, usePageMessage
} from '@tsp-ui/components';
import { useParams } from '@tsp-ui/utils';
import Form from '@views/common/components/Form';
import {
    addDays, formatISO, isBefore, isEqual, isSameDay, parseISO
} from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './CapacityOverrideDialog.module.scss';


interface CapacityOverrideDialogParams {
    associateId: string;
    overrideId: string;
}

export default function CapacityOverrideDialog(props: RoutedDialogImplProps) {
    const { associateId, overrideId } = useParams<CapacityOverrideDialogParams>();

    const { associates, isLoading } = useAssociates();

    const { capacityOverrides } = associates?.find(({ id }) => id === associateId) || {};
    const override = capacityOverrides?.find(({ id }) => id === overrideId);

    const [ loading, setLoading ] = useState(false);

    return (
        <RoutedDialog
            {...props}
            maxWidth="xs"
            title={`${overrideId ? 'Edit' : 'Add'} capacity override`}
            loading={isLoading && !associates}
        >
            <DialogContent>
                <CapacityOverrideForm
                    key={override?.id}
                    associateId={associateId}
                    override={override}
                    overrides={capacityOverrides}
                    setLoading={setLoading}
                />
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    form={CapacityOverrideForm.formID}
                    type="submit"
                    variant="contained"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface CapacityOverrideFormProps {
    associateId: string;
    override: CapacityOverride | undefined;
    overrides: CapacityOverride[] | undefined;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

function CapacityOverrideForm({
    associateId, override, overrides, setLoading
}: CapacityOverrideFormProps) {
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const formMethods = useForm<CreateCapacityOverrideRequest>({
        defaultValues: override || { associateId }
    });

    const startDateString = formMethods.watch('overrideDate');
    const startDate = startDateString ? parseISO(startDateString) : undefined;
    const endDateString = formMethods.watch('endDate');
    const endDate = endDateString ? parseISO(endDateString) : undefined;

    const saveOverride = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            if (override) {
                await api.closingCalendar.updateCapacityOverride(formValues);
            } else {
                const startDate = parseISO(formValues.overrideDate);
                const endDate = formValues.endDate ? parseISO(formValues.endDate) : startDate;
                const overridePromises = [];

                for (let date = startDate; isBefore(date, endDate) || isEqual(date, endDate); date = addDays(date, 1)) {
                    const overrideForDay = {
                        ...formValues,
                        overrideDate: formatISO(date, { representation: 'date' })
                    };

                    overridePromises.push(overrideForDay);
                }

                await Promise.all(overridePromises.map(async override => {
                    await api.closingCalendar.createCapacityOverride(override);
                }));
            }

            await mutateAssociates();

            navigate('..');
            pageMessage.success('Capacity override saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the capacity override', error);
        }

        setLoading(false);
    });

    return (
        <Form
            id={CapacityOverrideForm.formID}
            onSubmit={saveOverride}
            formMethods={formMethods}
            className={styles.root}
        >
            <DateField<CapacityOverride>
                name="overrideDate"
                label={override ? 'Date' : 'Start Date'}
                autoFocus
                required
                pickerProps={{
                    disablePast: true,
                    shouldDisableDate: (date) => (
                        !!overrides?.some(({ overrideDate }) => isSameDay(date, parseISO(overrideDate)))
                    )
                }}
                rules={{
                    validate: (dateStr) => {
                        dateStr = dateStr?.toString();

                        if (!dateStr || !isValidDate(dateStr)) {
                            return true;
                        }

                        const date = parseISO(dateStr);
                        const invalid = overrides
                            ?.filter(({ id }) => !override || override.id !== id)
                            .some(({ overrideDate }) => isSameDay(date, parseISO(overrideDate)));

                        return invalid
                            ? 'An override already exists for this date'
                            : true;
                    }
                }}
            />

            {!override && (
                <DateField<CreateCapacityOverrideRequest>
                    name="endDate"
                    label="End Date"
                    required
                    pickerProps={{
                        disablePast: true,
                        shouldDisableDate: (date) => (
                            !!overrides?.some(({ overrideDate }) => isSameDay(date, parseISO(overrideDate)))
                        )
                    }}
                    rules={{
                        validate: (dateStr) => {
                            dateStr = dateStr?.toString();

                            if (!dateStr || !isValidDate(dateStr)) {
                                return true;
                            }

                            return startDate && endDate && startDate > endDate
                                ? 'End date cannot be before start date'
                                : undefined;
                        }
                    }}
                />
            )}

            <TextField<CapacityOverride>
                name="value"
                label="Capacity"
                required
                rules={{
                    min: {
                        value: 0,
                        message: 'The capacity cannot be negative'
                    }
                }}
            />

            <TextField<CapacityOverride>
                name="reason"
                label="Reason"
                multiline
                className={styles.textArea}
                rows={3}
            />
        </Form>
    );
}

CapacityOverrideForm.formID = 'capacity-override-form';
