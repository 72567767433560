import { AmplifyProvider } from '@aws-amplify/ui-react';
import { store } from '@redux/store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SentryErrorBoundary } from '@tsp-ui/components';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import {
    useLocation, useNavigationType, createRoutesFromChildren, matchRoutes
} from 'react-router-dom';

import App from './App';

import './index.scss';


if (process.env.NODE_ENV === 'production') {
    const { host } = window.location;

    Sentry.init({
        dsn: 'https://b4f66819483c4acfb625b205e8a25921@o412084.ingest.sentry.io/4505149070311424',
        release: process.env.REACT_APP_VERSION,
        normalizeDepth: 10,
        tracesSampleRate: 0.75,
        integrations: [
            new BrowserTracing({
                tracingOrigins: [
                    host.includes('caprasuite')
                        ? host.startsWith('caprasuite')
                            ? 'api.caprasuite.com'
                            : `${host.split('.')[0]}-api.caprasuite.com`
                        : 'localhost'
                ],
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                )
            })
        ],
        environment: host.includes('caprasuite')
            ? host.startsWith('caprasuite')
                ? 'prod'
                : host.split('.')[0]
            : 'local'
    });
}

let reCaptchaKey = '6LdpsPYlAAAAAJhNMfTZ3F2CyonKABgSmnulz4LK';

if (process.env.NODE_ENV === 'development') {
    reCaptchaKey = '6Let9QwhAAAAAIFRV1rsOrt0hCjG3Agfoo691qzr';
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <StrictMode>
        <SentryErrorBoundary>
            <Provider store={store}>
                <AmplifyProvider>
                    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                        <App />
                    </GoogleReCaptchaProvider>
                </AmplifyProvider>
            </Provider>
        </SentryErrorBoundary>
    </StrictMode>
);
