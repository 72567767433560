import { HelpOutline } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { IconTypography } from '@tsp-ui/components';
import { ReactNode } from 'react';

import styles from './ProductOrGroupsLabel.module.scss';


interface ProductOrGroupsLabelProps {
    children: ReactNode;
    title: string;
}

export default function ProductOrGroupsLabel({ children, title }: ProductOrGroupsLabelProps) {
    return (
        <IconTypography
            icon={(
                <Tooltip title={title}>
                    <HelpOutline color="primary" />
                </Tooltip>
            )}
            iconPosition="after"
            fontWeight={400}
            color="textSecondary"
            className={styles.root}
        >
            {children}
        </IconTypography>
    );
}
