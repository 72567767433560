import { apiUtils } from '../api-utils';


export function getLookups(): Promise<Lookups> {
    return apiUtils.get('/products/ppm/lookup');
}

export function getCounties(stateId: number): Promise<KeyValue[]> {
    return apiUtils.get(`/products/ppm/lookup/county?stateId=${stateId}`);
}

export interface Lookups {
    // agencyApprovals: null;
    bankruptcyTypes: OrderedKeyValue[];
    deliveryTypes: KeyValue[];
    documentationTypes: DocumentationType[];
    // encompassElements: null;
    foreclosureTypes: OrderedKeyValue[];
    investors: KeyValue[];
    lienPositions: KeyValue[];
    loanTerms: LoanTerm[];
    loanUsages: AbbreviatedKeyValue[];
    lockDays: LockDay[];
    // prepayPenaltyTerms: null;
    productOptions: KeyValue[];
    propertyTypes: AbbreviatedKeyValue[];
    propertyUses: OrderedKeyValue[];
    specialProducts: KeyValue[];
    standardProducts: KeyValue[];
    states: State[];
}

export interface KeyValue {
    key: string;
    value: number | null;
}

export interface OrderedKeyValue extends KeyValue {
    displayOrder: number;
}

export interface AbbreviatedKeyValue extends KeyValue {
    abbreviation: string;
}

export interface LoanTerm extends KeyValue {
    term: number;
    fixedTerm: number;
    amortization: number;
    arm: boolean;
    heloc: boolean;
}

export interface State {
    stateId: number;
    state: string;
    abbreviation: string;
}

export interface LockDay {
    lockDays: number;
    virtualLock: number;
}

export interface DocumentationType {
    documentationId: number;
}
