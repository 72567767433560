import api from '@api';
import { AccountCircleOutlined } from '@mui/icons-material';
import {
    Avatar, Button, CircularProgress, Popover, Skeleton, Tooltip, Typography
} from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSwr from 'swr';

import { AuthenticationContext } from '../../../AuthenticationContext';


import styles from './AccountButton.module.scss';


interface AccountButtonProps {
    className?: string;
}

export default function AccountButton({ className }: AccountButtonProps) {
    const navigate = useNavigate();

    const { data: tenant } = useSwr('/tenant/me', api.framework.getCurrentTenant); // TODO tenant

    const { user, logout } = useContext(AuthenticationContext);

    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    return (
        <>
            <Tooltip title="Account details">
                <Button
                    color="primary"
                    variant="outlined"
                    className={className}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    startIcon={<AccountCircleOutlined color="primary" />}
                >
                    {tenant ? tenant.name : (
                        <Skeleton width={100} />
                    )}
                </Button>
            </Tooltip>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.accountMenu
                }}
            >
                {!user ? (
                    <CircularProgress />
                ) : (
                    <div className={styles.nameContainer}>
                        <Avatar className={styles.avatar}>
                            {`${user.firstName[0]}${user.lastName[0]}`}
                        </Avatar>

                        <div>
                            <Typography
                                variant="subtitle1"
                            >
                                {/* eslint-disable-next-line react/jsx-newline */}
                                {user.firstName} {user.lastName}
                            </Typography>

                            <Typography
                                variant="body2"
                            >
                                {user.encompassUserEmail}
                            </Typography>
                        </div>
                    </div>
                )}

                <div className={styles.actionsContainer}>
                    <Button
                        className={styles.logOutButton}
                        onClick={async () => {
                            await logout();
                            navigate('/login');
                        }}
                    >
                        Log out
                    </Button>
                </div>
            </Popover>
        </>
    );
}
