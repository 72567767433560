import api, {
    CreateTenantPayload, Tenant, User
} from '@api';
import { HelpOutline } from '@mui/icons-material';
import {
    Button, DialogContent, MenuItem, Tooltip
} from '@mui/material';
import {
    AddressFieldset, DialogActions, emailPattern, FormDialog, TextField, PhoneField, usePageMessage
} from '@tsp-ui/components';
import { useHasPermission } from '@views/common/hooks/useHasPermission';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';


import styles from './EditAccountDialog.module.scss';


interface EditAccountFormValues extends CreateTenantPayload {
    primaryContactEmail: string;
}

interface EditAccountDialogProps {
    open: boolean;
    onClose: () => void;
    tenant: Tenant | undefined;
}

export default function EditAccountDialog({ open, onClose, tenant }: EditAccountDialogProps) {
    const pageMessage = usePageMessage(250);
    const { isFrameworkAdmin } = useHasPermission();

    const defaultValues = {
        ...tenant,
        primaryContactEmail: tenant?.primaryContact.encompassUserEmail
    };

    const formMethods = useForm<EditAccountFormValues>({ defaultValues });

    const { reset } = formMethods;

    useEffect(() => {
        if (tenant) {
            reset({
                ...tenant,
                primaryContactEmail: tenant?.primaryContact.encompassUserEmail
            });
        }
    }, [ tenant, reset ]);

    const [ loading, setLoading ] = useState(false);
    const handleSubmit = formMethods.handleSubmit(async ({ primaryContactEmail, ...formValues }) => {
        setLoading(true);
        try {
            // TODO update data in swr
            await api.ppm.tenant.updateTenant({
                ...formValues
                // primaryContact: adminUsers?.find(({ email }) => email === primaryContactEmail)!
            });

            pageMessage.success('Account details updated');
            onClose();
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating account details', error);
        } finally {
            setLoading(false);
        }
    });

    const [ adminUsers ] = useState<User[]>();
    // useAsyncEffect(useCallback(async () => {
    //     if (hasAdminPermission) {
    //         try {
    //             await api.ppm.user.getUsers();
    //             setAdminUsers(users.filter(({ role, verified }) => (
    //                 verified && (role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN)
    //             )));
    //         } catch (error) {
    //             pageMessage.handleApiError('An error occurred while fetching users', error);
    //         }
    //     }
    // }, [ pageMessage, hasAdminPermission ]));

    useEffect(() => {
        if (open && !isFrameworkAdmin) {
            pageMessage.error('You don\'t have permission to edit account details');
            onClose();
        }
    }, [
        open, isFrameworkAdmin, pageMessage, onClose
    ]);

    return !isFrameworkAdmin ? null : (
        <FormDialog
            maxWidth="sm"
            open={open}
            onClose={() => {
                formMethods.reset(defaultValues);
                onClose();
            }}
            title="Edit account details"
            onSubmit={handleSubmit}
        >
            <DialogContent className={styles.content}>
                <FormProvider {...formMethods}>
                    <TextField<EditAccountFormValues>
                        name="name"
                        label="Name"
                        required
                        autoFocus
                    />

                    <PhoneField<EditAccountFormValues>
                        name="phoneNumber"
                        label="Phone"
                        countryCode
                        required
                    />

                    <div className={styles.primaryContactContainer}>
                        <TextField<EditAccountFormValues>
                            name="primaryContactEmail"
                            label="Primary contact"
                            required
                            select
                        >
                            {adminUsers?.map(({ firstName, lastName, encompassUserEmail }) => (
                                <MenuItem
                                    value={encompassUserEmail}
                                    key={encompassUserEmail}
                                >{/* eslint-disable-next-line react/jsx-newline */}
                                    {firstName} {lastName}
                                </MenuItem>
                            ))}
                        </TextField>

                        <Tooltip
                            title={`Only admin users who have verified
                                    their email may be assigned as the primary contact`}
                        >
                            <HelpOutline color="primary" />
                        </Tooltip>
                    </div>

                    <TextField<EditAccountFormValues>
                        name="invoiceEmail"
                        label="Billing email"
                        required
                        rules={{ pattern: emailPattern }}
                    />

                    <AddressFieldset<EditAccountFormValues>
                        className={styles.fullWidth}
                        required
                        fieldNames={{
                            street: 'street',
                            city: 'city',
                            state: 'state',
                            zip: 'zip'
                        }}
                    />
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </FormDialog>
    );
}
