import {
    ActivityLog, activityLogTypeDisplay, User
} from '@api';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Grow, IconButton, Paper, Tooltip
} from '@mui/material';
import { TimelineEventCard } from '@tsp-ui/components';
import { parseISO } from 'date-fns';
import { ReactNode, useState } from 'react';
import { useDebounce } from 'use-debounce';

import styles from './ActivityLogCard.module.scss';


export type ActivityLogCardImplProps<TEntity> = Pick<ActivityLogCardProps<TEntity>, 'activityLog' | 'usersMap'>;

export interface ActivityLogCardProps<TEntity> {
    children?: JSX.Element;
    activityLog: ActivityLog<TEntity>;
    showExpand: boolean;
    usersMap: Map<string, User> | undefined;
    entityNameDisplay: ReactNode | null;
}

export function ActivityLogCard<TEntity>({
    children, activityLog, showExpand, usersMap, entityNameDisplay
}: ActivityLogCardProps<TEntity>) {
    const {
        type, date: dateStr, userId
    } = activityLog;

    const [ expanded, setExpanded ] = useState(false);
    const [ debouncedExpanded ] = useDebounce(expanded, 250);
    const user = usersMap?.get(userId || '');

    return (
        <TimelineEventCard
            date={parseISO(dateStr)}
            secondaryText={user && `by ${user.firstName} ${user.lastName}`}
            header={(
                <>
                    {activityLogTypeDisplay[type]}

                    {entityNameDisplay && (
                        <>
                            {' '}{entityNameDisplay}
                        </>
                    )}
                </>
            )}
            actions={showExpand && (
                <Tooltip title={expanded ? 'Show more' : 'Show less'}>
                    <IconButton
                        size="small"
                        edge="end"
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? (
                            <ExpandLess
                                color="secondary"
                                fontSize="small"
                            />
                        ) : (
                            <ExpandMore
                                color="secondary"
                                fontSize="small"
                            />
                        )}
                    </IconButton>
                </Tooltip>
            )}
        >
            {children && (expanded || debouncedExpanded) && (
                <Grow in={!expanded ? (debouncedExpanded && expanded) : true}>
                    <Paper
                        variant="outlined"
                        className={styles.expandedContent}
                    >
                        <div className={styles.scrollContainer}>
                            {children}
                        </div>
                    </Paper>
                </Grow>
            )}
        </TimelineEventCard>
    );
}
