import { AppBar, Toolbar } from '@mui/material';
import React from 'react';


import styles from './Header.module.scss';
import { Logo } from './Logo';


/**
 * Renders the header for the CapraSuite project when unauthenticated
 * @constructor
 */
export function Header() {
    return (
        <AppBar
            position="static"
            className={styles.header}
        >
            <Toolbar>
                <Logo />
            </Toolbar>
        </AppBar>
    );
}
