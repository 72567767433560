import { GetUsersQueryParams, User } from '@api';
import { useUsers } from '@api/hooks';
import {
    Button, Pagination, Skeleton, Typography
} from '@mui/material';
import {
    FilledSection, Loader, OptionalWrapper, usePageMessage
} from '@tsp-ui/components';
import { usePaginatedQueryParams } from '@tsp-ui/utils';
import { ReactNode } from 'react';


import UserCard from './UserCard';
import styles from './UserSection.module.scss';


interface UserSectionProps {
    header: ReactNode;
    onEditUser: (user: User) => void;
    onDeleteUser: (user: User) => void;
    noResultsBody: ReactNode;
    noResultsCaption: ReactNode;
    queryParams: Omit<GetUsersQueryParams, 'pageNumber'>;
}

/**
 * Renders a section of users on the UsersPage
 *
 * @param header           - The header of the section
 * @param onEditUser       - A callback function to execute when a user is edited
 * @param onDeleteUser     - A callback function to execute when a user is deleted
 * @param noResultsBody    - The body to display when there are no results
 * @param noResultsCaption - The caption to display when there are no results
 * @param queryParams      - The query params to pass to the get users endpoint
 * @constructor
 */
export default function UserSection({
    header,
    onEditUser,
    onDeleteUser,
    noResultsBody,
    noResultsCaption,
    queryParams: queryParamsProp
}: UserSectionProps) {
    const pageMessage = usePageMessage();
    const [ queryParams, setPageNumber ] = usePaginatedQueryParams(queryParamsProp);

    const {
        usersResponse, isLoading, isValidating, error, isLagging, mutate
    } = useUsers(queryParams);

    const errorMessage = `An error occurred while fetching ${queryParams.active ? 'active' : 'inactive'} users`;
    pageMessage.useHandleApiError(errorMessage, error);

    const { data: users } = usersResponse || {};

    const skeleton = (
        <Skeleton
            variant="rounded"
            width="100%"
        >
            <UserCard
                user={emptyUser}
                onEditClick={onEditUser}
                onDeleteClick={onDeleteUser}
            />
        </Skeleton>
    );

    return (
        <FilledSection
            scrollable
            className={styles.root}
            header={header}
            footer={(!error || isLoading) && (
                <OptionalWrapper
                    renderWrapper={!usersResponse}
                    Component={Skeleton}
                    variant="rounded"
                    className={styles.paginationSkeleton}
                >
                    <Pagination
                        size="small"
                        color="primary"
                        count={usersResponse?.totalPages}
                        page={usersResponse?.pageNumber || queryParams.pageNumber}
                        className={styles.pagination}
                        classes={{ ul: styles.paginationItems }}
                        onChange={(_, page) => setPageNumber(page)}
                    />
                </OptionalWrapper>
            )}
        >
            <Loader
                loading={isLoading || isValidating}
                className={styles.loader}
            />

            {isLoading && !isLagging ? (
                <div className={styles.cardContainer}>
                    {skeleton}

                    {skeleton}

                    {skeleton}
                </div>
            ) : (
                error ? (
                    <>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {errorMessage}
                        </Typography>

                        <Button onClick={() => mutate()}>
                            Try again
                        </Button>
                    </>
                ) : !users?.length ? (
                    <>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {noResultsBody}
                        </Typography>

                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            {noResultsCaption}
                        </Typography>
                    </>
                ) : (
                    <div className={styles.cardContainer}>
                        {users.map(user => (
                            <UserCard
                                key={user.encompassUserId}
                                user={user}
                                onEditClick={onEditUser}
                                onDeleteClick={onDeleteUser}
                            />
                        ))}
                    </div>
                )
            )}
        </FilledSection>
    );
}

const emptyUser: User = {
    productRoles: [],
    id: '0d4b0091-9e40-4281-8770-6007ffe81381',
    encompassUserId: 'userid',
    encompassUserEmail: 'email@somwewhere.com',
    firstName: 'First',
    lastName: 'Last',
    cognitoUserId: 'guid',
    frameworkRole: 'admin',
    encompassInstanceId: 'encompassInstanceId',
    active: true,
    activeInEncompass: true
};
