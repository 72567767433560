import { Link as MuiLink, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './EntityNameDisplay.module.scss';


interface EntityNameDisplayProps {
    label: string;
    to?: string;
    entityType: 'feed' | 'scenario';
}

export function EntityNameDisplay({ label, to, entityType }: EntityNameDisplayProps) {
    return (
        <Typography
            color="textSecondary"
            variant="caption"
            component="span"
        >
            (
            {to ? (
                <MuiLink
                    component={Link}
                    to={to}
                >
                    {label}
                </MuiLink>
            ) : (
                <Tooltip title={`This ${entityType} no longer exists`}>
                    <span className={styles.entityMissing}>
                        {label}
                    </span>
                </Tooltip>
            )}
            )
        </Typography>
    );
}
