import api, { LoanAuditLoanDetail } from '@api';
import { DialogContent, Typography } from '@mui/material';
import {
    FilledSection,
    HorizontalLabeledValue,
    HorizontalLabelGroup,
    RoutedDialog,
    RoutedDialogImplProps,
    usePageMessage
} from '@tsp-ui/components';
import { useAsyncEffect, useParams } from '@tsp-ui/utils';
import { LoanAuditEntryCard } from '@views/loan-auditor/components/LoanAuditEntryCard';
import React, { useCallback, useState } from 'react';

import styles from './LoanAuditDialog.module.scss';


interface LoanAuditDialogParams {
    loanID: string;
}

export function LoanAuditDialog(props: RoutedDialogImplProps) {
    const pageMessage = usePageMessage();
    const { loanID } = useParams<LoanAuditDialogParams>();

    const [ loading, setLoading ] = useState(true);
    const [ loanAuditDetail, setLoanAuditDetail ] = useState<LoanAuditLoanDetail>();

    useAsyncEffect(useCallback(async () => {
        try {
            setLoanAuditDetail(await api.loanAuditor.getLoanAuditLoanDetail(loanID));
            setLoading(false);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the loan audit details', error);
            setLoading(false);
        }
    }, [ pageMessage, loanID ]));

    const halfPoint = Math.ceil((loanAuditDetail?.currentData.length || 0) / 2);
    const fieldGroups = loanAuditDetail && [
        loanAuditDetail.currentData.slice(0, halfPoint),
        loanAuditDetail.currentData.slice(halfPoint, loanAuditDetail.currentData.length)
    ];

    return (
        <RoutedDialog
            {...props}
            maxWidth={false}
            title="Loan Audit Log"
            loading={loading}
        >
            <DialogContent className={styles.root}>
                <div>
                    <Typography>
                        Loan #

                        <Typography
                            component="span"
                            fontWeight={500}
                        >
                            {loanAuditDetail?.loanNumber || '--'}
                        </Typography>
                    </Typography>

                    {fieldGroups && (
                        <div className={styles.loanData}>
                            {fieldGroups.map((changeSets) => (
                                <HorizontalLabelGroup>
                                    {changeSets.map((changeSet) => (
                                        <HorizontalLabeledValue
                                            variant="body1"
                                            labelVariant="body2"
                                            label={`${changeSet.fieldId}:`}
                                            value={changeSet.value}
                                        />
                                    ))}
                                </HorizontalLabelGroup>
                            ))}
                        </div>
                    )}
                </div>

                <FilledSection
                    variant="light"
                    header="Change history"
                    headerTypographyProps={{
                        variant: 'body1',
                        fontWeight: 500
                    }}
                    scrollable
                >
                    <div className={styles.entries}>
                        {loanAuditDetail?.auditEntries.map((entry) => (
                            <LoanAuditEntryCard
                                key={entry.id}
                                entry={entry}
                                hideLoanNumber
                            />
                        ))}
                    </div>
                </FilledSection>
            </DialogContent>
        </RoutedDialog>
    );
}
