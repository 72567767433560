import api from '@api';
import { laggyMiddleware, WithLaggy } from '@api/hooks/laggyMiddleware';
import useSwr, { mutate } from 'swr';


const associatesKey = '/products/closing-calendar/associates';
const instanceKey = '/products/closing-calendar/instance';

export function useAssociates() {
    const swrResult = useSwr(associatesKey, () => api.closingCalendar.getAssociates(), {
        use: [ laggyMiddleware ]
    });

    return {
        ...(swrResult as WithLaggy<typeof swrResult>),
        associates: swrResult.data
    };
}

export function mutateAssociates() {
    return mutate((key) => key === associatesKey, undefined, {
        throwOnError: false
    });
}

export function useCalendarInstance() {
    const swrResult = useSwr(instanceKey, () => api.closingCalendar.getCalendarInstance());

    return {
        ...(swrResult),
        instance: swrResult.data
    };
}
