import api, { Product, ProductConfig } from '@api';
import { Typography } from '@mui/material';
import { fetchProducts } from '@redux/entities';
import { useDispatch } from '@redux/store';
import { TextField, usePageMessage } from '@tsp-ui/components';
import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Form from '../../../common/components/Form';

import styles from './ProductConfigForm.module.scss';


export interface ProductFormProps {
    product: Product | undefined;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function ProductConfigForm({ product, setLoading }: ProductFormProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const pageMessage = usePageMessage();
    const formMethods = useForm<ProductConfig>({
        defaultValues: product?.config
    });

    const saveProductConfig = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            if (product) {
                if (product.config) {
                    await api.ppm.product.updateProductConfig(product.id, formValues);
                } else {
                    await api.ppm.product.createProductConfig(product.id, formValues);
                }

                await dispatch(fetchProducts());

                navigate('/apps/ppm/products');
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while configuring the product', error);
        }

        setLoading(false);
    });

    return (
        <Form
            id={ProductConfigForm.formID}
            onSubmit={saveProductConfig}
            formMethods={formMethods}
            className={styles.root}
        >
            <Typography
                fontWeight={500}
                color="textSecondary"
                className={styles.header}
            >
                {product?.programName}
            </Typography>

            <TextField<ProductConfig>
                name="displayName"
                label="Display name"
                defaultValue={product?.programName}
                autoFocus
                required
                helperText="This value will override the program name returned from EPPS for feed widget displays."
            />

            <TextField<ProductConfig>
                name="description"
                label="Description"
                autoFocus
                required
                multiline
                helperText="This value will be displayed when a customer clicks the info icon for this product on
                         feed widget displays."
            />
        </Form>
    );
}

ProductConfigForm.formID = 'product-config-form';
