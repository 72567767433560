import {
    Scenario, Product, FeedWithTokens
} from '@api';
import { createReducer } from '@reduxjs/toolkit';

import {
    deleteScenario, fetchScenarios, fetchProducts, deleteProductConfig, fetchFeeds, deleteFeed
} from './entities.actions';


export interface EntitiesState {
    pendingRequests: {
        scenarios: string | null;
        products: string | null;
        feeds: string | null;
    };
    scenarios: null | {
        [key: string]: Scenario;
    };
    products: null | {
        [key: string]: Product;
    };
    feeds: null | {
        [key: string]: FeedWithTokens;
    };
}

const defaultState: EntitiesState = {
    pendingRequests: {
        scenarios: null,
        products: null,
        feeds: null
    },
    scenarios: null,
    products: null,
    feeds: null
};

export const reducer = createReducer(defaultState, builder => builder
    .addCase(fetchScenarios.pending, (state, action) => {
        state.pendingRequests.scenarios = action.meta.requestId;
    })
    .addCase(fetchScenarios.fulfilled, (state, action) => {
        state.scenarios = createIdBasedObjectFromList(action.payload);
        state.pendingRequests.scenarios = null;
    })
    .addCase(fetchScenarios.rejected, (state) => {
        state.pendingRequests.scenarios = null;
    })
    .addCase(deleteScenario.fulfilled, (state, action) => {
        if (state.scenarios) {
            state.scenarios = Object.fromEntries(
                Object.entries(state.scenarios).filter(([ id ]) => id !== action.meta.arg)
            );
        }
    })

    .addCase(fetchProducts.pending, (state, action) => {
        state.pendingRequests.products = action.meta.requestId;
    })
    .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = createIdBasedObjectFromList(action.payload);
        state.pendingRequests.products = null;
    })
    .addCase(fetchProducts.rejected, (state) => {
        state.pendingRequests.products = null;
    })
    .addCase(deleteProductConfig.fulfilled, (state, action) => {
        if (state.products) {
            state.products[action.meta.arg].config = undefined;
        }
    })

    .addCase(fetchFeeds.pending, (state, action) => {
        state.pendingRequests.feeds = action.meta.requestId;
    })
    .addCase(fetchFeeds.fulfilled, (state, action) => {
        state.feeds = createIdBasedObjectFromList(action.payload);
        state.pendingRequests.feeds = null;
    })
    .addCase(fetchFeeds.rejected, (state) => {
        state.pendingRequests.feeds = null;
    })
    .addCase(deleteFeed.fulfilled, (state, action) => {
        if (state.feeds) {
            state.feeds = Object.fromEntries(
                Object.entries(state.feeds).filter(([ id ]) => id !== action.meta.arg)
            );
        }
    }));

function createIdBasedObjectFromList<
    TObjectType extends { id: string }
>(list: TObjectType[]) {
    return Object.fromEntries(list.map(obj => (
        [ obj.id, obj ]
    )));
}
