import { WebSocketEventType } from '@api';


export class WebSocketEvent<TData> extends Event {
    readonly data: TData;

    constructor(type: WebSocketEventType, data: TData) {
        super(type);

        this.data = data;
    }
}
