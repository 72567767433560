import { Search } from '@mui/icons-material';
import {
    Button, InputAdornment, Link as MuiLink, TextField, Typography
} from '@mui/material';
import { getProductsList, getScenariosList, useEntitySelector } from '@redux/entities';
import { RoutedDialogManager } from '@tsp-ui/components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import Page from '../../common/components/Page';

import styles from './ProductsPage.module.scss';
import EditProductConfigDialog from './components/EditProductConfigDialog';
import ProductSection from './components/ProductSection';


/**
 * Renders the products page
 *
 * @constructor
 */
export default function ProductsPage() {
    const products = useEntitySelector({
        entityType: 'products',
        selector: getProductsList,
        forceRefresh: true
    });

    const scenarios = useEntitySelector({
        entityType: 'scenarios',
        selector: getScenariosList
    });

    const [ showInactive, setShowInactive ] = useState(false);

    const [ filterInputValue, setFilterInputValue ] = useState('');
    const [ debouncedFilterInputValue ] = useDebounce(filterInputValue, 250);
    const filteredProducts = products?.filter((product) => (
        product.programName.toLowerCase().includes(debouncedFilterInputValue.toLowerCase())
        || product.config?.displayName.toLowerCase().includes(debouncedFilterInputValue.toLowerCase())
    ));

    const configuredProducts = filteredProducts?.filter(({ config }) => config);
    const inactiveProducts = filteredProducts?.filter(({ availableScenarioIds }) => !availableScenarioIds?.length);
    const unconfiguredProducts = filteredProducts?.filter(({ config, programId }) => (
        !config && !inactiveProducts?.some((inactiveProduct) => programId === inactiveProduct.programId)
    ));

    return (
        <Page
            header="Products"
            helpContent="Configure your priced products for a user-friendly display when included in a feed."
            className={styles.main}
            loading={!products}
            headerActions={(
                <Button onClick={() => setShowInactive(!showInactive)}>
                    {showInactive ? 'Hide' : 'Show'} inactive
                </Button>
            )}
            filterField={(
                <TextField
                    variant="standard"
                    placeholder="Filter products"
                    value={filterInputValue}
                    onChange={(event) => setFilterInputValue(event.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            )}
        >
            {!scenarios?.length ? (
                <Typography color="textSecondary">
                    You need to create a scenario before configuring products.{' '}

                    <MuiLink
                        component={Link}
                        to="/apps/ppm/scenarios/create"
                    >
                        Create a scenario{' '}
                    </MuiLink>

                    to get started.
                </Typography>
            ) : !products?.length ? (
                <Typography color="textSecondary">
                    Your current scenarios don't have any product results.{' '}

                    <MuiLink
                        component={Link}
                        to="/apps/ppm/scenarios"
                    >
                        Configure your scenarios{' '}
                    </MuiLink>

                    to return products.
                </Typography>
            ) : (
                <div className={styles.root}>
                    <ProductSection
                        header="Configured"
                        products={configuredProducts}
                        noResultsBody={filterInputValue
                            ? 'No configured products match your filter query.'
                            : 'You haven\'t configured any products yet.'}
                        noResultsCaption={!filterInputValue
                            && 'Find a product in the unconfigured section to add a configuration.'}
                    />

                    <ProductSection
                        header="Unconfigured"
                        products={unconfiguredProducts}
                        noResultsBody={filterInputValue
                            ? 'No unconfigured products match your filter query.'
                            : 'You don\'t have any unconfigured products.'}
                        noResultsCaption={!filterInputValue
                            && 'Create scenarios that include missing products to configure them.'}
                    />

                    {showInactive && (
                        <ProductSection
                            header="Inactive"
                            products={inactiveProducts}
                            noResultsBody={filterInputValue
                                ? 'No inactive products match your filter query.'
                                : 'You don\'t have any inactive products.'}
                            noResultsCaption={!filterInputValue
                                && 'Products will be marked inactive when they no longer appear in any scenarios.'}
                        />
                    )}
                </div>
            )}

            <RoutedDialogManager routes={dialogRoutes} />
        </Page>
    );
}

const dialogRoutes = {
    ':productID/config': EditProductConfigDialog
};
