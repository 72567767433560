import { User } from '@api';
import { EmailLink } from '@tsp-ui/components';


interface PrimaryContactDisplayProps {
    contact: User;
}

export default function PrimaryContactDisplay({ contact }: PrimaryContactDisplayProps) {
    return (
        <>
            {/* eslint-disable-next-line react/jsx-newline */}
            {contact.firstName} {contact.lastName}<br />

            <EmailLink email={contact.encompassUserEmail} />
        </>
    );
}
