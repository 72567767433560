import { Product } from '@api';
import { Edit, RemoveCircleOutline, Settings } from '@mui/icons-material';
import {
    Divider, IconButton, Link as MuiLink, Paper, Popover, Tooltip, Typography
} from '@mui/material';
import { deleteProductConfig, getScenarios, useEntitySelector } from '@redux/entities';
import { useDispatch } from '@redux/store';
import {
    HorizontalLabeledValue, HorizontalLabelGroup, useConfirm, usePageMessage
} from '@tsp-ui/components';
import utilStyles from '@tsp-ui/core/sass/style-utils.module.scss';
import clsx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './ProductCard.module.scss';


interface ProductCardProps {
    product: Product;
}

export default function ProductCard({
    product: {
        id, config, programId, programName, availableScenarioIds
    }
}: ProductCardProps) {
    const confirm = useConfirm();
    const dispatch = useDispatch();
    const pageMessage = usePageMessage();

    const isInactive = !availableScenarioIds?.length;

    async function handleDeleteProductConfig() {
        if (await confirm('Are you sure you want to delete this product\'s configuration?')) {
            const result = await dispatch(deleteProductConfig(id));

            if (deleteProductConfig.rejected.match(result)) {
                pageMessage.handleApiError('There was an error deleting the product config', result.error);
            }
        }
    }

    return (
        <Paper
            elevation={0}
            className={styles.root}
        >
            <Typography fontWeight={500}>
                {programName}
            </Typography>

            <div className={styles.actions}>
                {config ? (
                    <>
                        <Tooltip
                            title={isInactive
                                ? 'Configurations cannot be edited on inactive products'
                                : 'Edit product configuration'}
                        >
                            <span>
                                <IconButton
                                    component={Link}
                                    to={`/apps/ppm/products/${id}/config`}
                                    disabled={isInactive}
                                >
                                    <Edit color={isInactive ? 'disabled' : 'secondary'} />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Tooltip
                            title={isInactive
                                ? 'Configurations cannot be removed on inactive products'
                                : 'Remove configuration'}
                        >
                            <span>
                                <IconButton
                                    edge="end"
                                    disabled={isInactive}
                                    onClick={handleDeleteProductConfig}
                                >
                                    <RemoveCircleOutline color={isInactive ? 'disabled' : 'error'} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                ) : (
                    <Tooltip
                        title={isInactive
                            ? 'Configurations cannot be added to inactive products'
                            : 'Configure product'}
                    >
                        <span>
                            <IconButton
                                component={Link}
                                to={`/apps/ppm/products/${id}/config`}
                                edge="end"
                                disabled={isInactive}
                            >
                                <Settings color={isInactive ? 'disabled' : 'secondary'} />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </div>

            <Typography // TODO add display for "Visible in x feeds"
                color="textSecondary"
                variant="body2"
            >
                {!isInactive ? (
                    <>
                        Available in{' '}

                        <ScenariosLink scenarioIDs={availableScenarioIds} />
                    </>
                ) : (
                    'Not available in any scenarios'
                )}
            </Typography>

            <HorizontalLabelGroup>
                <HorizontalLabeledValue
                    label="Program ID:"
                    value={programId}
                />
            </HorizontalLabelGroup>

            {!isInactive && config && (
                <>
                    <Divider className={clsx(utilStyles.fullWidth, styles.divider)} />

                    <div className={utilStyles.fullWidth}>
                        <HorizontalLabelGroup>
                            <HorizontalLabeledValue
                                label="Display name:"
                                value={config.displayName}
                            />

                            <HorizontalLabeledValue
                                label="Description:"
                                value={config.description}
                            />
                        </HorizontalLabelGroup>
                    </div>
                </>
            )}
        </Paper>
    );
}

interface ScenariosLinkProps {
    scenarioIDs?: string[];
}

function ScenariosLink({ scenarioIDs }: ScenariosLinkProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    const scenarios = useEntitySelector({
        entityType: 'scenarios',
        selector: getScenarios
    });

    return !scenarioIDs ? null : (
        <>
            <MuiLink onClick={(event) => setAnchorEl(event.currentTarget)}>
                {scenarioIDs.length} scenario

                {scenarioIDs.length > 1 && 's'}
            </MuiLink>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
                classes={{
                    paper: styles.scenariosPopoverPaper
                }}
            >
                <div className={styles.scenarios}>
                    {scenarioIDs.map(scenarioID => (
                        <MuiLink
                            key={scenarioID}
                            component={Link}
                            to={`/apps/ppm/scenarios/${scenarioID}/edit`}
                        >
                            {scenarios?.[scenarioID]?.name}
                        </MuiLink>
                    ))}
                </div>
            </Popover>
        </>
    );
}
