import { apiUtils } from '../api-utils';


const subselectionID = '431ebb44-41b0-4dba-a291-3a63fbbc44ae';

export function getLoanAuditEntries(): Promise<LoanAuditEntry[]> {
    return apiUtils.get(`/products/productdatabridge/changesets/subselection/${subselectionID}?start=0&take=25`);
}

export function getLoanAuditLoanDetail(loanID: string): Promise<LoanAuditLoanDetail> {
    return apiUtils.get(`/products/productdatabridge/LoanAudit/${subselectionID}/${loanID}`);
}

enum LoanAuditUpdateType {
    EXISTING_FIELD_UPDATED,
    FIELD_ADDED_TO_DATASET,
    FIELD_REMOVED_FROM_DATASET,
    DATASET_CREATED_INSERT
}

export interface LoanAuditEntry {
    id: string;
    name: string;
    isCurrent: boolean;
    created: string;
    changeBy: string;
    loanId: string;
    friendlyLoanNumber: string;
    changeSets: ChangeSet[];
}

interface ChangeSet {
    field: string;
    oldValue: null | string;
    newValue: null | string;
    updateType: LoanAuditUpdateType;
    fieldDataId: string;
}

export interface LoanAuditLoanDetail {
    loanNumber: string;
    loanId: string;
    currentData: LoanField[];
    auditEntries: LoanAuditEntry[];
}

interface LoanField {
    fieldId: string;
    value: string;
}
