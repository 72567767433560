import { apiUtils } from '../api-utils';


export function submitBulkUpload(formData: FormData): Promise<BulkUploadJob[]> {
    return apiUtils.post('/products/ProductBulkUpdater/loans/update', formData, {
        useAutoContentType: true
    });
}

export interface BulkUploadJob {
    jobId: string;
    fileName: string;
}
