import api, { apiUtils } from '@api';

import { configureAmplify } from './configureAmplify';


export interface AppConfig {
    apiUrl: string;
    publicApiUrl: string;
    stripeKey: string;
    widgetAssetUrls: {
        css: string;
        js: string;
    };
    awsAuth: {
        identityPoolId: string;
        region: string;
        userPoolId: string;
        userPoolWebClientId: string;
    };
}

export const localConfig: AppConfig = {
    apiUrl: 'https://dev-api.caprasuite.com',
    publicApiUrl: 'https://dev-webapi.ppm-tsp.com/public',
    stripeKey: 'pk_test_51JHWrcJJf2UoZg3wx7kHjB3EFiRAPw0WZAiKi4RmtyXUU9r8q5TgCd0L9enjVbVnBIqGprkvMtL8EOrFdLq2C1bK00SPRRoaoD',
    widgetAssetUrls: {
        css: '/ppm-widgets.css',
        js: '/ppm-widgets.js'
    },
    awsAuth: {
        identityPoolId: 'us-east-1:e33d5ef6-674a-4117-b0da-aac6b5c8b021',
        region: 'us-east-1',
        userPoolId: 'us-east-1_L7g1q4RN7',
        userPoolWebClientId: '6jdhqv629898mk79gimfvu4mpp'
    }
};

/**
 * Loads config variables for the current environment and uses them to configure services that depend on them.
 * If the app is running in production mode, fetches the config json from the server, else falls back to the
 * local config defined above.
 */
export default async function configureApp() {
    let config = localConfig;

    if (process.env.NODE_ENV === 'production') {
        const configResponse = await fetch('/config.json');
        config = await configResponse.json();
    }

    configureAmplify(config);
    apiUtils.configure(config);

    if (!api.webSocket.url) {
        api.webSocket.url = `${config.apiUrl}/hub`;
    }

    return config;
}
