import api, { CreateSupportTicketPayload, SupportTeam, User } from '@api';
import {
    Button, DialogContent, Link, MenuItem, Typography
} from '@mui/material';
import { useDispatch, useSelector } from '@redux/store';
import { closeSupportDialog, getDefaultSupportTeam, isSupportModalOpen } from '@redux/support';
import {
    DialogActions, emailPattern, FormDialog, TextField, usePageMessage
} from '@tsp-ui/components';
import { useAsyncEffect } from '@tsp-ui/utils';
import clsx from 'clsx';
import {
    useCallback, useContext, useEffect, useState
} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm, FormProvider } from 'react-hook-form';


import { AuthenticationContext } from '../../../../AuthenticationContext';

import styles from './ContactUsDialog.module.scss';


export default function ContactUsDialog() {
    const dispatch = useDispatch();

    const { user } = useContext(AuthenticationContext);
    const pageMessage = usePageMessage(250);

    const open = useSelector(isSupportModalOpen);
    const defaultTeam = useSelector(getDefaultSupportTeam);

    const [ loading, setLoading ] = useState(false);
    const [ teamsLoading, setTeamsLoading ] = useState(true);
    const [ teams, setTeams ] = useState<SupportTeam[]>();

    const formMethods = useForm<CreateSupportTicketPayload>();
    const { reset } = formMethods;

    useEffect(() => {
        reset(getDefaultValues(user, teams, defaultTeam));
    }, [
        user, reset, teams, defaultTeam
    ]);

    useAsyncEffect(useCallback(async () => {
        if (open) {
            try {
                setTeams(await api.framework.getSupportTeams());
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching support options', error);
            }

            setTeamsLoading(false);
        }
    }, [ pageMessage, open ]));

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            if (!user && !executeRecaptcha) {
                return pageMessage.error('Error validating recaptcha. Please try again.');
            }

            if (user) {
                await api.framework.createSupportTicket(formValues);
            } else if (executeRecaptcha) {
                await api.framework.createSupportTicketPublic({
                    captchaToken: await executeRecaptcha('public_contact_us'),
                    ...formValues
                });
            }

            closeDialog();
            pageMessage.success('Your feedback has been sent. We will get back to you shortly.');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while submitting feedback', error);
        } finally {
            setLoading(false);
        }
    });

    function closeDialog() {
        dispatch(closeSupportDialog());
        reset(user ? getDefaultValues(user, teams, defaultTeam) : {});
    }

    return (
        <FormDialog
            title="Contact us"
            open={open}
            onClose={closeDialog}
            maxWidth="xs"
            onSubmit={handleSubmit}
            loading={teamsLoading}
        >
            <DialogContent className={styles.content}>
                <FormProvider {...formMethods}>
                    <Typography
                        color="textSecondary"
                        className={styles.fieldLabel}
                    >
                        Your contact info
                    </Typography>

                    <div className={styles.nameFields}>
                        <TextField<CreateSupportTicketPayload>
                            name="contact.firstName"
                            label="First name"
                            required
                        />

                        <TextField<CreateSupportTicketPayload>
                            name="contact.lastName"
                            label="Last name"
                        />
                    </div>

                    <TextField<CreateSupportTicketPayload>
                        name="contact.email"
                        label="Email"
                        required
                        rules={{ pattern: emailPattern }}
                    />

                    <Typography
                        color="textSecondary"
                        className={clsx(styles.fieldLabel, styles.issueLabel)}
                    >
                        Issue information
                    </Typography>

                    <TextField<CreateSupportTicketPayload>
                        name="teamId"
                        label="Department"
                        select
                    >
                        {teams?.map(({ id, name }) => (
                            <MenuItem
                                key={id}
                                value={id}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField<CreateSupportTicketPayload>
                        name="subject"
                        label="Subject"
                        helperText="Short summary of your issue or question"
                        required
                        autoFocus={!!user}
                        className={styles.subject}
                    />

                    <TextField<CreateSupportTicketPayload>
                        name="description"
                        label="Description"
                        multiline
                        required
                        rows={3}
                    />
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                {!user && (
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={styles.captchaTerms}
                    >
                        This site is protected by reCAPTCHA and the Google<br />

                        <Link href="https://policies.google.com/privacy">Privacy Policy</Link> and

                        {' '}

                        <Link href="https://policies.google.com/terms">Terms of Service</Link> apply.
                    </Typography>
                )}

                <Button
                    variant="contained"
                    type="submit"
                >
                    Submit
                </Button>
            </DialogActions>
        </FormDialog>
    );
}

function getDefaultValues(
    user: Pick<User, 'firstName' | 'lastName' | 'encompassUserEmail'> | undefined,
    teams: SupportTeam[] | undefined,
    defaultTeam: string
):
    Partial<CreateSupportTicketPayload> {
    const { id: teamId } = teams?.find((team) => team.name === defaultTeam) || teams?.[0] || {};

    return {
        teamId,
        contact: user && {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.encompassUserEmail
        }
    };
}
