import React from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';

import { emptyProductGroup, FeedFormValues } from '../FeedForm';

import * as FeedTypeHelpText from './FeedTypeHelpText';
import ProductGroupCard from './ProductGroupCard';
import ProductOrGroupsLabel from './ProductOrGroupsLabel';


interface GroupedFeedFieldsProps {
    groupsArray: UseFieldArrayReturn<FeedFormValues, 'productGroups' | `productGroups.${number}.products`, 'id'>;
}

export default function GroupedFeedFields({ groupsArray }: GroupedFeedFieldsProps) {
    const { length } = groupsArray.fields;

    return (
        <>
            <ProductOrGroupsLabel
                title={FeedTypeHelpText.GROUPED_FEED_TYPE}
            >
                Product groups *
            </ProductOrGroupsLabel>

            {groupsArray.fields.map((group, index) => (
                <ProductGroupCard
                    key={group.id}
                    index={index}
                    onUpClick={index === 0
                        ? undefined
                        : () => groupsArray.swap(index, index - 1)}
                    onDownClick={index === length - 1
                        ? undefined
                        : () => groupsArray.swap(index, index + 1)}
                    onDeleteClick={() => {
                        if (length > 1) {
                            groupsArray.remove(index);
                        } else {
                            groupsArray.update(index, emptyProductGroup);
                        }
                    }}
                />
            ))}
        </>
    );
}
