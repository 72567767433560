import { Button, DialogContent } from '@mui/material';
import { getProduct, useEntitySelector } from '@redux/entities';
import {
    DialogActions, RoutedDialog, RoutedDialogImplProps
} from '@tsp-ui/components';
import { useParams } from '@tsp-ui/utils';
import { useState } from 'react';

import ProductConfigForm from './ProductConfigForm';


interface EditProductPageParams {
    productID: string;
}

export default function EditProductConfigDialog(props: RoutedDialogImplProps) {
    const { productID } = useParams<EditProductPageParams>();
    const isCreate = !productID;

    const product = useEntitySelector({
        entityType: 'products',
        selector: (state) => (productID ? getProduct(state, productID) : undefined),
        disable: isCreate
    });

    const [ loading, setLoading ] = useState(false);

    return (
        <RoutedDialog
            {...props}
            maxWidth="xs"
            title="Edit product configuration"
        >
            {(isCreate || !!product) && (
                <DialogContent>
                    <ProductConfigForm
                        key={product?.id}
                        product={product}
                        setLoading={setLoading}
                    />
                </DialogContent>
            )}

            <DialogActions loading={loading}>
                <Button
                    form={ProductConfigForm.formID}
                    type="submit"
                    variant="contained"
                    disabled={loading}
                >
                    Save configuration
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
