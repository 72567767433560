import api from '@api';
import { createAsyncThunk } from '@reduxjs/toolkit';


/**
 * Thunk action to fetch all feeds
 */
export const fetchFeeds = createAsyncThunk(
    'entities/fetchFeeds',
    api.ppm.feed.getFeeds
);

/**
 * Thunk action to delete a feed
 */
export const deleteFeed = createAsyncThunk(
    'entities/deleteFeed',
    api.ppm.feed.deleteFeed
);

/**
 * Thunk action to fetch all scenarios
 */
export const fetchScenarios = createAsyncThunk(
    'entities/fetchScenarios',
    api.ppm.scenario.getScenarios
);

/**
 * Thunk action to delete a scenario
 */
export const deleteScenario = createAsyncThunk(
    'entities/deleteScenario',
    api.ppm.scenario.deleteScenario
);

/**
 * Thunk action to fetch all products
 */
export const fetchProducts = createAsyncThunk(
    'entities/fetchProducts',
    api.ppm.product.getProducts
);

/**
 * Thunk action to delete a product config
 */
export const deleteProductConfig = createAsyncThunk(
    'entities/deleteProductConfig',
    api.ppm.product.deleteProductConfig
);
