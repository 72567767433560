import { Category, Edit, List } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, TypographyVariant 
} from '@mui/material';
import { IconTypography } from '@tsp-ui/components';
import { ReactNode } from 'react';

import { FeedFormValues } from '../FeedForm';

import styles from './FeedDetailsDisplayCard.module.scss';


interface FeedDetailsDisplayCardProps {
    children?: ReactNode;
    feedName: string | undefined;
    feedType: FeedFormValues['feedType'];
    onEditClick: () => void;
    headerVariant?: TypographyVariant;
}

export default function FeedDetailsDisplayCard({
    children, feedName, feedType, onEditClick, headerVariant
}: FeedDetailsDisplayCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <div className={styles.header}>
                <IconTypography
                    iconPosition="after"
                    variant={headerVariant}
                    icon={feedType === 'grouped' ? (
                        <Tooltip title="Grouped product list">
                            <Category color="primary" />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Flat product list">
                            <List color="primary" />
                        </Tooltip>
                    )}
                >
                    {feedName}
                </IconTypography>

                <IconButton onClick={onEditClick}>
                    <Edit color="secondary" />
                </IconButton>
            </div>

            {children}
        </Paper>
    );
}
