import {
    ApiUtilsConfig,
    createApiUtils,
    EXPIRATION_DATE_OFFSET,
    FetchOptions,
    getCognitoToken, useAsyncEffect
} from '@tsp-ui/utils';
import { isAfter } from 'date-fns';
import decodeJWT from 'jwt-decode';
import { useCallback, useState } from 'react';


let frameworkToken: string;
let frameworkTokenPromise: Promise<AuthResponse> | null = null;

const AUTH_URL = '/auth/gettoken';

export const apiUtils = {
    ...createApiUtils<ApiUtilsConfig, IceFetchOptions>({
        getAuthToken: getFrameworkAuthToken,
        getRequestUrl: (url, options, config) => (
            config?.apiUrl + url
        )
    }),

    async getFrameworkAccessToken() {
        return (await getFrameworkAuthToken()).replace('Bearer ', '');
    },

    clearFrameworkAccessToken() {
        frameworkTokenPromise = null;
        frameworkToken = '';
    },

    async decodeFrameworkToken() {
        return decodeFrameworkToken(await getFrameworkAuthToken());
    }
};

async function getFrameworkAuthToken(url?: string, options?: IceFetchOptions | undefined): Promise<string> {
    if (options?.public) {
        return '';
    }

    if (url === AUTH_URL) {
        return await getCognitoToken() || '';
    }

    if (frameworkToken) {
        const { exp } = decodeFrameworkToken(frameworkToken);
        const expiration = new Date(exp * 1000);

        return isAfter(new Date(Date.now() + EXPIRATION_DATE_OFFSET), expiration)
            ? await refreshFrameworkToken()
            : frameworkToken;
    }

    return await refreshFrameworkToken();
}

async function refreshFrameworkToken() {
    if (!frameworkTokenPromise) {
        console.log('Refreshing framework auth token');
        frameworkTokenPromise = getToken();
    }

    try {
        const { tokenType, accessToken } = await frameworkTokenPromise;
        frameworkToken = `${tokenType} ${accessToken}`;
    } finally {
        frameworkTokenPromise = null;
    }

    return frameworkToken;
}

function getToken(): Promise<AuthResponse> {
    return apiUtils.post('/auth/gettoken', null);
}

function decodeFrameworkToken(token: string): FrameworkTokenPayload {
    const payload = decodeJWT(token.split(' ')[1]) as FrameworkTokenPayload;

    return {
        ...payload,
        roles: payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
    };
}

export function useTokenPayload() {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ tokenPayload, setTokenPayload ] = useState<FrameworkTokenPayload>();

    useAsyncEffect(useCallback(async () => {
        setTokenPayload(await apiUtils.decodeFrameworkToken());

        setIsLoading(false);
    }, [ ]));

    return {
        isLoading,
        tokenPayload
    };
}

export function useHasProduct() {
    const { isLoading, tokenPayload } = useTokenPayload();

    return {
        isLoading,
        hasProduct: (productName: FrameworkProduct) => (
            tokenPayload?.product?.includes(productName)
        )
    };
}

interface IceFetchOptions extends FetchOptions {
    public?: boolean;
}

export interface AuthResponse {
    accessToken: string;
    tokenType: string;
}

export interface FrameworkTokenPayload {
    aud: string;
    exp: number;
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string[];
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': string;
    roles: string[];
    instanceId: string;
    iss: string;
    nbf: number;
    product?: FrameworkProduct[];
    firstName: string;
    lastName: string;
}

export enum FrameworkProduct {
    BULK_UPDATER = 'BulkUpdater',
    CLOSING_CALENDAR = 'ClosingCalendar',
    DATA_BRIDGE = 'DataBridge',
    PPM = 'PPM',
    PRODUCT_A = 'ProductA',
    PRODUCT_B = 'ProductB'
}

export const frameworkProductDisplay = {
    [FrameworkProduct.BULK_UPDATER]: 'Bulk Updater',
    [FrameworkProduct.CLOSING_CALENDAR]: 'Closing Calendar',
    [FrameworkProduct.DATA_BRIDGE]: 'Loan Audit',
    [FrameworkProduct.PPM]: 'Product & Pricing Manager',
    [FrameworkProduct.PRODUCT_A]: 'Product A',
    [FrameworkProduct.PRODUCT_B]: 'Product B'
};

export const productRoutesMap = {
    [FrameworkProduct.BULK_UPDATER]: 'bulk-updater',
    [FrameworkProduct.CLOSING_CALENDAR]: 'closing-calendar',
    [FrameworkProduct.DATA_BRIDGE]: 'loan-auditor',
    [FrameworkProduct.PPM]: 'ppm',
    [FrameworkProduct.PRODUCT_A]: 'product-a',
    [FrameworkProduct.PRODUCT_B]: 'product-b'
};
