import api, { FeedToken } from '@api';
import {
    AddCircleOutline,
    ContentCopy,
    Edit as EditIcon,
    RemoveCircleOutline as RemoveCircleOutlineIcon
} from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { CopyButton, FilledSection, useConfirm } from '@tsp-ui/components';
import { useState } from 'react';

import styles from './FeedAccessTokensSection.module.scss';
import FeedTokenDialog from './FeedTokenDialog';


interface AccessTokensSectionProps {
    className?: string;
    feedID: string;
    tokens?: FeedToken[] | undefined;
    fetchTokens: () => Promise<void>;
}

export default function FeedAccessTokensSection({
    className, feedID, tokens, fetchTokens
}: AccessTokensSectionProps) {
    const confirm = useConfirm();

    const [ editFeedToken, setEditFeedToken ] = useState<FeedToken>();
    const [ showTokenCreate, setShowTokenCreate ] = useState(false);

    return (
        <FilledSection
            header={(
                <>
                    Access tokens

                    <Tooltip title="Create token">
                        <IconButton onClick={() => setShowTokenCreate(true)}>
                            <AddCircleOutline color="secondary" />
                        </IconButton>
                    </Tooltip>
                </>
            )}
            className={className}
            scrollable
        >
            <div className={styles.cardContainer}>
                {tokens?.map((token) => (
                    <Paper
                        key={token.token}
                        className={styles.card}
                        elevation={0}
                    >
                        <div>
                            <Typography variant="subtitle1">
                                {token.name}
                            </Typography>

                            <Typography
                                variant="body2"
                                color="textSecondary"
                            >
                                {token.description}
                            </Typography>
                        </div>

                        <div className={styles.buttons}>
                            <CopyButton
                                textToCopy={token.token}
                                successMessage="Access token copied"
                                children={({ open, onClick }) => (
                                    <Tooltip title={open ? '' : 'Copy token'}>
                                        <IconButton onClick={onClick}>
                                            <ContentCopy color="action" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            />

                            <Tooltip title="Edit token">
                                <IconButton onClick={() => setEditFeedToken(token)}>
                                    <EditIcon color="secondary" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Revoke token">
                                <IconButton
                                    edge="end"
                                    onClick={async () => {
                                        if (await confirm(revokeConfirmMessage)) {
                                            await api.ppm.feed.deleteFeedToken(feedID, token.id);

                                            await fetchTokens();
                                        }
                                    }}
                                >
                                    <RemoveCircleOutlineIcon color="error" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Paper>
                ))}
            </div>

            <FeedTokenDialog
                token={editFeedToken}
                feedID={feedID}
                showCreate={showTokenCreate}
                onClose={async (refreshResults) => {
                    if (refreshResults) {
                        await fetchTokens();
                    }

                    setEditFeedToken(undefined);
                    setShowTokenCreate(false);
                }}
            />
        </FilledSection>
    );
}

const revokeConfirmMessage = 'Revoking this token will cause any embedded widgets that use it to stop working.'
    + ' Are you sure you want to revoke this token?';
