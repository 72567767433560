import * as frameworkApi from './framework-api';
import * as supportApi from './support-api';


const api = {
    ...frameworkApi,
    ...supportApi
};

export default api;

export * from './framework-api';
export * from './support-api';
