import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { useDispatch as baseUseDispatch, useSelector as baseUseSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import config from './config';
import entities from './entities';
import paymentInfo from './payment-info';
import support from './support';


const sentryEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
    reducer: {
        config,
        entities,
        paymentInfo,
        support
    },
    devTools: process.env.NODE_ENV !== 'production' && {
        name: 'CapraSuite'
    },
    enhancers: [ sentryEnhancer ]
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<AppState> = baseUseSelector;
export const useDispatch: () => AppDispatch = baseUseDispatch;
