import { Lookups, documentationLevels } from '@api';
import { Skeleton } from '@mui/material';
import { useEPPSLookups } from '@views/common/hooks/useEPPSLookups';


export interface LookupDisplayProps {
    value: number | undefined;
    lookupKey: keyof Lookups;
}

export function LookupDisplay({ value, lookupKey }: LookupDisplayProps) {
    const { lookups, isLoading } = useEPPSLookups();
    const specificLookup = lookups?.[lookupKey];

    if (isLoading) {
        return (<Skeleton />);
    }

    if (!lookups || !specificLookup || value === undefined) {
        return null;
    }

    const lookupMap = lookupKey === 'documentationTypes' ? new Map(lookups[lookupKey].map(({ documentationId }) => (
        [ documentationId, documentationLevels[documentationId as keyof typeof documentationLevels] ]
    ))) : lookupKey === 'states' ? new Map(lookups[lookupKey].map(({ abbreviation, stateId }) => (
        [ stateId, abbreviation ]
    ))) : lookupKey === 'lockDays' ? new Map(lookups[lookupKey].map(({ lockDays }) => (
        [ lockDays, `${lockDays} days` ]
    ))) : new Map(lookups[lookupKey].map(({ value, key }) => (
        [ value, key ]
    )));

    return !lookupMap.get(value) ? null : (
        <>
            {lookupMap.get(value)}
        </>
    );
}
