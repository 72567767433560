import { apiUtils } from '@api';


export function fetchWidgetTheme(): Promise<WidgetTheme> {
    return apiUtils.get('/products/ppm/widget/theme');
}

export function updateWidgetTheme(theme: WidgetTheme): Promise<WidgetTheme> {
    return apiUtils.put('/products/ppm/widget/theme', theme);
}

export interface WidgetTheme {
    primaryColor: string;
    secondaryColor: string;
    calculatorBackgroundColor: string;
    rateWidgetBackgroundColor: string;
}
