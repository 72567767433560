import { getProducts, useEntitySelector } from '@redux/entities';
import { Autocomplete } from '@tsp-ui/components';
import { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { FeedFormValues } from '../FeedForm';

import { formatProductName } from './FeedProductCard';
import styles from './FeedProductCard.module.scss';


interface FeedProductSelectProps {
    nameBase: `productGroups.${number}.products.${number}`;
}

export default function FeedProductSelect({ nameBase }: FeedProductSelectProps) {
    const name: `${typeof nameBase}.productId` = `${nameBase}.productId`;

    const productID = useWatch<FeedFormValues, `${typeof name}`>({ name });
    const productGroups = useWatch<FeedFormValues, 'productGroups'>({ name: 'productGroups' });

    const products = useEntitySelector({
        entityType: 'products',
        selector: getProducts
    });

    const options = useMemo(() => {
        const selectedProductIds = productGroups
            .flatMap(({ products }) => products)
            .map(({ productId }) => productId);

        return (
            products && Object.values(products)
                .filter(({ availableScenarioIds, id }) => (
                    Boolean(availableScenarioIds) && (id === productID || !selectedProductIds.includes(id))
                ))
                .map((product) => (product.id))
        );
    }, [
        products, productGroups, productID
    ]);

    const [ open, setOpen ] = useState(!productID);

    return (
        <Autocomplete<FeedFormValues>
            name={name}
            label="Product"
            options={options || []}
            getOptionLabel={(productId) => (products?.[productId] ? formatProductName(products[productId]) : '')}
            className={styles.selectField}
            size="small"
            required
            autoFocus
            disableClearable
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            fullWidth
        />
    );
}
