import { Scenario } from '@api';
import {
    Edit as EditIcon, RemoveCircleOutline
} from '@mui/icons-material';
import {
    Button, Divider,
    IconButton,
    Paper,
    Tooltip,
    Typography
} from '@mui/material';
import { deleteScenario, fetchProducts } from '@redux/entities';
import { useDispatch } from '@redux/store';
import {
    HorizontalLabeledValue, HorizontalLabelGroup, LabeledValue, useConfirm, usePageMessage
} from '@tsp-ui/components';
import { formatCurrency } from '@tsp-ui/utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { LookupDisplay, LookupDisplayProps } from '../../components/LookupDisplay';

import styles from './ScenarioCard.module.scss';


interface ScenarioCardProps {
    scenario: Scenario;
}

export default function ScenarioCard({ scenario }: ScenarioCardProps) {
    const dispatch = useDispatch();
    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    const [ loading, setLoading ] = useState(false);

    async function handleDeleteScenario() {
        if (await confirm('Are you sure you want to delete this scenario?')) {
            setLoading(true);
            const result = await dispatch(deleteScenario(scenario.id));

            if (deleteScenario.rejected.match(result)) {
                pageMessage.error(result.error.message || 'An error occurred');
                setLoading(false); // Only set loading on failure, the card unmounts on success.
            } else {
                dispatch(fetchProducts());
            }
        }
    }

    const {
        loanInformation,
        productType,
        property,
        standardProducts
    } = scenario.request;

    const {
        loanPurpose,
        firstMortgageAmount
    } = loanInformation;

    const { borrowerFinanancial } = scenario?.request.borrowers?.[0] || {};

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            <Typography
                variant="h6"
                className={styles.name}
            >
                {scenario.name}
            </Typography>

            <div className={styles.iconButtons}>
                <Tooltip title="Edit scenario">
                    <IconButton
                        component={Link}
                        to={`/apps/ppm/scenarios/${scenario.id}/edit`}
                        disabled={loading}
                    >
                        <EditIcon color={loading ? 'disabled' : 'secondary'} />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Delete scenario">
                    <IconButton
                        edge="end"
                        onClick={handleDeleteScenario}
                        disabled={loading}
                    >
                        <RemoveCircleOutline color={loading ? 'disabled' : 'error'} />
                    </IconButton>
                </Tooltip>
            </div>

            <Divider className={styles.divider} />

            <div className={styles.summary}>
                <div>
                    <Tooltip
                        title="Loan purpose"
                        enterDelay={0}
                    >
                        <Typography fontWeight={500}>
                            <LookupDisplay
                                value={loanPurpose}
                                lookupKey="loanUsages"
                            />
                        </Typography>
                    </Tooltip>

                    <Tooltip
                        title="Loan amount"
                        enterDelay={0}
                    >
                        <Typography>
                            {formatCurrency(firstMortgageAmount)}
                        </Typography>
                    </Tooltip>
                </div>

                <LabeledValue
                    variant="vertical"
                    variants={{ label: 'body2' }}
                    label="Standard products"
                    value={(
                        <LookupDisplayList
                            value={standardProducts}
                            lookupKey="standardProducts"
                        />
                    )}
                />

                <LabeledValue
                    variant="vertical"
                    variants={{ label: 'body2' }}
                    label="Loan terms"
                    value={(
                        <LookupDisplayList
                            value={productType}
                            lookupKey="loanTerms"
                        />
                    )}
                />

                <div>
                    <Tooltip
                        title="Property use"
                        enterDelay={0}
                    >
                        <Typography fontWeight={500}>
                            <LookupDisplay
                                value={property.use}
                                lookupKey="propertyUses"
                            />
                        </Typography>
                    </Tooltip>

                    <Tooltip
                        title="Property value"
                        enterDelay={0}
                    >
                        <Typography>
                            {formatCurrency(property?.value)}
                        </Typography>
                    </Tooltip>
                </div>

                <LabeledValue
                    variant="vertical"
                    variants={{ label: 'body2' }}
                    label="Property type"
                    value={(
                        <LookupDisplay
                            value={property.type}
                            lookupKey="propertyTypes"
                        />
                    )}
                />

                <LabeledValue
                    variant="vertical"
                    variants={{ label: 'body2' }}
                    label="Property state"
                    value={property?.state}
                />
            </div>

            <Button
                component={Link}
                to={`/apps/ppm/scenarios/${scenario.id}/edit?showProducts=true`}
                className={styles.button}
            >
                View products
            </Button>

            <HorizontalLabelGroup className={styles.fico}>
                <HorizontalLabeledValue
                    label="Borrower FICO:"
                    value={borrowerFinanancial.creditScore}
                    variant="body1"
                    labelVariant="body2"
                />
            </HorizontalLabelGroup>
        </Paper>
    );
}

interface LookupDisplayListProps extends Pick<LookupDisplayProps, 'lookupKey'> {
    value: number[] | undefined;
}

function LookupDisplayList({ value, lookupKey }: LookupDisplayListProps) {
    return ( // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {value?.map((val) => (
                <div key={val}>
                    <LookupDisplay
                        value={val}
                        lookupKey={lookupKey}
                    />
                </div>
            ))}
        </>
    );
}
