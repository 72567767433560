import api, { GetUsersQueryParams } from '@api';
import useSwr, { mutate } from 'swr';

import { laggyMiddleware, WithLaggy } from './laggyMiddleware';


const usersKey = '/user';

export function useUsers(queryParams: GetUsersQueryParams) {
    const swrResult = useSwr({
        key: usersKey,
        ...queryParams
    }, () => api.ppm.user.getUsers(queryParams), {
        use: [ laggyMiddleware ]
    });

    return {
        ...(swrResult as WithLaggy<typeof swrResult>),
        usersResponse: swrResult.data
    };
}

export function mutateUsers() {
    return mutate((key) => (
        typeof key === 'object' && (key as { key: string; }).key === usersKey
    ), undefined, {
        throwOnError: false
    });
}
