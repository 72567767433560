import { delay } from '@tsp-ui/utils';

import { apiUtils } from '../api-utils';
import { getCurrentLicense, License } from '../framework/framework-api';


import { LicenseStatus } from './tenant-api';


// TODO move this out of ppm

export async function addPaymentMethod(payload: PaymentMethodPayload) {
    await apiUtils.post('/stripe/paymentmethod', payload, { emptyResponse: true });
}

export async function updatePaymentMethod(payload: PaymentMethodPayload) {
    await apiUtils.put('/stripe/paymentmethod', payload, { emptyResponse: true });
}

export async function verifyACH(payload: ACHVerification) {
    await apiUtils.post('/stripe/verifybankaccount', payload, { emptyResponse: true });
}

export function getCurrentPaymentInfo(): Promise<CurrentPaymentInfo> {
    return apiUtils.get('/stripe/currentpaymentdetails');
}

export async function createSubscription(): Promise<void> {
    await apiUtils.post('/stripe/subscription', null);
}

export function getPrices(): Promise<Price[]> {
    return apiUtils.get('/stripe/prices');
}

export function getCurrentPrice(): Promise<Price> {
    return apiUtils.get('/stripe/price');
}

export function getInvoiceHistory(): Promise<Invoice[]> {
    return apiUtils.get('/invoice');
}

export function pollForUpdatedLicense(): Promise<License> {
    return new Promise(async (resolve, reject) => {
        for (let i = 0; i < 5; i++) {
            try {
                const license = await getCurrentLicense();

                if (license.status !== LicenseStatus.Initial) {
                    resolve(license);
                    return;
                }
            } catch (e) {
                console.debug(`Error fetching current license, retrying ${5 - 1 - i} more times`);
                console.error(e);
            } finally {
                await delay(1000);
            }
        }

        reject({
            message: 'We ran into a problem processing your payment. Please contact support.'
        });
    });
}

export enum PaymentType {
    Check = 1,
    CreditCard = 2,
    BankTransfer = 3
}

interface PaymentMethodPayload {
    paymentToken: string;
    paymentType: PaymentType;
}

export interface CurrentPaymentInfo {
    paymentType: PaymentType;
    last4?: string;
    amountOnNextBill: number;
    nextPaymentDate: string;
    isACHVerified: boolean;
}

/* eslint-disable camelcase */
export interface Recurring {
    interval: string;
    interval_count: number;
    usage_type: string;
}

export interface Price {
    id: string;
    active: boolean;
    nickname?: any;
    recurring: Recurring;
    unit_amount: number;
    unit_amount_decimal: string;
}
/* eslint-enable camelcase */

export interface Invoice {
    invoiceId: string;
    createdDate: string;
    dueDate: string;
    paidDate?: string;
    amountRemaining: number;
    amountPaid: number;
    isPaid: boolean;
}

export interface ACHVerification {
    amountOne: string;
    amountTwo: string;
}
