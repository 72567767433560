import {
    Code, ContentCopy, List, Refresh
} from '@mui/icons-material';
import {
    Button, CircularProgress, IconButton, Tooltip
} from '@mui/material';
import { CopyButton } from '@tsp-ui/components';
import { Dispatch, SetStateAction } from 'react';

import styles from '../ScenarioPreviewSection.module.scss';


interface ScenarioPreviewHeaderProps {
    loading: boolean;
    fetchResults: () => void;
    showJSON: boolean;
    setShowJSON: Dispatch<SetStateAction<boolean>>;
    resultsJSON: string | undefined;
}

export default function ScenarioPreviewHeader({
    loading, fetchResults, showJSON, setShowJSON, resultsJSON
}: ScenarioPreviewHeaderProps) {
    return (
        <>
            Scenario preview

            {!resultsJSON ? (
                !loading && (
                    <Button
                        className={styles.previewButton}
                        onClick={fetchResults}
                    >
                        Show preview
                    </Button>
                )
            ) : (
                <div className={styles.buttonContainer}>
                    {!showJSON ? (
                        loading ? (
                            <Tooltip
                                key="loader"
                                title="Refreshing preview"
                                open
                            >
                                <CircularProgress
                                    size={24}
                                    className={styles.refreshLoader}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip
                                key="preview"
                                title="Refresh preview"
                            >
                                <IconButton onClick={fetchResults}>
                                    <Refresh color="action" />
                                </IconButton>
                            </Tooltip>
                        )
                    ) : (
                        <CopyButton
                            textToCopy={resultsJSON}
                            successMessage="Results copied"
                            children={({ onClick }) => (
                                <Tooltip title="Copy JSON">
                                    <IconButton onClick={onClick}>
                                        <ContentCopy color="action" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        />
                    )}

                    <Tooltip
                        key={showJSON ? 'json' : 'results'} // Prevents the tooltip from staying open
                        title={showJSON ? 'Show result list' : 'Show JSON output'}
                    >
                        <IconButton onClick={() => setShowJSON(!showJSON)}>
                            {showJSON ? (
                                <List color="secondary" />
                            ) : (
                                <Code color="secondary" />
                            )}
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </>
    );
}
