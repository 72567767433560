import api, { PaymentType } from '@api';
import { FormLabel } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CreateTokenCardData } from '@stripe/stripe-js';
import { AddressFieldset, TextField, usePageMessage } from '@tsp-ui/components';
import { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';


import styles from './CreditCardForm.module.scss';
import StripeCardInput from './StripeCardInput';


export const creditCardFormID = 'credit-card-form';

interface CreditCardFormProps {
    update?: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    onSubmit: () => Promise<void>;
}

export default function CreditCardForm({ update, setLoading, onSubmit }: CreditCardFormProps) {
    const pageMessage = usePageMessage(250);
    const formMethods = useForm<CreateTokenCardData>();

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        formValues.currency = 'usd';
        formValues.address_country = 'US'; // eslint-disable-line camelcase

        const cardElement = elements?.getElement(CardElement);

        if (stripe && cardElement) {
            setLoading(true);
            const { error, token } = await stripe.createToken(cardElement, formValues);

            if (error) {
                pageMessage.error(error.message || 'An error occurred');
            } else if (token) {
                try {
                    const apiMethod = update
                        ? api.ppm.payment.updatePaymentMethod
                        : api.ppm.payment.addPaymentMethod;

                    await apiMethod({
                        paymentToken: token.id,
                        paymentType: PaymentType.CreditCard
                    });

                    await api.ppm.payment.createSubscription();

                    await onSubmit();
                    return; // Return early so setLoading doesn't fire, the modal will be unmounted at this point
                } catch (error) {
                    pageMessage.handleApiError('An error occurred while submitting credit card information', error);
                }
            }

            setLoading(false);
        }
    });

    return (
        <form
            noValidate
            onSubmit={handleSubmit}
            id={creditCardFormID}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <TextField<CreateTokenCardData>
                    name="name"
                    label="Cardholder name"
                    required
                />

                <AddressFieldset<CreateTokenCardData>
                    required
                    fieldNames={{
                        street: 'address_line1',
                        city: 'address_city',
                        state: 'address_state',
                        zip: 'address_zip'
                    }}
                />

                <div>
                    <FormLabel required>
                        Card info
                    </FormLabel>

                    <StripeCardInput />
                </div>
            </FormProvider>
        </form>
    );
}
