import { LoanProgram } from '@api';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    FormControlLabel, IconButton, Paper, Switch, Tooltip, Typography
} from '@mui/material';
import { HorizontalLabeledValue, HorizontalLabelGroup, CardTable } from '@tsp-ui/components';
import { truncateNumber } from '@tsp-ui/utils';
import clsx from 'clsx';
import { useState } from 'react';

import styles from '../ScenarioPreviewSection.module.scss';


interface ProductResultCardProps {
    loanProgram: LoanProgram;
}

export default function ProductResultCard({
    loanProgram: {
        program, programID, term, lockDays, rateDetails, filteredRates, notes
    }
}: ProductResultCardProps) {
    const [ expanded, setExpanded ] = useState(false);
    const [ showFilteredRates, setShowFilteredRates ] = useState(!rateDetails && !!filteredRates);

    const rates = !showFilteredRates
        ? rateDetails
        : (filteredRates || []).concat(rateDetails || []).sort((a, b) => (
            a.rate - b.rate
        ));

    const notesLines = notes.split('|');

    const ratesTable = rates && (
        <CardTable
            headers={[
                'Rate', {
                    value: 'Price',
                    align: 'right'
                }
            ]}
            className={styles.rateTable}
            slim
        >
            {rates?.map(({ rate, price }) => (
                <tr
                    key={rate}
                    className={clsx({
                        [styles.filteredRate]: filteredRates?.find(filteredRate => filteredRate.rate === rate)
                    })}
                >
                    <Typography
                        component="td"
                        variant="body2"
                    >
                        {rate.toFixed(3)}
                    </Typography>

                    <Typography
                        component="td"
                        variant="body2"
                        align="right"
                    >
                        {truncateNumber(price, 3)}
                    </Typography>
                </tr>
            ))}
        </CardTable>
    );

    return (
        <Paper
            variant="outlined"
            className={styles.result}
        >
            <div className={styles.mainRow}>
                <Typography>
                    {program}
                </Typography>

                <HorizontalLabelGroup className={styles.programID}>
                    <HorizontalLabeledValue
                        label="Program ID:"
                        value={programID}
                    />
                </HorizontalLabelGroup>

                <Tooltip title={expanded ? 'Hide rate details' : 'Show rate details'}>
                    <IconButton onClick={() => setExpanded(!expanded)}>
                        {expanded ? (
                            <ExpandLess color="secondary" />
                        ) : (
                            <ExpandMore color="secondary" />
                        )}
                    </IconButton>
                </Tooltip>
            </div>

            {expanded && (
                <div className={styles.expandedRow}>
                    <div className={styles.programDetails}>
                        <Typography
                            fontWeight={500}
                            className={styles.header}
                        >
                            Program details
                        </Typography>

                        <HorizontalLabelGroup>
                            <HorizontalLabeledValue
                                label="Term:"
                                value={`${term * 12} months`}
                            />

                            <HorizontalLabeledValue
                                label="Lock:"
                                value={`${lockDays} days`}
                            />
                        </HorizontalLabelGroup>
                    </div>

                    <div>
                        <Typography
                            fontWeight={500}
                            className={styles.header}
                        >
                            Rate details

                            <Tooltip title={filteredRates ? '' : 'No rates have been filtered for this product'}>
                                <FormControlLabel
                                    label="Show filtered rates"
                                    disabled={!filteredRates}
                                    className={styles.filteredRatesSwitch}
                                    componentsProps={{
                                        typography: {
                                            variant: 'body2'
                                        }
                                    }}
                                    control={(
                                        <Switch
                                            size="small"
                                            disabled={!filteredRates}
                                            checked={showFilteredRates}
                                            onChange={(event) => setShowFilteredRates(event.target.checked)}
                                        />
                                    )}
                                />
                            </Tooltip>
                        </Typography>

                        {rateDetails ? ratesTable : (
                            !filteredRates ? (
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    No rates available because this product is ineligible
                                </Typography>
                            ) : (
                                <div className={styles.allRatesFilteredContainer}>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        No rates are within the specified par rate range
                                    </Typography>

                                    {ratesTable}
                                </div>
                            )
                        )}
                    </div>

                    <div className={styles.notes}>
                        <Typography
                            fontWeight={500}
                            className={styles.header}
                        >
                            Notes
                        </Typography>

                        <ul className={styles.noteList}>
                            {notesLines.map((note) => (
                                <Note key={note}>
                                    {note}
                                </Note>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </Paper>
    );
}

interface NoteProps {
    children: string;
}

function Note({ children }: NoteProps) {
    // TODO parse children for {b} and {i} formatting tags
    return (
        <Typography
            component="li"
            variant="body2"
        >
            {children}
        </Typography>
    );
}
