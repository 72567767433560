import { Paper, PaperProps, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './HeadedBorderCard.module.scss';


interface HeadedBorderCardProps extends PaperProps {
    actions?: ReactNode;
    children: ReactNode;
    header: ReactNode;
    headerVariant?: 'body1' | 'body2';
    classes?: {
        actions?: string;
    };
}

export default function HeadedBorderCard({
    actions, children, className, header, headerVariant = 'body1', classes, ...props
}: HeadedBorderCardProps) {
    return (
        <Paper
            variant="outlined"
            className={clsx(styles.root, className)}
            {...props}
        >
            <Typography
                fontWeight={500}
                variant={headerVariant}
                className={styles.header}
                component="div"
            >
                <span className={styles.headerLabel}>
                    {header}
                </span>

                {actions && (
                    <div className={classes?.actions}>
                        {actions}
                    </div>
                )}
            </Typography>

            {children}
        </Paper>
    );
}
