import { ActivityLogType, Lookups, Scenario } from '@api';
import { getScenario, useEntitySelector } from '@redux/entities';
import { DiffContainer, DiffHeader, DiffLabeledValue } from '@tsp-ui/components';
import { formatCurrency } from '@tsp-ui/utils';
import { LookupDisplay } from '@views/ppm/components/LookupDisplay';
import { useState } from 'react';


import { extractActivityLogEntities } from '../utils/extractActivityLogEntities';

import { ActivityLogCard, ActivityLogCardImplProps } from './ActivityLogCard';
import { EntityNameDisplay } from './EntityNameDisplay';


const { SCENARIO_CREATED, SCENARIO_UPDATED } = ActivityLogType;

export function ScenarioActivityLogCard({ activityLog, usersMap }: ActivityLogCardImplProps<Scenario>) {
    const [ scenario, updatedScenario ] = extractActivityLogEntities(activityLog, SCENARIO_CREATED);
    const { name, id } = scenario || updatedScenario!;

    const scenarioExists = useEntitySelector({
        entityType: 'scenarios',
        selector: (state) => getScenario(state, id)
    });

    const allowHideUnchanged = activityLog.type === SCENARIO_UPDATED;
    const [ hideUnchanged, setHideUnchanged ] = useState(allowHideUnchanged);

    const commonProps = {
        original: scenario,
        updated: updatedScenario,
        hideIfUnchanged: hideUnchanged
    };

    return (
        <ActivityLogCard
            showExpand
            activityLog={activityLog}
            usersMap={usersMap}
            entityNameDisplay={(
                <EntityNameDisplay
                    entityType="scenario"
                    label={name}
                    to={scenarioExists && `/apps/ppm/scenarios/${id}/edit`}
                />
            )}
        >
            <DiffContainer
                hideUnchanged={hideUnchanged}
                setHideUnchanged={setHideUnchanged}
                allowHideUnchanged={allowHideUnchanged}
            >
                <DiffLabeledValue
                    {...commonProps}
                    label="Scenario name"
                    property="name"
                />

                <DiffHeader
                    header="Product selection"
                    hideUnchanged={hideUnchanged}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Loan terms"
                    property="request.productType"
                    renderValue={(value) => renderLookupDisplay(value, 'loanTerms')}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Standard products"
                    property="request.standardProducts"
                    renderValue={(value) => renderLookupDisplay(value, 'standardProducts')}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Product options"
                    property="request.productOptions"
                    renderValue={(value) => renderLookupDisplay(value, 'productOptions')}
                />

                <DiffHeader
                    header="Target price"
                    hideUnchanged={hideUnchanged}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Min par rate"
                    property="targetPrice.minParRate"
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Max par rate"
                    property="targetPrice.maxParRate"
                />

                <DiffHeader
                    header="Loan information"
                    hideUnchanged={hideUnchanged}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Delivery type"
                    property="request.deliveryType"
                    renderValue={(value) => (!value ? 'None' : renderLookupDisplay(value, 'deliveryTypes'))}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Lock days"
                    property="request.lockDays"
                    renderValue={(value) => renderLookupDisplay(value, 'lockDays')}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Lien position"
                    property="request.loanInformation.lienPosition"
                    renderValue={(value) => renderLookupDisplay(value, 'lienPositions')}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Loan purpose"
                    property="request.loanInformation.loanPurpose"
                    renderValue={(value) => renderLookupDisplay(value, 'loanUsages')}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Loan amount"
                    property="request.loanInformation.firstMortgageAmount"
                    renderValue={formatCurrency}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Total loan amount"
                    property="request.loanInformation.totalMortgageAmount"
                    renderValue={formatCurrency}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Compensation"
                    property="request.compensation.model"
                    renderValue={(value) => (value === 1 ? 'Borrower' : 'Creditor')}
                />

                <DiffHeader
                    header="Borrower finances"
                    hideUnchanged={hideUnchanged}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Credit score"
                    property="request.borrowers.0.borrowerFinanancial.creditScore"
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Documentation level"
                    property="request.documentationLevel"
                    renderValue={(value) => renderLookupDisplay(value, 'documentationTypes')}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Liquid assets"
                    property="request.borrowers.0.borrowerFinanancial.liquidAsset"
                    renderValue={formatCurrency}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Annual income"
                    property="request.borrowers.0.borrowerFinanancial.income"
                    renderValue={formatCurrency}
                />

                <DiffHeader
                    header="Property"
                    hideUnchanged={hideUnchanged}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Property value"
                    property="request.property.value"
                    renderValue={formatCurrency}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="State"
                    property="request.property.state"
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Zip"
                    property="request.property.zip"
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="County"
                    property="request.property.county"
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Property type"
                    property="request.property.type"
                    renderValue={(value) => renderLookupDisplay(value, 'propertyTypes')}
                />

                <DiffLabeledValue
                    {...commonProps}
                    label="Property use"
                    property="request.property.use"
                    renderValue={(value) => renderLookupDisplay(value, 'propertyUses')}
                />
            </DiffContainer>
        </ActivityLogCard>
    );
}

export const renderLookupDisplay = (value: number, lookupKey: keyof Lookups) => (
    <LookupDisplay
        value={value}
        lookupKey={lookupKey}
    />
);
