import { FrameworkProduct, useTokenPayload } from '@api';
import { useContext } from 'react';

import { AuthenticationContext } from '../../../AuthenticationContext';


export function useHasPermission() {
    const { user } = useContext(AuthenticationContext);

    return {
        isFrameworkAdmin: user?.frameworkRole === 'admin'
    };
}

export function useHasAdminPermission(...products: FrameworkProduct[]) {
    const { tokenPayload } = useTokenPayload();
    const { isFrameworkAdmin } = useHasPermission();

    return products.map((product) => (
        isFrameworkAdmin || tokenPayload?.roles.includes(`${product}:admin`)
    ));
}
