import { Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthenticationContext } from '../../../AuthenticationContext';

import styles from './GlobalErrorMessage.module.scss';


interface GlobalErrorMessageProps {
    onLogout?: () => void;
    hideLogout?: boolean;
}

export default function GlobalErrorMessage({ onLogout, hideLogout }: GlobalErrorMessageProps) {
    const navigate = useNavigate();
    const { logout } = useContext(AuthenticationContext);
    
    return (
        <div className={styles.root}>
            <Typography className={styles.errorText}>
                We ran into a problem starting the app, please contact support.
            </Typography>

            {!hideLogout && (
                <Button
                    variant="contained"
                    onClick={async () => {
                        await logout();
                        navigate('/login');
                        onLogout?.();
                    }}
                >
                    Logout
                </Button>
            )}
        </div>
    );
}
