import { FormHelperText } from '@mui/material';
import { CardElement } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { useState } from 'react';

import styles from './StripeCardInput.module.scss';


export default function StripeCardInput() {
    const [ focused, setFocused ] = useState(false);
    const [ error, setError ] = useState<string>();

    return (
        <div>
            <div
                className={clsx(styles.root, {
                    [styles.focused]: focused
                })}
            >
                <CardElement
                    className={styles.cardNumberElement}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onChange={(event) => {
                        if (event.error) {
                            setError(event.error.message);
                        } else if (error) {
                            setError(undefined);
                        }
                    }}
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                fontSize: '16px',
                                letterSpacing: '0.15008px',
                                color: 'rgba(0, 0, 0, 0.87)',
                                iconColor: 'rgba(0, 0, 0, 0.6)',

                                '::placeholder': {
                                    color: 'rgba(0, 0, 0, 0.6)'
                                }
                            },
                            invalid: {
                                color: 'rgba(0, 0, 0, 0.87)'
                            }
                        }
                    }}
                />
            </div>

            {error && (
                <FormHelperText error>
                    {error}
                </FormHelperText>
            )}
        </div>
    );
}
