import {
    ActivityLog, ActivityLogType, isActivityLogUpdate 
} from '@api';


/**
 * Returns a pair of the before and after entity for a given activity log entry
 *
 * @param activityLog - The activity log to extract entities from
 * @param createdType - The ActivityLogType for a create event for this entity e.g. ActivityLotType.FEED_CREATED
 */
export function extractActivityLogEntities<TEntity>(
    { type, data }: ActivityLog<TEntity>,
    createdType?: ActivityLogType
): [TEntity | undefined, TEntity | undefined] {
    return isActivityLogUpdate(data)
        ? [ data.before, data.after ]
        : type === createdType
            ? [ undefined, extractActivityLogEntity(data) ]
            : [ extractActivityLogEntity(data), undefined ];
}

function extractActivityLogEntity<TEntity>(activityLogData: ActivityLog<TEntity>['data']) {
    return isActivityLogUpdate(activityLogData) ? activityLogData.after : activityLogData;
}
