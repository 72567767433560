import { Button } from '@mui/material';
import { getFeed, useEntitySelector } from '@redux/entities';
import { useParams, useRouteState } from '@tsp-ui/utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import Page from '../../common/components/Page';

import styles from './CreateEditFeedPage.module.scss';
import FeedForm from './components/FeedForm/FeedForm';


interface CreateEditFeedPageParams {
    feedID: string;
}

interface CreateEditFeedPageState {
    cancelTo?: string;
}

export default function CreateEditFeedPage() {
    const { feedID } = useParams<CreateEditFeedPageParams>();
    const { cancelTo } = useRouteState<CreateEditFeedPageState>();
    const isCreate = !feedID;

    const [ onShowWidgetPreview, setOnShowWidgetPreview ] = useState<() => void>();

    const feed = useEntitySelector({
        entityType: 'feeds',
        selector: (state) => getFeed(state, feedID),
        disable: isCreate
    });

    return (
        <Page
            variant="centered"
            classNames={{ header: styles.header }}
            header={(
                <>
                    {isCreate ? 'Create feed' : 'Edit feed'}

                    <Button
                        component={Link}
                        to={cancelTo || '/apps/ppm/feeds'}
                        className={styles.cancelButton}
                    >
                        Cancel
                    </Button>
                </>
            )}
            headerActions={(
                <Button
                    className={styles.widgetPreviewButton}
                    onClick={onShowWidgetPreview}
                >
                    Show widget preview
                </Button>
            )}
        >
            {(isCreate || !!feed) && (
                <FeedForm
                    feed={feed}
                    setOnShowWidgetPreview={setOnShowWidgetPreview}
                />
            )}
        </Page>
    );
}
