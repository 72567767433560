import { Feed, Product, Scenario } from '@api';
import { Link as MuiLink, Popover } from '@mui/material';
import {
    EntityTypes,
    getFeeds, getProducts, getScenarios, useEntitySelector
} from '@redux/entities';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './EntitiesLinks.module.scss';


interface EntitiesLinkProps {
    entityType: EntityTypes;
    entityIDs: string[];
    formatEntity: (entity: any) => string;
}

type SelectorReturnType = null | {
    [key: string]: Entity;
}

type Entity = Feed | Product | Scenario;

export default function EntitiesLink({
    entityType,
    entityIDs,
    formatEntity
}: EntitiesLinkProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    const selector = entityType === 'feeds'
        ? getFeeds
        : entityType === 'products'
            ? getProducts
            : getScenarios;

    const entities = useEntitySelector<SelectorReturnType>({
        entityType,
        selector
    });

    return entities && (
        <>
            <MuiLink onClick={(event) => entityIDs.length && setAnchorEl(event.currentTarget)}>
                {entityIDs.length}{' '}

                {entityIDs.length === 1
                    ? entityType.substring(0, entityType.length - 1)
                    : entityType}
            </MuiLink>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
                classes={{
                    paper: styles.popoverPaper
                }}
            >
                <div className={styles.entities}>
                    {entityIDs.map(entityID => (
                        <MuiLink
                            key={entityID}
                            component={Link}
                            to={`/apps/ppm/${entityType}/${entityID}/${entityType === 'products' ? 'config' : 'edit'}`}
                        >
                            {entities[entityID] && formatEntity(entities[entityID])}
                        </MuiLink>
                    ))}
                </div>
            </Popover>
        </>
    );
}
