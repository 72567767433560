import { Logo } from '@components';
import { AnalyticsOutlined, SupportAgent, WarningAmberOutlined } from '@mui/icons-material';
import {
    Button, IconButton, Toolbar, Tooltip
} from '@mui/material';
import { needsACHVerification } from '@redux/payment-info';
import { useDispatch, useSelector } from '@redux/store';
import { openSupportDialog } from '@redux/support';
import { SentryRoutes, Loader } from '@tsp-ui/components';
import { useAsyncEffect } from '@tsp-ui/utils';
import { useHasPermission } from '@views/common/hooks/useHasPermission';
import { Auth } from 'aws-amplify';
import {
    ComponentType, useCallback, useContext, useState
} from 'react';
import {
    Route, Navigate, useLocation, Link
} from 'react-router-dom';


import { AuthenticationContext } from '../../AuthenticationContext';
import { default as AccountPageBase } from '../ppm/account/AccountPage';
import { default as BillingPageBase } from '../ppm/billing/BillingPage';
import { default as UsersPageBase } from '../ppm/users/UsersPage';

import { default as AppRoutesBase } from './AppRoutes';
import styles from './AuthenticatedRouteSwitch.module.scss';
import NoPermissionPageBase from './NoPermissionPage';
import NotFoundPage from './NotFoundPage';
import AccountButton from './components/AccountButton';
import ActivityLogDialog from './components/ActivityLogDialog/ActivityLogDialog';
import MainNav from './components/MainNav';
import VerifyEmailDialog from './components/VerifyEmailDialog';


const AccountPage = withAuthentication(AccountPageBase);
const AppRoutes = withAuthentication(AppRoutesBase);
const UsersPage = withAuthentication(UsersPageBase);
const BillingPage = withAuthentication(BillingPageBase);
const NoPermissionPage = withAuthentication(NoPermissionPageBase);

export default function AuthenticatedRouteSwitch() {
    const dispatch = useDispatch();

    const { user, isInitialized } = useContext(AuthenticationContext);
    const needsVerification = useSelector(needsACHVerification);

    // Set the email to be verified by default, so that it doesn't flash to screen
    const [ isEmailVerified, setIsEmailVerified ] = useState(true);

    useAsyncEffect(useCallback(async () => {
        if (user) {
            const userInfo = await Auth.currentUserInfo();

            if (!userInfo?.attributes?.email_verified) {
                setIsEmailVerified(false);
            }
        }
    }, [ user ]));

    const { isFrameworkAdmin } = useHasPermission();
    const [ showActivityLog, setShowActivityLog ] = useState(false);

    return (
        <div className={styles.root}>
            <MainNav />

            <header className={styles.headerContainer}>
                <Toolbar className={styles.toolbar}>
                    <Logo />

                    <div className={styles.accountButtonContainer}>
                        {needsVerification && (
                            <Button
                                component={Link}
                                to="/account/verify"
                                startIcon={<WarningAmberOutlined color="warning" />}
                                color="warning"
                            >
                                ACH verification required
                            </Button>
                        )}

                        <Tooltip
                            title="Contact support"
                            enterDelay={0}
                        >
                            <IconButton onClick={() => dispatch(openSupportDialog('Support'))}>
                                <SupportAgent color="secondary" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title="View activity log"
                            enterDelay={0}
                        >
                            <IconButton
                                onClick={() => setShowActivityLog(true)}
                                className={styles.activityLogButton}
                            >
                                <AnalyticsOutlined color="secondary" />
                            </IconButton>
                        </Tooltip>

                        <AccountButton className={styles.headerButton} />
                    </div>
                </Toolbar>
            </header>

            {!isInitialized ? (
                <Loader
                    loading
                    className={styles.routeLoader}
                />
            ) : (
                <SentryRoutes>
                    <Route
                        path="/apps/*"
                        element={<AppRoutes />}
                    />

                    <Route
                        path="/users"
                        element={isFrameworkAdmin ? <UsersPage /> : <NoPermissionPage />}
                    />

                    <Route
                        path="/account/*"
                        element={isFrameworkAdmin ? <AccountPage /> : <NoPermissionPage />}
                    />

                    <Route
                        path="/billing"
                        element={<BillingPage />}
                    />

                    <Route
                        path="*"
                        element={<NotFoundPage />}
                    />
                </SentryRoutes>
            )}

            {showActivityLog && (
                <ActivityLogDialog
                    open={showActivityLog}
                    onClose={() => setShowActivityLog(false)}
                />
            )}

            <VerifyEmailDialog
                open={!isEmailVerified}
                setIsEmailVerified={(isVerified) => setIsEmailVerified(isVerified)}
            />
        </div>
    );
}

function withAuthentication(Component: ComponentType) {
    return function AuthenticationWrapper() {
        const { user } = useContext(AuthenticationContext);
        const { pathname } = useLocation();

        return !user ? (
            <Navigate
                to="/login"
                state={{ redirectTo: pathname }}
            />
        ) : (
            <Component />
        );
    };
}
