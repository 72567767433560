import { apiUtils } from '@api';
import { toISO } from '@tsp-ui/utils';
import { parseISO } from 'date-fns';


const urlPrefix = '/products/closing-calendar';

export async function getDays(startDay: Date, endDay: Date): Promise<CalendarDay[]> {
    const days = await apiUtils.get(`${urlPrefix}/day`, {
        startDay: startDay.toISOString(),
        endDay: endDay.toISOString()
    }) as CalendarDay[];

    return days.map(day => ({
        ...day,
        date: toISO(parseISO(day.date))
    }));
}

export function getDayDetails(day: string): Promise<CalendarDayDetail> {
    return apiUtils.get(`${urlPrefix}/day/${day}`);
}

export function getAssociates(): Promise<Associate[]> {
    return apiUtils.get(`${urlPrefix}/associates`);
}

export function updateClosing(loanID: string, closerAssociateId: string, closingDate: Date): Promise<Loan> {
    return apiUtils.patch(`${urlPrefix}/loan/${loanID}`, {
        closerAssociateId,
        closingDate: toISO(closingDate)
    });
}

export function updateAssociateCapacity(capacity: AssociateCapacity): Promise<void> {
    return apiUtils.put(`${urlPrefix}/associates/${capacity.associateId}/capacity`, capacity, {
        emptyResponse: true
    });
}

export function createCapacityOverride(override: CapacityOverride): Promise<void> {
    return apiUtils.post(
        `${urlPrefix}/associates/${override.associateId}/capacityoverride`,
        override, {
            emptyResponse: true
        }
    );
}

export function updateCapacityOverride(override: CapacityOverride): Promise<void> {
    return apiUtils.put(
        `${urlPrefix}/associates/${override.associateId}/capacityoverride`,
        override, {
            emptyResponse: true
        }
    );
}

export function deleteCapacityOverride(override: CapacityOverride): Promise<void> {
    return apiUtils.delete(`${urlPrefix}/associates/${override.associateId}/capacityoverride/${override.id}`);
}

export function getRoles(): Promise<Role[]> {
    return apiUtils.get(`${urlPrefix}/roles`);
}

export function getCalendarInstance(): Promise<CalendarInstance> {
    return apiUtils.get(`${urlPrefix}/instance`);
}

export async function syncClosers(): Promise<void> {
    await apiUtils.get(`${urlPrefix}/associates/sync`, null, { emptyResponse: true });
}

export interface CalendarInstance {
    id: string;
    closingDateFieldCanonicalName: string;
    closingDateFieldId: string;
    editableMilestoneNames: string[];
    roleId: string;
    roleName: string;
    tenantId: string;
}

export interface Role {
    roleId: string;
    roleName: string;
}

export enum LoanPurpose {
    PURCHASE = 'PURCHASE',
    REFINANCE = 'REFINANCE',
    REFINANCE_RATE_TERM = 'REFINANCE_RATE_TERM',
    REFINANCE_CASH_OUT = 'REFINANCE_CASH_OUT'
}

export const loanPurposeDisplay = {
    [LoanPurpose.PURCHASE]: 'Purchase',
    [LoanPurpose.REFINANCE]: 'Refinance',
    [LoanPurpose.REFINANCE_RATE_TERM]: 'Refinance rate term',
    [LoanPurpose.REFINANCE_CASH_OUT]: 'Refinance cash out'
};

export interface CalendarDay {
    date: string;
    availableClosers: number;
    availableCapacity: number;
    loansClosing: number;
    closingVolume: number;
}

export interface CloserCapacity {
    closerAssociateId: string;
    capacity: number;
}

export interface CalendarDayDetail {
    date: string;
    closerCapacities: CloserCapacity[];
    loans: Loan[];
}

export interface Associate {
    id: string;
    name: string;
    encompassUserId: string;
    capacities: AssociateCapacity;
    capacityOverrides?: CapacityOverride[];
}

export interface AssociateCapacity {
    associateId: string;
    sunday: number;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
}

export interface CapacityOverride {
    id: string;
    associateId: string;
    overrideDate: string;
    value: number;
    reason?: string;
}

export interface CreateCapacityOverrideRequest extends CapacityOverride {
    endDate?: string;
}

export interface Loan {
    id: string;
    loanNumber: string;
    closerAssociateId?: string;
    closerFullName?: string;
    closerEncompassUserId?: string;
    processorAssociateName: string;
    underwriterAssociateName: string;
    borrowerNames: string[];
    loanPurpose: LoanPurpose;
    currentMilestone: string;
    lockExpiration?: string;
    loanProgramName: string;
    interestRate?: number;
    loanAmount?: number;
    lockPeriod?: number;
    propertyAddress: Address;
    titleCompany: string;
    closingDate?: string;
    lockDate?: string;
    applicationDate?: string;
    approvedDate?: string;
    clearToCloseDate?: string;
    estimatedClosingDate?: string;
}

interface Address {
    street: string;
    line2?: string;
    city: string;
    state: string;
    zip: string;
    county?: string;
}
