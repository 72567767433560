import { SvgIconComponent } from '@mui/icons-material';
import { Button, Radio, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import { FeedFormValues } from '../FeedForm';

import styles from './FeedTypeButton.module.scss';


interface FeedTypeButtonProps {
    children: ReactNode;
    Icon: SvgIconComponent;
    value: FeedFormValues['feedType'];
    tooltipText: string;
}

export default function FeedTypeButton({
    children, Icon, value, tooltipText 
}: FeedTypeButtonProps) {
    const currentFeedType = useFormContext<FeedFormValues>().watch('feedType');
    const isSelected = currentFeedType === value;

    return (
        <Tooltip title={tooltipText}>
            <Button
                component="div"
                variant="outlined"
                className={clsx(styles.root, {
                    [styles.selected]: isSelected
                })}
            >
                <Radio
                    value={value}
                    className={styles.radio}
                />

                <Icon
                    color={isSelected ? 'secondary' : 'action'}
                    className={styles.icon}
                />

                {children}
            </Button>
        </Tooltip>
    );
}
