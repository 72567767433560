import { EntitiesState } from '@redux/entities/entities.reducer';
import { AppState, useDispatch, useSelector } from '@redux/store';
import { usePageMessage } from '@tsp-ui/components';
import { useAsyncEffect } from '@tsp-ui/utils';
import { useCallback, useState } from 'react';

import { fetchFeeds, fetchProducts, fetchScenarios } from './entities.actions';


export type EntityTypes = keyof Omit<EntitiesState, 'pendingRequests'>;

export interface UseEntitySelectorProps<TSelectorResult> {
    entityType: EntityTypes;
    selector: (state: AppState) => TSelectorResult;
    forceRefresh?: boolean;
    disable?: boolean;
}

/**
 * TODO post-demo kill this monstrosity with fire
 *
 * Custom hook to return the result of the given selector, ensuring that scenarios have been fetched already.
 *
 * @param entityType   - The type of the entities to fetch
 * @param selector     - The selector to run
 * @param forceRefresh - Whether to forcibly refresh if we already have results in redux
 * @param disable      - Whether the fetch should be disabled
 */
export function useEntitySelector<TSelectorResult>({
    entityType,
    selector,
    forceRefresh,
    disable
}: UseEntitySelectorProps<TSelectorResult>) {
    const dispatch = useDispatch();
    const pageMessage = usePageMessage(250);

    const hasPendingReduxRequestInRedux = useSelector((state) => isRequestPending(state, entityType));
    const hasResults = useSelector((state) => isResultPopulated(state, entityType));

    const [ refreshForced, setRefreshForced ] = useState(false);
    const [ hasPendingLocalRequest, setHasPendingLocalRequest ] = useState(false);
    const [ hasError, setHasError ] = useState(false);
    const shouldForce = !refreshForced && forceRefresh;

    useAsyncEffect(useCallback(async () => {
        if (hasPendingReduxRequestInRedux || hasPendingLocalRequest || disable) {
            return;
        }

        if (!hasError && (!hasResults || shouldForce)) {
            setHasPendingLocalRequest(true);

            if (entityType === 'scenarios') {
                const result = await dispatch(fetchScenarios());

                if (fetchScenarios.rejected.match(result)) {
                    setHasError(true);
                    pageMessage.handleApiError('An error occurred while fetching scenarios', result.error);
                }
            } else if (entityType === 'products') {
                const result = await dispatch(fetchProducts());

                if (fetchProducts.rejected.match(result)) {
                    setHasError(true);
                    pageMessage.handleApiError('An error occurred while fetching products', result.error);
                }
            } else if (entityType === 'feeds') {
                const result = await dispatch(fetchFeeds());

                if (fetchFeeds.rejected.match(result)) {
                    setHasError(true);
                    pageMessage.handleApiError('An error occurred while fetching feeds', result.error);
                }
            }

            setHasPendingLocalRequest(false);

            if (forceRefresh) {
                setRefreshForced(true);
            }
        }
    }, [
        disable, dispatch, pageMessage, entityType, forceRefresh, hasPendingReduxRequestInRedux,
        hasResults, shouldForce, hasError, hasPendingLocalRequest
    ]));

    return useSelector(selector);
}

function isRequestPending(state: AppState, entityType: EntityTypes) {
    return state.entities.pendingRequests[entityType] !== null;
}

function isResultPopulated(state: AppState, entityType: EntityTypes) {
    return state.entities[entityType] !== null;
}
