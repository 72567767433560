import bulkUpdater from './bulk-updater';
import closingCalendar from './closing-calendar';
import framework from './framework';
import loanAuditor from './loan-auditor';
import ppm from './ppm';
import { CapraSuiteWebSocket } from './signalr/CapraSuiteWebSocket';


const api = {
    bulkUpdater,
    closingCalendar,
    framework,
    loanAuditor,
    ppm,
    webSocket: new CapraSuiteWebSocket()
};

export default api;
export * from './ppm';
export * from './bulk-updater';
export * from './closing-calendar';
export * from './framework';
export * from './loan-auditor';
export * from './framework/framework-api';
export * from './signalr/CapraSuiteWebSocket';
export * from './signalr/WebSocketEvent';
export * from './signalr/WebSocketEventType';
export * from './api-utils';
