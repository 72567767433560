import { Feed, FeedProduct, ProductGroup } from '@api';
import { ArrayDiffValue, DiffValue } from '@tsp-ui/components';
import { FeedFormValues } from '@views/ppm/feeds/components/FeedForm/FeedForm';
import clsx from 'clsx';
import deepEqual from 'fast-deep-equal';
import React from 'react';

import HeadedBorderCard from '../../../../../../ppm/feeds/components/FeedForm/components/HeadedBorderCard';

import { FeedProductDiff } from './FeedProductDiff';
import styles from './FeedProductGroupDiffs.module.scss';


interface FeedProductGroupDiffsProps {
    feed: FeedFormValues | undefined;
    updatedFeed: FeedFormValues | undefined;
    hideUnchanged: boolean;
}

export function FeedProductGroupDiffs({ feed, updatedFeed, hideUnchanged }: FeedProductGroupDiffsProps) {
    return (
        <div className={styles.root}>
            {[ ...getProductGroupTitleSet(feed, updatedFeed) ].map((groupTitle) => (
                <ProductGroupDiffCard
                    groupTitle={groupTitle}
                    feed={feed}
                    updatedFeed={updatedFeed}
                    hideIfUnchanged={hideUnchanged}
                />
            ))}
        </div>
    );
}

interface ProductGroupDiffCardProps {
    feed: Feed | undefined;
    updatedFeed: Feed | undefined;
    groupTitle: string;
    hideIfUnchanged: boolean;
}

function ProductGroupDiffCard({
    feed, updatedFeed, groupTitle, hideIfUnchanged
}: ProductGroupDiffCardProps) {
    const findGroupByTitle = ({ title }: ProductGroup) => (groupTitle === FLAT_PRODUCT_GROUP_DUMMY_TITLE
        ? title === null
        : title === groupTitle);

    const originalGroup = feed?.productGroups.find(findGroupByTitle);
    const updatedGroup = updatedFeed?.productGroups.find(findGroupByTitle);

    const originalScenarioProductKeys = originalGroup?.products.map(getScenarioProductKey);
    const updatedScenarioProductKeys = updatedGroup?.products.map(getScenarioProductKey);

    return (!hideIfUnchanged || !deepEqual(originalGroup, updatedGroup)) ? (
        <HeadedBorderCard
            headerVariant="body2"
            className={clsx(styles.group, {
                [styles.old]: !updatedGroup,
                [styles.new]: !originalGroup
            })}
            header={(
                <DiffValue
                    originalValue={originalGroup ? (originalGroup.title || 'Flat product list') : undefined}
                    updatedValue={updatedGroup ? (updatedGroup?.title || 'Flat product list') : undefined}
                    isCreate={!originalGroup}
                    isDelete={!updatedGroup}
                />
            )}
        >
            <ArrayDiffValue
                originalValue={originalScenarioProductKeys || []}
                updatedValue={updatedScenarioProductKeys || []}
                disableNone
                hideIfUnchanged={hideIfUnchanged}
                valueContainerClassName={styles.productDiffContainer}
                renderValue={(scenarioProductKey) => (
                    <FeedProductDiff scenarioProductKey={scenarioProductKey} />
                )}
            />
        </HeadedBorderCard>
    ) : null;
}

const FLAT_PRODUCT_GROUP_DUMMY_TITLE = 'FLAT_PRODUCT_GROUP_DUMMY_TITLE';

const getScenarioProductKey = ({ productId, scenarioId }: FeedProduct) => `${productId}_${scenarioId}`;

function getProductGroupTitleSet(feed: FeedFormValues | undefined, updatedFeed: FeedFormValues | undefined) {
    const getProductGroupTitles = (feed: FeedFormValues | undefined) => (
        !feed ? [] : feed?.feedType === 'grouped'
            ? feed?.productGroups?.map(({ title }) => title!) || []
            : [ FLAT_PRODUCT_GROUP_DUMMY_TITLE ]
    );

    return new Set([
        ...getProductGroupTitles(feed),
        ...getProductGroupTitles(updatedFeed)
    ]);
}
