import {
    ActivityLogType, EllieConfiguration
} from '@api';
import {
    DiffContainer, DiffLabeledValue
} from '@tsp-ui/components';
import { useState } from 'react';

import { extractActivityLogEntities } from '../utils/extractActivityLogEntities';

import { ActivityLogCard, ActivityLogCardImplProps } from './ActivityLogCard';


const { ELLIE_CONFIGURATION_CREATED, ELLIE_CONFIGURATION_UPDATED } = ActivityLogType;

export function EllieConfigActivityLogCard({ activityLog, usersMap }: ActivityLogCardImplProps<EllieConfiguration>) {
    const [ ellieConfig, updatedEllieConfig ] = extractActivityLogEntities(activityLog, ELLIE_CONFIGURATION_CREATED);

    const allowHideUnchanged = activityLog.type === ELLIE_CONFIGURATION_UPDATED;
    const [ hideUnchanged, setHideUnchanged ] = useState(allowHideUnchanged);

    const diffProps = {
        original: ellieConfig,
        updated: updatedEllieConfig,
        hideIfUnchanged: hideUnchanged
    };

    return (
        <ActivityLogCard
            activityLog={activityLog}
            usersMap={usersMap}
            showExpand
            entityNameDisplay={null}
        >
            <DiffContainer
                hideUnchanged={hideUnchanged}
                setHideUnchanged={setHideUnchanged}
                allowHideUnchanged={allowHideUnchanged}
            >
                <DiffLabeledValue
                    {...diffProps}
                    label="Instance name"
                    property="instanceName"
                />

                <DiffLabeledValue
                    {...diffProps}
                    label="Client ID"
                    property="clientId"
                />

                <DiffLabeledValue
                    {...diffProps}
                    label="Client secret"
                    property="encryptedClientSecret"
                    renderValue={() => '••••••••••••••••••••'}
                />
            </DiffContainer>
        </ActivityLogCard>
    );
}
