import { FrameworkProduct, useHasProduct, productRoutesMap } from '@api';
import { Typography } from '@mui/material';
import { Loader, SentryRoutes } from '@tsp-ui/components';
import BulkUpdaterPage from '@views/bulk-updater/BulkUpdaterPage';
import ClosersPage from '@views/closing-calendar/ClosersPage';
import { ClosingCalendarPage } from '@views/closing-calendar/ClosingCalendarPage';
import NoPermissionPage from '@views/common/NoPermissionPage';
import NotFoundPage from '@views/common/NotFoundPage';
import Page from '@views/common/components/Page';
import { useEPPSLookups } from '@views/common/hooks/useEPPSLookups';
import { useHasAdminPermission } from '@views/common/hooks/useHasPermission';
import LoanAuditPage from '@views/loan-auditor/LoanAuditPage';
import WidgetSettingsPage from '@views/ppm/calculators/WidgetSettingsPage';
import FeedsPage from '@views/ppm/feeds/FeedsPage';
import ProductsPage from '@views/ppm/products/ProductsPage';
import ScenariosPage from '@views/ppm/scenarios/ScenariosPage';
import { Navigate, Route } from 'react-router-dom';

import AppSelectionPage from './AppSelectionPage';
import styles from './AuthenticatedRouteSwitch.module.scss';


const {
    BULK_UPDATER,
    CLOSING_CALENDAR,
    DATA_BRIDGE,
    PPM
} = FrameworkProduct;

export default function AppRoutes() {
    const { isLoading, hasProduct } = useHasProduct();

    return (
        <SentryRoutes>
            <Route
                path="/"
                element={<AppSelectionPage />}
            />

            {isLoading ? (
                <Route
                    path="*"
                    element={(
                        <Loader
                            loading
                            className={styles.routeLoader}
                        />
                    )}
                />
            ) : (
                <>
                    <Route
                        path={productRoutesMap[BULK_UPDATER]}
                        element={!hasProduct(BULK_UPDATER) ? noAppPermissionPage : <BulkUpdaterPage />}
                    />

                    <Route
                        path={`${productRoutesMap[CLOSING_CALENDAR]}/*`}
                        element={!hasProduct(CLOSING_CALENDAR) ? noAppPermissionPage : <ClosingCalendarRoutes />}
                    />

                    <Route
                        path={`${productRoutesMap[DATA_BRIDGE]}/*`}
                        element={!hasProduct(DATA_BRIDGE) ? noAppPermissionPage : <LoanAuditPage />}
                    />

                    <Route
                        path={`${productRoutesMap[PPM]}/*`}
                        element={!hasProduct(PPM) ? noAppPermissionPage : <PPMRoutes />}
                    />
                </>
            )}
        </SentryRoutes>
    );
}

const noAppPermissionPage = (
    <Page header="Unauthorized">
        <Typography>
            You don't have access to this app. If you believe this is an error, please contact support.
        </Typography>
    </Page>
);


function PPMRoutes() {
    useEPPSLookups(); // Include this here, so it's always mounted when within ppm

    // useEffect(() => {
    // preload('/products/ppm/scenario', api.ppm.scenario.getScenarios);
    // preload('/products/ppm/product', api.ppm.product.getProducts);
    // preload('/products/ppm/feed', api.ppm.feed.getFeeds); TODO
    // }, [ ]);

    return (
        <SentryRoutes>
            <Route
                path="/"
                element={(
                    <Navigate
                        replace
                        to="scenarios"
                    />
                )}
            />

            <Route
                path="/scenarios/*"
                element={<ScenariosPage />}
            />

            <Route
                path="/products/*"
                element={<ProductsPage />}
            />

            <Route
                path="/feeds/*"
                element={<FeedsPage />}
            />

            <Route
                path="/widget-settings/*"
                element={<WidgetSettingsPage />}
            />

            <Route
                path="*"
                element={<NotFoundPage />}
            />
        </SentryRoutes>
    );
}

function ClosingCalendarRoutes() {
    const [ isClosingCalendarAdmin ] = useHasAdminPermission(CLOSING_CALENDAR);

    return (
        <SentryRoutes>
            <Route
                path="/"
                element={(
                    <Navigate
                        replace
                        to="calendar"
                    />
                )}
            />

            <Route
                path="/calendar/*"
                element={<ClosingCalendarPage />}
            />

            <Route
                path="/closers/*"
                element={isClosingCalendarAdmin ? <ClosersPage /> : <NoPermissionPage />}
            />

            <Route
                path="*"
                element={<NotFoundPage />}
            />
        </SentryRoutes>
    );
}
