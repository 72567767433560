import api, { CalculatorLoanType, calculatorLoanTypeDisplay } from '@api';
import { CalculatorSettings } from '@api/ppm/calculator-api';
import { Button, DialogContent, Typography } from '@mui/material';
import {
    DialogActions, FormDialog, renderEnumOptions, TextField, usePageMessage
} from '@tsp-ui/components';
import {
    ComponentProps, useEffect, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './CalculatorSettingsDialog.module.scss';


interface CalculatorSettingsDialogProps extends Omit<ComponentProps<typeof FormDialog>, 'title' | 'onClose'> {
    calculatorSettings: CalculatorSettings | undefined;
    onClose: (updatedSettings?: CalculatorSettings) => void;
}

export default function CalculatorSettingsDialog({
    calculatorSettings, onClose, ...props
}: CalculatorSettingsDialogProps) {
    const pageMessage = usePageMessage();
    const [ loading, setLoading ] = useState(false);

    const formMethods = useForm<CalculatorSettings>({
        defaultValues: calculatorSettings || {
            name: 'Default',
            availableLoanTypes: Object.values(CalculatorLoanType)
        }
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        try {
            setLoading(true);
            onClose(calculatorSettings
                ? await api.ppm.calculator.updateCalculatorSettings(formValues)
                : await api.ppm.calculator.createCalculatorSettings(formValues));

            pageMessage.success('Calculator settings saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the calculator settings', error);
        } finally {
            setLoading(false);
        }
    });

    const { setValue } = formMethods;
    const [ availableLoanTypes, defaultLoanType ] = formMethods.watch([ 'availableLoanTypes', 'defaultLoanType' ]);

    useEffect(() => {
        if (!availableLoanTypes.includes(defaultLoanType)) {
            setValue('defaultLoanType', '' as CalculatorLoanType);
        }
    }, [
        availableLoanTypes, defaultLoanType, setValue
    ]);

    return (
        <FormDialog
            title="Edit Calculator Widget Settings"
            onSubmit={handleSubmit}
            onClose={() => onClose()}
            {...props}
        >
            <DialogContent className={styles.content}>
                <FormProvider {...formMethods}>
                    <TextField<CalculatorSettings>
                        name="availableLoanTypes"
                        label="Available Loan Terms"
                        className={styles.fullWidth}
                        required
                        select
                        SelectProps={{ multiple: true }}
                    >
                        {renderEnumOptions(calculatorLoanTypeDisplay)}
                    </TextField>

                    <Typography
                        fontWeight={500}
                        className={styles.fullWidth}
                    >
                        Default values
                    </Typography>

                    <TextField<CalculatorSettings>
                        name="defaultLoanType"
                        label="Loan Term"
                        required
                        select
                    >
                        {renderEnumOptions(Object.fromEntries(
                            Object.entries(calculatorLoanTypeDisplay).filter(([ key ]) => (
                                availableLoanTypes.includes(key as CalculatorLoanType)
                            ))
                        ))}
                    </TextField>

                    <TextField<CalculatorSettings>
                        name="defaultInterestRate"
                        label="Interest Rate"
                        required
                    />

                    <TextField<CalculatorSettings>
                        name="legalDisclosure"
                        label="Legal Disclosure"
                        className={styles.fullWidth}
                        multiline
                        rows={3}
                    />
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={() => onClose()}>
                    Cancel
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </FormDialog>
    );
}
