import { User } from '@api';
import { AdminPanelSettings as AdminPanelSettingsIcon } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';

import styles from './UserDetails.module.scss';


interface UserDetailsProps {
    user: User;
    hideSystemAdminBadge?: boolean;
}

export default function UserDetails({ user, hideSystemAdminBadge }: UserDetailsProps) {
    return (
        <>
            <Typography className={styles.name}>
                {/* eslint-disable-next-line react/jsx-newline */}
                {user.firstName} {user.lastName}

                {!hideSystemAdminBadge && user.frameworkRole === 'admin' && (
                    <Tooltip
                        title="System admin"
                        enterDelay={100}
                    >
                        <AdminPanelSettingsIcon
                            color="primary"
                            fontSize="small"
                        />
                    </Tooltip>
                )}
            </Typography>

            <Tooltip title="Encompass Username">
                <Typography variant="body2">
                    {user.encompassUserId}
                </Typography>
            </Tooltip>

            <Tooltip title="Encompass Email">
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {user.encompassUserEmail}
                </Typography>
            </Tooltip>
        </>
    );
}
