import { CalendarDay } from '@api';
import { InsertDriveFile, OpenInFull, Person } from '@mui/icons-material';
import {
    Alert, LinearProgress, Skeleton, Slide, Tooltip, Typography
} from '@mui/material';
import { IconTypography, IconButton } from '@tsp-ui/components';
import { formatCurrency } from '@tsp-ui/utils';
import clsx from 'clsx';
import { isToday, parseISO } from 'date-fns';
import {
    useEffect, useRef, useState
} from 'react';
import { Link } from 'react-router-dom';


import styles from './ClosingCalendarDay.module.scss';


interface ClosingCalendarDayProps {
    day: Partial<CalendarDay> & { date: string };
    isOtherMonth: boolean;
    loading: boolean;
    alerts: string[];
}


export function ClosingCalendarDay({
    day, isOtherMonth, loading, alerts
}: ClosingCalendarDayProps) {
    const numAlerts = alerts.filter(date => date === day.date).length;

    const [ alertIn, setAlertIn ] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (numAlerts) {
            setAlertIn(true);

            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                setAlertIn(false);
            }, 5000);
        }
    }, [ numAlerts ]);

    const isOverCapacity = (day.loansClosing || 0) > (day.availableCapacity || 0);

    const date = parseISO(day.date);

    // effect to remove alert when day is changed
    const dateString = day.date;
    useEffect(() => {
        setAlertIn(false);
    }, [ dateString ]);

    return (
        <div className={clsx(styles.root, { [styles.otherMonth]: isOtherMonth })}>
            <div className={styles.dayInfo}>
                <div
                    className={clsx(styles.date, {
                        [styles.today]: isToday(date)
                    })}
                >
                    {date.getDate()}
                </div>

                {loading ? (
                    <Skeleton height="100%" />
                ) : (
                    <div className={styles.statusIcons}>
                        <Tooltip
                            title={`${day.availableClosers} closers available`}
                            enterDelay={0}
                        >
                            <IconTypography
                                className={styles.iconTypography}
                                color="textSecondary"
                                icon={(
                                    <Person
                                        color="inherit"
                                        fontSize="small"
                                    />
                                )}
                            >
                                {day.availableClosers}
                            </IconTypography>
                        </Tooltip>

                        <Tooltip
                            title={`${day.loansClosing} loans closing`}
                            enterDelay={0}
                        >
                            <IconTypography
                                className={styles.iconTypography}
                                color="textSecondary"
                                icon={(
                                    <InsertDriveFile
                                        color="inherit"
                                        fontSize="small"
                                    />
                                )}
                            >
                                {day.loansClosing}
                            </IconTypography>
                        </Tooltip>
                    </div>
                )}

                <IconButton
                    component={Link}
                    to={day.date}
                    tooltip="View day details"
                    size="small"
                >
                    <OpenInFull
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>
            </div>

            <div className={styles.progressContainer}>
                {loading ? (
                    <>
                        <Skeleton width="100%" />

                        <Skeleton width="100%" />
                    </>
                ) : (
                    <>
                        {isOverCapacity && (
                            <Typography
                                variant="caption"
                                color="error"
                                fontWeight={500}
                                align="center"
                                component="div"
                                className={styles.overCapacity}
                            >
                                OVER CAPACITY
                            </Typography>
                        )}

                        <LinearProgress
                            variant="determinate"
                            color={isOverCapacity ? 'error' : 'primary'}
                            value={isOverCapacity
                                ? 100
                                : ((day.loansClosing || 0) / (day.availableCapacity || 0)) * 100}
                            className={styles.progress}
                        />

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            align="center"
                            component="div"
                            className={styles.progressCaption}
                        >
                            {`${day.loansClosing}/${day.availableCapacity} slots filled`}
                        </Typography>
                    </>
                )}
            </div>

            <Tooltip title="Closing volume">
                <Typography
                    fontWeight={400}
                    color="textSecondary"
                    className={styles.volume}
                >
                    {loading
                        ? <Skeleton width="72px" />
                        : formatCurrency(day.closingVolume) || '--'}
                </Typography>
            </Tooltip>

            <div className={styles.alert}>
                <Slide
                    direction="up"
                    in={alertIn}
                >
                    <Alert
                        className={styles.alertBody}
                        icon={false}
                        severity="info"
                    >
                        New closing added
                    </Alert>
                </Slide>
            </div>
        </div>
    );
}
