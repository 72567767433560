import api, { Invoice } from '@api';
import { CheckCircleOutline, ErrorOutline, InfoOutlined } from '@mui/icons-material';
import {
    DialogContent, Paper, Tooltip, Typography 
} from '@mui/material';
import { Dialog, usePageMessage } from '@tsp-ui/components';
import { useAsyncEffect, format, formatCurrencyCents } from '@tsp-ui/utils';
import {
    formatDistanceToNowStrict, isPast, isToday, isTomorrow, parseISO
} from 'date-fns';
import { useCallback, useState } from 'react';

import styles from './InvoiceHistoryDialog.module.scss';


interface InvoiceHistoryDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function InvoiceHistoryDialog({ open, onClose }: InvoiceHistoryDialogProps) {
    const [ invoices, setInvoices ] = useState<Invoice[]>();
    const [ loading, setLoading ] = useState(false);

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        if (open) {
            setLoading(true);

            try {
                setInvoices(await api.ppm.payment.getInvoiceHistory());
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching invoice history', error);
            }

            setLoading(false);
        }
    }, [ open, pageMessage ]));

    return (
        <Dialog
            title="Invoice history"
            open={open}
            onClose={() => onClose()}
            loading={loading}
        >
            <DialogContent className={styles.content}>
                {invoices?.map((invoice) => (
                    <InvoiceCard
                        key={invoice.invoiceId}
                        invoice={invoice}
                    />
                ))}
            </DialogContent>
        </Dialog>
    );
}

interface InvoiceCardProps {
    invoice: Invoice;
}

function InvoiceCard({
    invoice: {
        createdDate: createdDateStr, dueDate: dueDateStr, amountPaid, amountRemaining, isPaid
    } 
}: InvoiceCardProps) {
    const invoiceDate = parseISO(createdDateStr);
    const dueDate = parseISO(dueDateStr);

    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <div>
                <Typography variant="h6">
                    {formatCurrencyCents(amountPaid + amountRemaining)}
                </Typography>

                <Tooltip
                    title="Invoice date"
                    enterDelay={0}
                >
                    <Typography>
                        {format(invoiceDate)}
                    </Typography>
                </Tooltip>
            </div>

            {(amountRemaining === 0 || isPaid) ? (
                <Typography className={styles.iconTypography}>
                    <CheckCircleOutline color="success" />

                    Paid
                </Typography>
            ) : (
                isPast(dueDate) ? (
                    <Typography className={styles.iconTypography}>
                        <ErrorOutline color="error" />

                        Overdue
                    </Typography>
                ) : (
                    <Tooltip
                        title={format(dueDate)}
                        enterDelay={0}
                    >
                        <Typography className={styles.iconTypography}>
                            <InfoOutlined color="primary" />

                            due {isToday(dueDate)
                                ? 'today'
                                : isTomorrow(dueDate)
                                    ? 'tomorrow'
                                    : `in ${formatDistanceToNowStrict(dueDate)}`}
                        </Typography>
                    </Tooltip>
                )
            )}
        </Paper>
    );
}
