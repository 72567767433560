import api from '@api';
import useSwr from 'swr';


export function useEPPSLookups() {
    const { data, ...swrResponse } = useSwr(
        '/product/ppm/lookup',
        api.ppm.lookup.getLookups,
        {
            revalidateOnFocus: false,
            keepPreviousData: true
        }
    );

    return {
        ...swrResponse,
        lookups: data
    };
}
