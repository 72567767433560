import api, { FeedToken } from '@api';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { FormDialog, TextField } from '@tsp-ui/components';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './FeedToken.module.scss';


interface FeedAccessTokenDialogProps {
    token: FeedToken | undefined;
    onClose: (refreshResults: boolean) => void;
    showCreate: boolean;
    feedID: string;
}


export default function FeedTokenDialog({
    token, onClose, showCreate, feedID 
}: FeedAccessTokenDialogProps) {
    const formMethods = useForm<FeedToken>({
        defaultValues: token
    });
    const { reset } = formMethods;

    useEffect(() => {
        if (showCreate || token) {
            reset(showCreate ? {
                feedId: feedID,
                name: '',
                description: ''
            } : token);
        }
    }, [
        reset, token, showCreate, feedID
    ]);

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        token?.id
            ? await api.ppm.feed.updateFeedToken(formValues)
            : await api.ppm.feed.createFeedToken(formValues);

        onClose(true);
    });

    return (
        <FormDialog
            maxWidth="xs"
            open={showCreate || !!token}
            onClose={() => onClose(false)}
            title={showCreate
                ? 'Create token'
                : `Edit token${!token ? '' : ` - ${token.name}`}`}
            onSubmit={handleSubmit}
        >
            <DialogContent className={styles.content}>
                <FormProvider {...formMethods}>
                    <TextField<FeedToken>
                        name="name"
                        label="Name"
                    />

                    <TextField<FeedToken>
                        name="description"
                        label="Description"
                        multiline
                        className={styles.description}
                    />
                </FormProvider>
            </DialogContent>

            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </FormDialog>
    );
}
