import api, {
    FeedProduct, FeedResult, FeedToken, WidgetTheme
} from '@api';
import { InsertLink } from '@mui/icons-material';
import {
    Button, Divider, IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { getAppConfig } from '@redux/config';
import { getFeed, useEntitySelector } from '@redux/entities';
import { useSelector } from '@redux/store';
import { CopyButton, LabeledValue, usePageMessage } from '@tsp-ui/components';
import { useParams, useAsyncEffect } from '@tsp-ui/utils';
import EmbedWidgetDialog from '@views/ppm/calculators/EmbedWidgetDialog';
import { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Page from '../../common/components/Page';

import styles from './FeedDetailPage.module.scss';
import FeedAccessTokensSection from './components/FeedAccessTokensSection';
import FeedDetailProductCard from './components/FeedDetailProductCard';
import FeedDetailsDisplayCard from './components/FeedForm/components/FeedDetailsDisplayCard';
import HeadedBorderCard from './components/FeedForm/components/HeadedBorderCard';
import FeedWidgetPreviewDialog from './components/FeedWidgetPreviewDialog';


interface FeedDetailPageParams {
    feedID: string;
}

export default function FeedDetailPage() {
    const { feedID } = useParams<FeedDetailPageParams>();
    const { apiUrl } = useSelector(getAppConfig) || {};
    const navigate = useNavigate();
    const location = useLocation();
    const pageMessage = usePageMessage(250);

    const feed = useEntitySelector({
        entityType: 'feeds',
        selector: (state) => getFeed(state, feedID)
    });

    const feedType = feed?.productGroups[0].title ? 'grouped' : 'flat';
    const feedURL = `${apiUrl}/products/ppm/feed/${feed?.id}/result`;

    const [ showPreview, setShowPreview ] = useState(false);
    const [ showEmbedWidget, setShowEmbedWidget ] = useState(false);

    const testWidget = useCallback(() => (
        api.ppm.feed.testFeed(feed!)
    ), [ feed ]);

    const [ tokens, setTokens ] = useState<FeedToken[]>();

    const fetchTokens = useCallback(async () => {
        try {
            setTokens(await api.ppm.feed.getFeedTokens(feedID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching feed tokens', error);
        }
    }, [ feedID, pageMessage ]);

    useAsyncEffect(fetchTokens);

    const [ feedResult, setFeedResult ] = useState<FeedResult>();
    const [ feedResultLoading, setFeedResultLoading ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        if (feed) {
            setFeedResultLoading(true);

            try {
                setFeedResult(await api.ppm.feed.testFeed(feed));
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching feed results', error);
            }

            setFeedResultLoading(false);
        }
    }, [ feed, pageMessage ]));

    function renderProductCards(feedProducts: FeedProduct[]) {
        return feedProducts.map((product) => (
            <FeedDetailProductCard
                key={product.productId}
                product={product}
                feedResult={feedResult}
                feedResultLoading={feedResultLoading}
            />
        ));
    }

    const [ themeLoading, setThemeLoading ] = useState(true);
    const [ theme, setTheme ] = useState<WidgetTheme>();

    useAsyncEffect(useCallback(async () => {
        try {
            setTheme(await api.ppm.widget.fetchWidgetTheme());
            setThemeLoading(false);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching feed results', error);
        }
    }, [ pageMessage ]));

    return (
        <Page
            header="Feed details"
            loading={!feed}
        >
            {feed && (
                <div className={styles.root}>
                    <FeedDetailsDisplayCard
                        feedName={feed.name}
                        feedType={feedType}
                        onEditClick={() => {
                            navigate(location.pathname.replace('details', 'edit'), {
                                state: { cancelTo: location.pathname }
                            });
                        }}
                        headerVariant="h6"
                    >
                        <Divider className={styles.divider} />

                        <Typography
                            color="textSecondary"
                            className={styles.label}
                        >
                            {feedType === 'grouped' ? 'Product groups' : 'Products'}
                        </Typography>

                        <div className={styles.groups}>
                            {feedType === 'flat' ? renderProductCards(feed.productGroups[0].products) : (
                                feed.productGroups.map(({ title, products }) => (
                                    <HeadedBorderCard
                                        key={title}
                                        header={title}
                                    >
                                        {renderProductCards(products)}
                                    </HeadedBorderCard>
                                ))
                            )}
                        </div>
                    </FeedDetailsDisplayCard>

                    <div className={styles.rightSidebar}>
                        <Typography
                            variant="h5"
                            className={styles.feedWidgetHeader}
                        >
                            Feed widget

                            <Button
                                className={styles.widgetPreviewButton}
                                onClick={() => setShowPreview(true)}
                            >
                                View widget preview
                            </Button>
                        </Typography>

                        <Paper
                            variant="outlined"
                            className={styles.widgetPaper}
                        >
                            {tokens?.length ? (
                                <Typography>
                                    Using access token:{' '}

                                    <Typography
                                        fontWeight={500}
                                        component="span"
                                    >
                                        {tokens[0].name}
                                    </Typography>
                                </Typography>
                            ) : (
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    Create an access token to enable widget embedding
                                </Typography>
                            )}

                            <Tooltip title={tokens?.length ? 'Embed widget' : ''}>
                                <span>
                                    <IconButton
                                        disabled={!tokens?.length}
                                        onClick={() => setShowEmbedWidget(true)}
                                    >
                                        <InsertLink color={tokens?.length ? 'secondary' : 'disabled'} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Paper>

                        <Typography
                            variant="h5"
                            className={styles.apiAccessHeader}
                        >
                            Api access
                        </Typography>

                        <div className={styles.feedUrlContainer}>
                            <LabeledValue
                                variants={{ label: 'body2' }}
                                label="Feed URL"
                                value={feedURL}
                            />

                            <CopyButton
                                textToCopy={feedURL}
                                successMessage="Feed url copied"
                                children={({ onClick }) => (
                                    <Button
                                        className={styles.copyFeedButton}
                                        onClick={onClick}
                                    >
                                        Copy feed url
                                    </Button>
                                )}
                            />
                        </div>

                        <FeedAccessTokensSection
                            feedID={feedID}
                            className={styles.accessTokens}
                            tokens={tokens}
                            fetchTokens={fetchTokens}
                        />
                    </div>

                    <FeedWidgetPreviewDialog
                        open={showPreview}
                        onClose={() => setShowPreview(false)}
                        widgetProps={{
                            fetchResults: testWidget
                        }}
                    />

                    <EmbedWidgetDialog
                        feed={feed}
                        theme={theme}
                        open={showEmbedWidget}
                        onClose={() => setShowEmbedWidget(false)}
                        loading={themeLoading}
                    />
                </div>
            )}
        </Page>
    );
}
