import {
    FrameworkProduct, productRoutesMap, useHasProduct
} from '@api';
import {
    Apps, CalendarMonth, Home, PriceCheck, Upload, WebStories
} from '@mui/icons-material';
import {
    Button, IconButton, Popover, Tooltip, Typography
} from '@mui/material';
import { Loader } from '@tsp-ui/components';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './AppsButton.module.scss';


interface AppsButtonProps {
    className?: string;
}

export default function AppsButton({ className }: AppsButtonProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    function handleAppButtonClick() {
        setAnchorEl(undefined);
    }

    return (
        <>
            <Tooltip title="View apps">
                <IconButton
                    className={className}
                    onClick={(event) => setAnchorEl(event.currentTarget.parentElement || undefined)}
                    color="inherit"
                >
                    <Apps
                        color="inherit"
                        fontSize="large"
                    />
                </IconButton>
            </Tooltip>

            <Popover
                open={!!anchorEl}
                onClose={() => {
                    setAnchorEl(undefined);
                    handleAppButtonClick();
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.popover
                }}
            >
                <div className={styles.header}>
                    <Typography
                        variant="h6"
                        color="inherit"
                    >
                        Available Apps
                    </Typography>

                    <IconButton
                        component={Link}
                        to="/apps"
                        color="inherit"
                        onClick={handleAppButtonClick}
                    >
                        <Home color="inherit" />
                    </IconButton>
                </div>

                <AppsButtons onAppClick={handleAppButtonClick} />
            </Popover>
        </>
    );
}

interface AppButtonProps {
    to: string;
    icon: ReactNode;
    name: ReactNode;
    onClick?: () => void;
}

export function AppButton({
    to, icon, name, onClick
}: AppButtonProps) {
    return (
        <Button
            component={Link}
            to={`/apps/${to}`}
            variant="outlined"
            onClick={onClick}
            className={styles.appButton}
        >
            {icon}

            <Typography align="center">
                {name}
            </Typography>
        </Button>
    );
}

const {
    BULK_UPDATER, CLOSING_CALENDAR, DATA_BRIDGE, PPM
} = FrameworkProduct;

interface AppsButtonsProps {
    onAppClick?: () => void;
}

export function AppsButtons({ onAppClick }: AppsButtonsProps) {
    const { isLoading, hasProduct } = useHasProduct();

    return isLoading ? (
        <Loader loading />
    ) : (
        <>
            {hasProduct(BULK_UPDATER) && (
                <AppButton
                    to={productRoutesMap[BULK_UPDATER]}
                    name="Bulk Updater"
                    onClick={onAppClick}
                    icon={(
                        <Upload
                            fontSize="large"
                            color="secondary"
                        />
                    )}
                />
            )}

            {hasProduct(CLOSING_CALENDAR) && (
                <AppButton
                    to={productRoutesMap[CLOSING_CALENDAR]}
                    name="Closing Calendar"
                    onClick={onAppClick}
                    icon={(
                        <CalendarMonth
                            fontSize="large"
                            color="secondary"
                        />
                    )}
                />
            )}

            {hasProduct(DATA_BRIDGE) && (
                <AppButton
                    to={productRoutesMap[DATA_BRIDGE]}
                    name="Loan Auditor"
                    onClick={onAppClick}
                    icon={(
                        <WebStories
                            className={styles.auditorIcon}
                            fontSize="large"
                            color="secondary"
                        />
                    )}
                />
            )}

            {hasProduct(PPM) && (
                <AppButton
                    to={productRoutesMap[PPM]}
                    onClick={onAppClick}
                    name={(
                        <>
                            Product & Pricing<br />Manager
                        </>
                    )}
                    icon={(
                        <PriceCheck
                            fontSize="large"
                            color="secondary"
                        />
                    )}
                />
            )}
        </>
    );
}
