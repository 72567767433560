import { apiUtils } from '@api/api-utils';


export function getCalculatorSettings(): Promise<CalculatorSettings[]> {
    return apiUtils.get('/products/ppm/calculator');
}

export function createCalculatorSettings(settings: Omit<CalculatorSettings, 'id' | 'instanceId'>):
    Promise<CalculatorSettings> {
    return apiUtils.post('/products/ppm/calculator', settings);
}

export function updateCalculatorSettings(settings: CalculatorSettings): Promise<CalculatorSettings> {
    return apiUtils.put(`/products/ppm/calculator/${settings.id}`, settings);
}

export interface CalculatorSettings {
    id: string;
    instanceId: string;
    name: string;
    availableLoanTypes: CalculatorLoanType[];
    defaultLoanType: CalculatorLoanType;
    defaultInterestRate: number;
    legalDisclosure?: string;
}

export enum CalculatorLoanType {
    FIXED_30 = 'FIXED_30',
    FIXED_20 = 'FIXED_20',
    FIXED_15 = 'FIXED_15',
    FIXED_10 = 'FIXED_10'
}

export const calculatorLoanTypeDisplay = {
    [CalculatorLoanType.FIXED_30]: '30 Year',
    [CalculatorLoanType.FIXED_20]: '20 Year',
    [CalculatorLoanType.FIXED_15]: '15 Year',
    [CalculatorLoanType.FIXED_10]: '10 Year'
};
