import { ScenarioResult, Scenario } from '@api';
import { Typography } from '@mui/material';
import { FilledSection, PaperSaveLoader } from '@tsp-ui/components';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, useCallback,
    useEffect, useMemo, useState
} from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';

import styles from './ScenarioPreviewSection.module.scss';
import ScenarioPreviewHeader from './components/ScenarioPreviewHeader';
import ScenarioPreviewResults from './components/ScenarioPreviewResults';


SyntaxHighlighter.registerLanguage('json', json);

export interface ScenarioPreviewProps {
    createFetchResults: (setResults: Dispatch<SetStateAction<ScenarioResult | undefined>>) =>
        ReturnType<UseFormHandleSubmit<Scenario>>;
}

export default function ScenarioPreviewSection({ createFetchResults }: ScenarioPreviewProps) {
    const [ loading, setLoading ] = useState(false);
    const [ showJSON, setShowJSON ] = useState(false);
    const [ results, setResults ] = useState<ScenarioResult>();

    useEffect(() => () => setResults(undefined), [ setResults ]);

    const fetchResults = useCallback(async () => {
        setLoading(true);

        await createFetchResults(setResults)(); // Errors are handled in ScenarioForm

        setLoading(false);
    }, [ createFetchResults ]);

    const resultsJSON = useMemo(() => (
        JSON.stringify(results, null, 2)
    ), [ results ]);

    const initialResultsLoading = loading && !results;

    const urlParams = new URLSearchParams(useLocation().search);
    const showProducts = urlParams.get('showProducts') === 'true';

    useEffect(() => {
        if (showProducts) {
            fetchResults();
        }
    }, [ showProducts, fetchResults ]);

    return (
        <FilledSection
            variant="light"
            className={clsx(styles.queryResults, {
                [styles.isJson]: showJSON
            })}
            header={(
                <ScenarioPreviewHeader
                    loading={loading}
                    fetchResults={fetchResults}
                    showJSON={showJSON}
                    setShowJSON={setShowJSON}
                    resultsJSON={resultsJSON}
                />
            )}
            scrollable
        >
            {initialResultsLoading ? (
                <Typography
                    variant="body2"
                    color="textSecondary"
                    className={styles.loadingText}
                >
                    Your scenario preview is loading. This may take a moment.
                </Typography>
            ) : (
                <ScenarioPreviewResults
                    results={results}
                    showJSON={showJSON}
                    resultsJSON={resultsJSON}
                />
            )}

            <PaperSaveLoader loading={initialResultsLoading} />
        </FilledSection>
    );
}
