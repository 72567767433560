import api, {
    calculatorLoanTypeDisplay, CalculatorSettings, WidgetTheme
} from '@api';
import { Edit, Save } from '@mui/icons-material';
import {
    Button, MenuItem, Paper, TextField, Typography
} from '@mui/material';
import { getAppConfig } from '@redux/config';
import { getFeedsList, useEntitySelector } from '@redux/entities';
import { useSelector } from '@redux/store';
import {
    IconButton, LabeledValue, useConfirm, usePageMessage
} from '@tsp-ui/components';
import { useAsyncEffect } from '@tsp-ui/utils';
import Page from '@views/common/components/Page';
import CalculatorSettingsDialog from '@views/ppm/calculators/CalculatorSettingsDialog';
import EmbedWidgetDialog from '@views/ppm/calculators/EmbedWidgetDialog';
import clsx from 'clsx';
import deepEqual from 'fast-deep-equal';
import {
    useCallback, useEffect, useLayoutEffect, useRef, useState
} from 'react';

import styles from './WidgetSettingsPage.module.scss';


export default function WidgetSettingsPage() {
    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const { apiUrl } = useSelector(getAppConfig) || {};

    const [ loading, setLoading ] = useState(true);
    const [ calculatorSettings, setCalculatorSettings ] = useState<CalculatorSettings>();
    const [ theme, setTheme ] = useState<WidgetTheme>();
    const [ originalTheme, setOriginalTheme ] = useState<WidgetTheme>();

    const [ settingsOpen, setSettingsOpen ] = useState(false);
    const [ editTheme, setEditTheme ] = useState(false);
    const [ showEmbed, setShowEmbed ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        try {
            setLoading(true);

            const [ calculatorSettings, theme ] = await Promise.all([
                api.ppm.calculator.getCalculatorSettings(),
                api.ppm.widget.fetchWidgetTheme()
            ]);

            setCalculatorSettings(calculatorSettings[0]);
            setTheme(theme);
            setOriginalTheme(theme);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the calculator settings or theme', error);
        } finally {
            setLoading(false);
        }
    }, [ pageMessage ]));

    useEffect(() => {
        if (theme) {
            window.PPMWidgets.configure({
                urlBase: `${apiUrl}/products/ppm`,
                theme
            });
        }
    }, [ apiUrl, theme ]);

    async function saveTheme() {
        try {
            if (theme) {
                const updatedTheme = await api.ppm.widget.updateWidgetTheme(theme);

                setTheme(updatedTheme);
                setOriginalTheme(updatedTheme);

                pageMessage.success(`Your theme has been updated. Please re-embed your widgets
                    to apply the new theme.`);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the theme', error);
        }
    }

    async function cancelSave() {
        if (deepEqual(theme, originalTheme)
            || await confirm('Your unsaved theme changes will be lost. Are you sure you want to continue?')
        ) {
            setEditTheme(false);
            setTheme(originalTheme);
        }
    }

    return (
        <Page
            header="Widget Settings"
            loading={loading}
            headerActions={(
                <Button
                    variant="contained"
                    onClick={() => setShowEmbed(true)}
                >
                    Embed widget
                </Button>
            )}
        >
            <div className={styles.root}>
                <Paper
                    variant="outlined"
                    className={styles.detailsPaper}
                >
                    <Typography
                        variant="h6"
                        className={styles.header}
                    >
                        Theme Colors

                        {editTheme ? (
                            <>
                                <Button
                                    className={styles.cancelButton}
                                    onClick={cancelSave}
                                >
                                    Cancel
                                </Button>

                                <IconButton
                                    title="Save"
                                    onClick={async () => {
                                        await saveTheme();
                                        setEditTheme(false);
                                    }}
                                >
                                    <Save color="secondary" />
                                </IconButton>
                            </>
                        ) : (
                            <IconButton
                                title="Edit"
                                onClick={() => setEditTheme(true)}
                            >
                                <Edit color="secondary" />
                            </IconButton>
                        )}
                    </Typography>

                    <ColorPickerItem
                        color={theme?.primaryColor}
                        label="Primary"
                        edit={editTheme}
                        setColor={(primaryColor) => theme && setTheme({
                            ...theme,
                            primaryColor
                        })}
                    />

                    <ColorPickerItem
                        color={theme?.secondaryColor}
                        label="Secondary"
                        edit={editTheme}
                        setColor={(secondaryColor) => theme && setTheme({
                            ...theme,
                            secondaryColor
                        })}
                    />

                    <ColorPickerItem
                        color={theme?.rateWidgetBackgroundColor}
                        label="Rate widget background"
                        edit={editTheme}
                        setColor={(rateWidgetBackgroundColor) => theme && setTheme({
                            ...theme,
                            rateWidgetBackgroundColor
                        })}
                    />

                    <ColorPickerItem
                        color={theme?.calculatorBackgroundColor}
                        label="Calculator background"
                        edit={editTheme}
                        setColor={(calculatorBackgroundColor) => theme && setTheme({
                            ...theme,
                            calculatorBackgroundColor
                        })}
                    />
                </Paper>

                <Paper
                    variant="outlined"
                    className={clsx(styles.detailsPaper, styles.calcSettings)}
                >
                    <Typography
                        variant="h6"
                        className={styles.header}
                    >
                        Calculator Settings

                        <IconButton
                            title="Edit"
                            onClick={() => setSettingsOpen(true)}
                        >
                            <Edit color="secondary" />
                        </IconButton>
                    </Typography>

                    <LabeledValue
                        variant="vertical"
                        label="Available Loan Terms"
                        value={calculatorSettings?.availableLoanTypes.map((loanType) => (
                            calculatorLoanTypeDisplay[loanType]
                        )).join(', ')}
                    />

                    <Typography
                        fontWeight={500}
                        className={styles.defaultValuesLabel}
                    >
                        Default values
                    </Typography>

                    <div className={styles.defaultValuesRow}>
                        <LabeledValue
                            variant="vertical"
                            label="Loan Term"
                            value={calculatorSettings && calculatorLoanTypeDisplay[calculatorSettings.defaultLoanType]}
                        />

                        <LabeledValue
                            variant="vertical"
                            label="Interest Rate"
                            value={calculatorSettings?.defaultInterestRate}
                        />
                    </div>

                    <LabeledValue
                        variant="vertical"
                        label="Legal Disclosure"
                        value={calculatorSettings?.legalDisclosure || '--'}
                        classNames={{
                            value: styles.justify
                        }}
                    />
                </Paper>

                <Typography
                    variant="h5"
                    component="h2"
                    className={styles.widgetPreviewsHeading}
                >
                    Widget Preview
                </Typography>

                <RateWidgetPreview />

                <CalculatorPreview calculatorSettingsId={calculatorSettings?.id} />
            </div>

            <CalculatorSettingsDialog
                calculatorSettings={calculatorSettings}
                open={settingsOpen}
                onClose={(updatedSettings) => {
                    setSettingsOpen(false);

                    if (updatedSettings) {
                        setCalculatorSettings(updatedSettings);
                    }
                }}
            />

            <EmbedWidgetDialog
                open={showEmbed}
                theme={theme}
                calculatorId={calculatorSettings?.id}
                onClose={() => setShowEmbed(false)}
            />
        </Page>
    );
}

interface CalculatorPreviewProps {
    calculatorSettingsId: string | undefined;
}

function CalculatorPreview({ calculatorSettingsId }: CalculatorPreviewProps) {
    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (ref.current && calculatorSettingsId) {
            window.PPMWidgets.initCalculator(ref.current, { id: calculatorSettingsId });

            const refNode = ref.current;

            return () => window.PPMWidgets.unmount(refNode);
        }
    }, [ calculatorSettingsId ]);

    return (
        <div ref={ref} />
    );
}

function RateWidgetPreview() {
    const ref = useRef<HTMLDivElement>(null);

    const [ feedId, setFeedId ] = useState<string>('');
    const feeds = useEntitySelector({
        entityType: 'feeds',
        selector: getFeedsList,
        forceRefresh: true
    });

    useEffect(() => {
        if (feeds?.length) {
            setFeedId(feeds[0].id);
        }
    }, [ feeds ]);

    const feed = feeds?.find((feed) => feed.id === feedId);

    const testWidget = useCallback(() => (
        api.ppm.feed.testFeed(feed!)
    ), [ feed ]);

    useLayoutEffect(() => {
        if (ref.current) {
            window.PPMWidgets.initRateWidget(ref.current, {
                fetchResults: testWidget
            });

            const refNode = ref.current;

            return () => window.PPMWidgets.unmount(refNode);
        }
    }, [ testWidget ]);

    return (
        <div>
            <TextField
                label="Feed to preview"
                value={feedId}
                onChange={(event) => setFeedId(event.target.value)}
                select
                fullWidth
                className={styles.rateSelect}
            >
                {feeds?.map((feed => (
                    <MenuItem
                        key={feed.id}
                        value={feed.id}
                    >
                        {feed.name}
                    </MenuItem>
                ))) || (
                    <MenuItem value={feedId}>
                        Loading...
                    </MenuItem>
                )}
            </TextField>

            {feedId && (
                <div ref={ref} />
            )}
        </div>
    );
}

interface ColorPickerItemProps {
    color: string | undefined;
    label: string;
    edit: boolean;
    setColor: (color: string) => void;
}

function ColorPickerItem({
    color, label, edit, setColor
}: ColorPickerItemProps) {
    return (
        <div className={styles.colorPickerItem}>
            {edit ? (
                <input
                    type="color"
                    value={color}
                    className={styles.colorInput}
                    onChange={(event) => setColor(event.target.value)}
                />
            ) : (
                <div
                    className={styles.colorPickerItemColor}
                    title={color}
                    style={{ backgroundColor: color }}
                />
            )}

            <span>{label}</span>
        </div>
    );
}
