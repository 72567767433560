import { AppState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { formatProductName } from '@views/ppm/feeds/components/FeedForm/components/FeedProductCard';
import { parseISO } from 'date-fns';


/** Feed selectors */
export const getFeedsList = createSelector(getFeeds, (feeds) => feeds && Object.values(feeds).sort((feedA, feedB) => (
    parseISO(feedA.createdDate).getTime() - parseISO(feedB.createdDate).getTime()
)));

export function getFeed(state: AppState, id: string) {
    return getFeeds(state)?.[id];
}

export function getFeeds(state: AppState) {
    return state.entities.feeds;
}

/** Scenario selectors */
export const getScenariosList = createSelector(getScenarios,
    (scenarios) => scenarios && Object.values(scenarios).sort((scenarioA, scenarioB) => (
        parseISO(scenarioA.createdDate).getTime() - parseISO(scenarioB.createdDate).getTime()
    )));

export function getScenario(state: AppState, id: string) {
    return getScenarios(state)?.[id];
}

export function getScenarios(state: AppState) {
    return state.entities.scenarios;
}


/** Product selectors */
export const getProductsList = createSelector(getProducts,
    (products) => products && Object.values(products).sort((productA, productB) => (
        (formatProductName(productA)).localeCompare(formatProductName(productB))
    )));

export function getProduct(state: AppState, id: string) {
    return getProducts(state)?.[id];
}

export function getProducts(state: AppState) {
    return state.entities.products;
}
