import { Typography } from '@mui/material';
import { getProduct, getScenario, useEntitySelector } from '@redux/entities';
import { formatProductName } from '@views/ppm/feeds/components/FeedForm/components/FeedProductCard';


interface FeedProductDiffProps {
    scenarioProductKey: string;
}

export function FeedProductDiff({ scenarioProductKey }: FeedProductDiffProps) {
    const [ productId, scenarioId ] = scenarioProductKey.split('_');

    const scenario = useEntitySelector({
        entityType: 'scenarios',
        selector: (state) => getScenario(state, scenarioId)
    });

    const product = useEntitySelector({
        entityType: 'products',
        selector: (state) => getProduct(state, productId)
    });

    return (
        <>
            <Typography variant="body2">
                {product ? formatProductName(product) : 'Product not found'}
            </Typography>

            <Typography
                color="textSecondary"
                variant="caption"
            >
                from
            </Typography>

            <Typography variant="body2">
                {scenario ? scenario.name : 'Scenario not found'}
            </Typography>
        </>
    );
}
