import { ActivityLogType, Feed } from '@api';
import { getFeed, useEntitySelector } from '@redux/entities';
import { DiffLabeledValue, DiffContainer, DiffHeader } from '@tsp-ui/components';
import { getFeedFormValues } from '@views/ppm/feeds/components/FeedForm/FeedForm';
import deepEqual from 'fast-deep-equal';
import { useState } from 'react';

import { extractActivityLogEntities } from '../../utils/extractActivityLogEntities';
import { ActivityLogCard, ActivityLogCardImplProps } from '../ActivityLogCard';
import { EntityNameDisplay } from '../EntityNameDisplay';

import { FeedProductGroupDiffs } from './components/FeedProductGroupDiffs';


const { FEED_CREATED, FEED_UPDATED } = ActivityLogType;

export function FeedActivityLogCard({ activityLog, usersMap }: ActivityLogCardImplProps<Feed>) {
    const [ feed, updatedFeed ] = extractActivityLogEntities(activityLog, FEED_CREATED).map(getFeedFormValues);
    const { name, id } = feed || updatedFeed!;

    const feedExists = useEntitySelector({
        entityType: 'feeds',
        selector: (state) => getFeed(state, id)
    });

    const allowHideUnchanged = activityLog.type === FEED_UPDATED;
    const [ hideUnchanged, setHideUnchanged ] = useState(allowHideUnchanged);

    const diffProps = {
        original: feed,
        updated: updatedFeed,
        hideIfUnchanged: hideUnchanged
    };

    const productGroupsChanged = !deepEqual(feed?.productGroups, updatedFeed?.productGroups);

    return (
        <ActivityLogCard
            activityLog={activityLog}
            usersMap={usersMap}
            showExpand={activityLog.type !== ActivityLogType.FEED_DELETED}
            entityNameDisplay={(
                <EntityNameDisplay
                    entityType="feed"
                    label={name}
                    to={feedExists && `/apps/ppm/feeds/${id}/edit`}
                />
            )}
        >
            <DiffContainer
                hideUnchanged={hideUnchanged}
                setHideUnchanged={setHideUnchanged}
                allowHideUnchanged={allowHideUnchanged}
            >
                <DiffLabeledValue
                    {...diffProps}
                    label="Feed name"
                    property="name"
                />

                <DiffLabeledValue
                    {...diffProps}
                    label="Feed type"
                    property="feedType"
                    renderValue={(value) => (value === 'grouped'
                        ? 'Grouped product list'
                        : 'Flat product list')}
                />

                {(!hideUnchanged || productGroupsChanged) ? (
                    <>
                        <DiffHeader header="Product groups" />

                        <FeedProductGroupDiffs
                            feed={feed}
                            updatedFeed={updatedFeed}
                            hideUnchanged={hideUnchanged}
                        />
                    </>
                ) : null}
            </DiffContainer>
        </ActivityLogCard>
    );
}
