import React  from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';

import { emptyFeedProduct, FeedFormValues } from '../FeedForm';

import FeedProductCard from './FeedProductCard';
import * as FeedTypeHelpText from './FeedTypeHelpText';
import ProductOrGroupsLabel from './ProductOrGroupsLabel';


interface FlatFieldsProps {
    flatProductsName: 'productGroups.0.products';
    flatProductsArray: UseFieldArrayReturn<FeedFormValues, 'productGroups' | `productGroups.${number}.products`, 'id'>;
}

export default function FlatFeedFields({ flatProductsArray, flatProductsName }: FlatFieldsProps) {
    const { length } = flatProductsArray.fields;

    return (
        <>
            <ProductOrGroupsLabel
                title={FeedTypeHelpText.FLAT_FEED_TYPE}
            >
                Products *
            </ProductOrGroupsLabel>

            {flatProductsArray.fields.map((field, index) => (
                <FeedProductCard
                    key={field.id}
                    index={index}
                    productsNameBase={flatProductsName}
                    onDeleteClick={length > 1
                        ? () => flatProductsArray.remove(index)
                        : () => flatProductsArray.update(index, emptyFeedProduct)}
                    onUpClick={index === 0
                        ? undefined
                        : () => flatProductsArray.swap(index, index - 1)}
                    onDownClick={index === length - 1
                        ? undefined
                        : () => flatProductsArray.swap(index, index + 1)}
                />
            ))}
        </>
    );
}
