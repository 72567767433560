import { apiUtils } from '../api-utils';
import { License, Tenant } from '../framework/framework-api';

import { User } from './user-api';


// TODO move this out of ppm

export function createTenant(payload: CreateTenantPayload) {
    return apiUtils.post('/tenant', payload);
}

export function updateTenant(payload: UpdateTenantPayload): Promise<Tenant> {
    return apiUtils.put('/tenant', payload);
}

export async function deleteTenant(id: string): Promise<void> {
    await apiUtils.delete(`/tenant?id=${id}`);
}

export function updateEllieConfig(payload: EllieConfiguration): Promise<EllieConfiguration> {
    return apiUtils.put('/tenant/ellieconfiguration', payload);
}

export function getTenants(): Promise<TenantWithLicense[]> {
    return apiUtils.get('/tenant?licenses=true');
}

export enum LicenseStatus {
    Initial = 1,
    Pending = 2,
    Active = 3,
    Cancelled = 4,
    Suspended = 5,
    Expired = 6,
}

export const LicenseStatusDisplay = {
    [LicenseStatus.Active]: 'Active',
    [LicenseStatus.Expired]: 'Past Due',
    [LicenseStatus.Suspended]: 'Suspended',
    [LicenseStatus.Cancelled]: 'Canceled',
    [LicenseStatus.Pending]: 'Pending billing',
    [LicenseStatus.Initial]: 'Initial' // TODO: Better naming
};

export interface EllieConfiguration {
    instanceName: string;
    clientId: string;
    clientSecret: string;
    encryptedClientSecret?: string;
    enabled: boolean;
}

export interface Address { // TODO move to common
    street: string;
    city: string;
    state: string;
    zip: string;
}

export interface TenantWithLicense extends Tenant {
    license: License;
}

export interface CreateTenantPayload extends Omit<Tenant, 'primaryContact'> {
    primaryContact: Pick<User, 'firstName' | 'lastName' | 'encompassUserEmail'>;
    isManualPayment: boolean;
}

type UpdateTenantPayload = Omit<CreateTenantPayload, 'isManualPayment'>;
