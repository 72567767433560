import { LoanProgram, ScenarioResult } from '@api';
import { Button, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
// @ts-ignore there aren't any TS types for the virtualized renderer
import virtualizedRenderer from 'react-syntax-highlighter-virtualized-renderer';
import prism from 'react-syntax-highlighter/dist/esm/styles/prism/material-light';

import styles from '../ScenarioPreviewSection.module.scss';

import ProductResultCard from './ProductResultCard';


interface ScenarioPreviewResultsProps {
    results: ScenarioResult | undefined;
    showJSON: boolean;
    resultsJSON: string | undefined;
}

export default function ScenarioPreviewResults({ results, showJSON, resultsJSON }: ScenarioPreviewResultsProps) {
    const eligible: LoanProgram[] = [];
    const filtered: LoanProgram[] = [];
    const ineligible: LoanProgram[] = [];

    results?.loanPrograms?.forEach((program) => {
        if (program.rateDetails) {
            eligible.push(program);
        } else if (program.filteredRates) {
            filtered.push(program);
        } else {
            ineligible.push(program);
        }
    });

    const [ showIneligible, setShowIneligible ] = useState(false);
    const [ showFiltered, setShowFiltered ] = useState(false);

    if (!results) {
        return (
            <Typography
                variant="body2"
                color="textSecondary"
            >
                Preview the available products for your scenario here.
            </Typography>
        );
    }

    if (showJSON) {
        return (
            <SyntaxHighlighter
                language="json"
                style={prism}
                renderer={virtualizedRenderer({
                    rowHeight: 19
                })}
            >
                {resultsJSON!}
            </SyntaxHighlighter>
        );
    }

    return !results.loanPrograms ? (
        <Typography
            variant="body2"
            color="textSecondary"
        >
            No products found.
        </Typography>
    ) : (
        <div className={styles.results}>
            {!eligible?.length ? (
                <Typography color="textSecondary">
                    No eligible loan programs
                </Typography>
            ) : (
                <>
                    <Typography
                        color="textSecondary"
                        className={styles.additionalConfigText}
                    >
                        The following products will be available for additional
                        configuration after this scenario is saved.
                    </Typography>

                    {eligible?.map((program) => (
                        <ProductResultCard
                            key={`${program.programID}${program.lockDays}`}
                            loanProgram={program}
                        />
                    ))}
                </>
            )}

            {filtered && filtered.length > 0 && (
                <Tooltip
                    title={showFiltered
                        ? ''
                        : 'Click to view products that don\'t have rates that meet the specified par rate range'}
                >
                    <Button
                        onClick={() => setShowFiltered(!showFiltered)}
                        className={styles.productsButton}
                    >
                        {showFiltered ? 'Hide ' : 'Show '}

                        filtered products ({filtered.length})
                    </Button>
                </Tooltip>
            )}

            {showFiltered && filtered?.map((program) => (
                <ProductResultCard
                    key={`${program.programID}${program.lockDays}`}
                    loanProgram={program}
                />
            ))}

            {ineligible && ineligible.length > 0 && (
                <Button
                    onClick={() => setShowIneligible(!showIneligible)}
                    className={styles.productsButton}
                >
                    {showIneligible ? 'Hide ' : 'Show '}

                    ineligible products ({ineligible.length})
                </Button>
            )}

            {showIneligible && ineligible?.map((program) => (
                <ProductResultCard
                    key={`${program.programID}${program.lockDays}`}
                    loanProgram={program}
                />
            ))}
        </div>
    );
}
