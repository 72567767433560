import api, { PaymentType, Tenant } from '@api';
import {
    CheckCircleOutline, Edit, History, WarningAmberOutlined
} from '@mui/icons-material';
import {
    Button, IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { fetchPaymentInfo, getCurrentPaymentInfo, needsACHVerification } from '@redux/payment-info';
import { useDispatch, useSelector } from '@redux/store';
import {
    AddressDisplay, EmailLink, LabeledValue, RoutedDialogManager, PhoneTypography, Loader, usePageMessage
} from '@tsp-ui/components';
import {
    useAsyncEffect, parseAndFormatISO, formatCurrencyCents
} from '@tsp-ui/utils';
import { useHasPermission } from '@views/common/hooks/useHasPermission';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, useCallback, useState
} from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import useSwr from 'swr';

import Page from '../../common/components/Page';

import styles from './AccountPage.module.scss';
import ACHVerificationDialog from './components/ACHVerificationDialog';
import EditAccountDialog from './components/EditAccountDialog';
import EditBillingInfoDialog from './components/EditBillingInfoDialog';
import InvoiceHistoryDialog from './components/InvoiceHistoryDialog';
import PaymentTypeIcon from './components/PaymentTypeIcon';
import PrimaryContactDisplay from './components/PrimaryContactDisplay';


const routes = {
    verify: ACHVerificationDialog
};

export default function AccountPage() {
    const { isFrameworkAdmin } = useHasPermission();

    const { data: tenant, isLoading } = useSwr('/tenant/me', api.framework.getCurrentTenant); // TODO tenant

    const [ showEdit, setShowEdit ] = useState(false);
    const [ showEditBilling, setShowEditBilling ] = useState(false);
    const [ showInvoiceHistory, setShowInvoiceHistory ] = useState(false);

    return (
        <Page
            header="Account"
            loading={isLoading}
        >
            <div className={styles.root}>
                <div className={styles.leftPanel}>
                    <AccountDetailsCard
                        hasAdminPermission={isFrameworkAdmin}
                        onEditClick={() => setShowEdit(true)}
                        tenant={tenant}
                    />

                    {/* <BillingDetailsCard
                        setShowInvoiceHistory={setShowInvoiceHistory}
                        setShowEditBilling={setShowEditBilling}
                    /> */}
                </div>
            </div>

            {isFrameworkAdmin && (
                <EditAccountDialog
                    open={showEdit}
                    onClose={() => setShowEdit(false)}
                    tenant={tenant}
                />
            )}

            {isFrameworkAdmin && (
                <RoutedDialogManager routes={routes} />
            )}

            <EditBillingInfoDialog
                open={showEditBilling}
                onClose={() => setShowEditBilling(false)}
            />

            <InvoiceHistoryDialog
                open={showInvoiceHistory}
                onClose={() => setShowInvoiceHistory(false)}
            />
        </Page>
    );
}

interface AccountDetailsCardProps {
    hasAdminPermission: boolean;
    onEditClick: () => void;
    tenant: Tenant | undefined;
}

function AccountDetailsCard({ hasAdminPermission, onEditClick, tenant }: AccountDetailsCardProps) {
    return !tenant ? null : (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <Typography
                variant="h6"
                className={styles.header}
            >
                {tenant.name}

                <Tooltip
                    title={hasAdminPermission
                        ? 'Edit account details'
                        : 'You don\'t have permission to edit account details'}
                >
                    <span>
                        <IconButton
                            edge="end"
                            disabled={!hasAdminPermission}
                            onClick={onEditClick}
                        >
                            <Edit color={hasAdminPermission ? 'secondary' : 'disabled'} />
                        </IconButton>
                    </span>
                </Tooltip>
            </Typography>

            <div className={styles.content}>
                <LabeledValue
                    variants={{ label: 'body2' }}
                    label="Phone number"
                    value={(
                        <PhoneTypography component="span">
                            {tenant.phoneNumber}
                        </PhoneTypography>
                    )}
                />

                <LabeledValue
                    variants={{ label: 'body2' }}
                    label="Invoice email"
                    value={(
                        <EmailLink email={tenant.invoiceEmail} />
                    )}
                />

                <LabeledValue
                    variants={{ label: 'body2' }}
                    label="Address"
                    value={(
                        <AddressDisplay address={tenant} />
                    )}
                />

                <LabeledValue
                    variants={{ label: 'body2' }}
                    label="Primary contact"
                    value={(
                        <PrimaryContactDisplay contact={tenant.primaryContact} />
                    )}
                />
            </div>
        </Paper>
    );
}

interface BillingDetailsCardProps {
    setShowInvoiceHistory: Dispatch<SetStateAction<boolean>>;
    setShowEditBilling: Dispatch<SetStateAction<boolean>>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function BillingDetailsCard({
    setShowEditBilling, setShowInvoiceHistory
}: BillingDetailsCardProps) {
    const dispatch = useDispatch();
    const pageMessage = usePageMessage();

    const needsVerification = useSelector(needsACHVerification);
    const paymentInfo = useSelector(getCurrentPaymentInfo);

    const [ paymentInfoLoading, setPaymentInfoLoading ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        setPaymentInfoLoading(true);
        const result = await dispatch(fetchPaymentInfo());

        if (fetchPaymentInfo.rejected.match(result)) {
            const error = result.payload as any;
            pageMessage.handleApiError('An error occurred while fetching the payment info', error);
        }

        setPaymentInfoLoading(false);
    }, [ dispatch, pageMessage ]));

    return (
        <Paper
            variant="outlined"
            className={clsx(styles.card, styles.billingDetails)}
        >
            <Typography
                variant="h6"
                className={styles.header}
            >
                Billing details

                {paymentInfo && (
                    <div>
                        <Tooltip title="View invoice history">
                            <IconButton onClick={() => setShowInvoiceHistory(true)}>
                                <History color="action" />
                            </IconButton>
                        </Tooltip>


                        <Tooltip title="Edit billing details">
                            <IconButton
                                edge="end"
                                onClick={() => setShowEditBilling(true)}
                            >
                                <Edit color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </Typography>

            {paymentInfoLoading ? (
                <Loader loading />
            ) : paymentInfo ? (
                <>
                    <div className={styles.subscriptionInfo}>
                        <PaymentTypeIcon paymentType={paymentInfo.paymentType} />

                        {paymentInfo.last4 && (
                            <Typography
                                color="textSecondary"
                                fontWeight={500}
                            >
                                {paymentInfo.paymentType === PaymentType.Check ? (
                                    'Check'
                                ) : (
                                    `*${paymentInfo.last4}`
                                )}
                            </Typography>
                        )}

                        {needsVerification ? (
                            <WarningAmberOutlined
                                color="warning"
                                className={styles.subscriptionStatusIcon}
                            />
                        ) : (
                            <CheckCircleOutline
                                color="success"
                                className={styles.subscriptionStatusIcon}
                            />
                        )}

                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            {needsVerification ? 'Trial period' : 'Subscription'} active
                        </Typography>
                    </div>

                    {needsVerification ? (
                        <>
                            <Typography>
                                Bank account verification is required
                            </Typography>

                            <Button
                                component={Link}
                                to="account/verify"
                                className={styles.cardButton}
                            >
                                Verify bank account
                            </Button>
                        </>
                    ) : paymentInfo.amountOnNextBill !== undefined && (
                        <Typography>
                            {`Next payment of ${formatCurrencyCents(paymentInfo.amountOnNextBill)} due
                                ${parseAndFormatISO(paymentInfo.nextPaymentDate)}`}
                        </Typography>
                    )}
                </>
            ) : (
                <>
                    <Typography>
                        You don't have any billing information yet.
                    </Typography>

                    <Button
                        component={RouterLink}
                        to="/billing"
                        className={styles.enterBillingButton}
                    >
                        Enter billing details
                    </Button>
                </>
            )}
        </Paper>
    );
}
