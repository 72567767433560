import { getCurrentPrice, Price, License } from '@api';
import {
    Link, Paper, Typography
} from '@mui/material';
import { useDispatch } from '@redux/store';
import { openSupportDialog } from '@redux/support';
import { Loader, usePageMessage } from '@tsp-ui/components';
import { useAsyncEffect, formatCurrencyCents } from '@tsp-ui/utils';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '../../common/components/Page';

import styles from './BillingPage.module.scss';
import BankAccountDialog from './components/BankAccountDialog';
import CreditCardDialog from './components/CreditCardDialog';
import SelectPaymentMethod from './components/SelectPaymentMethod';


export default function BillingPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pageMessage = usePageMessage(250);

    const [ loading, setLoading ] = useState(false);
    const [ price, setPrice ] = useState<Price>();

    useAsyncEffect(useCallback(async () => {
        try {
            setLoading(true);
            setPrice(await getCurrentPrice());
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the current billing information', error);
        }

        setLoading(false);
    }, [ pageMessage ]));

    const [ isCreditCardOpen, setCreditCardOpen ] = useState(false);
    const [ isBankOpen, setBankOpen ] = useState(false);

    function onBillingModalClose(license: License | undefined, setDialogOpenState: (open: boolean) => void) {
        setDialogOpenState(false);

        if (license) {
            // TODO update swr with new license
            // licenseUpdated(license);

            navigate('/account', { replace: true });
            pageMessage.success('Subscription activated');
        }
    }


    const {
        unit_amount: unitAmount,
        recurring: {
            interval_count: intervalCount,
            interval
        }
    } = price || { recurring: {} };

    return (
        <Page header="Activate subscription">
            {loading ? (
                <Loader loading />
            ) : (
                <div className={styles.root}>
                    <Paper className={styles.paper}>
                        <Typography
                            variant="h4"
                            align="center"
                        >
                            {unitAmount !== undefined && formatCurrencyCents(unitAmount)}
                        </Typography>

                        <Typography align="center">
                            Billed every {intervalCount === 1 ? interval : interval}
                        </Typography>

                        <SelectPaymentMethod
                            onCreditCardClick={() => setCreditCardOpen(true)}
                            onBankClick={() => setBankOpen(true)}
                        />
                    </Paper>

                    <Typography>
                        Need a different way to pay?
                        {' '}

                        <Link onClick={() => dispatch(openSupportDialog('Sales'))}>
                            Contact us
                        </Link>
                        .
                    </Typography>
                </div>
            )}

            <CreditCardDialog
                open={isCreditCardOpen}
                onClose={(updatedLicense) => onBillingModalClose(updatedLicense, setCreditCardOpen)}
            />

            <BankAccountDialog
                open={isBankOpen}
                onClose={(updatedLicense) => onBillingModalClose(updatedLicense, setBankOpen)}
            />
        </Page>
    );
}
