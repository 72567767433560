import api from '@api';
import { Button, DialogContent, DialogContentText } from '@mui/material';
import { DialogActions, FormDialog, TextField } from '@tsp-ui/components';
import { usePageMessage } from '@tsp-ui/components';
import { useAsyncEffect } from '@tsp-ui/utils';
import { Auth } from 'aws-amplify';
import { useCallback, useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { AuthenticationContext } from '../../../AuthenticationContext';


interface VerifyEmailDialogProps {
    open: boolean;
    setIsEmailVerified: (isVerified: boolean) => void
}

/**
 * Renders a dialog to verify the email of the currently signed in user
 *
 * @param open                - whether the dialog is currently open
 * @param setIsEmailVerified  - a function to set the email as verified, since it is used in a higher scope
 * @constructor
 */
export default function VerifyEmailDialog({
    open, setIsEmailVerified
}: VerifyEmailDialogProps) {
    const formMethods = useForm<{ code: string }>();

    const [ loading, setLoading ] = useState(false);
    const [ hasSentVerificationCodeLast60Seconds, setHasSentVerificationCodeLast60Seconds ] = useState(false);
    const pageMessage = usePageMessage(250);

    const { user } = useContext(AuthenticationContext);

    useAsyncEffect(useCallback(async () => {
        if (user) {
            const userInfo = await Auth.currentUserInfo();

            if (!userInfo?.attributes?.email_verified) {
                await api.ppm.user.sendEmailVerificationCode();
                setHasSentVerificationCodeLast60Seconds(true);
                setTimeout(() => setHasSentVerificationCodeLast60Seconds(false), 60000);
            }
        }
    }, [ user ]));

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        if (!formValues.code) {
            return;
        }

        setLoading(true);
        try {
            await Auth.verifyCurrentUserAttributeSubmit('email', formValues.code);

            setIsEmailVerified(true);
            pageMessage.success('Successfully verified');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while submitting verification', error);
        } finally {
            setLoading(false);
        }
    });

    return (
        <FormDialog
            maxWidth="xs"
            open={open}
            title="Verify email"
            onSubmit={handleSubmit}
        >
            <DialogContent>
                <DialogContentText>
                    Email verification is required. We sent a verification code to{' '}

                    {user?.encompassUserEmail}. Enter the code here.
                </DialogContentText>

                <br />

                <FormProvider {...formMethods}>
                    <TextField<{ code: string }>
                        name="code"
                        label="Verification code"
                        required
                    />
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    onClick={() => {
                        setHasSentVerificationCodeLast60Seconds(true);
                        api.ppm.user.sendEmailVerificationCode();
                        setTimeout(() => setHasSentVerificationCodeLast60Seconds(false), 60000);
                    }}
                    disabled={loading || hasSentVerificationCodeLast60Seconds}
                >
                    Send verification code
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                >
                    Verify
                </Button>
            </DialogActions>
        </FormDialog>
    );
}
