import api, { ACHVerification } from '@api';
import {
    Button, DialogContent, InputAdornment, Typography 
} from '@mui/material';
import { fetchPaymentInfo, getCurrentPaymentInfo } from '@redux/payment-info';
import { useDispatch, useSelector } from '@redux/store';
import {
    DialogActions, RoutedDialog, RoutedDialogImplProps, TextField, usePageMessage
} from '@tsp-ui/components';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';


import styles from './ACHVerificationDialog.module.scss';


const formID = 'ach-verification-form';

export default function ACHVerificationDialog(props: RoutedDialogImplProps) {
    const dispatch = useDispatch();
    const pageMessage = usePageMessage(250);
    const navigate = useNavigate();

    const paymentInfo = useSelector(getCurrentPaymentInfo);
    const formMethods = useForm<ACHVerification>();
    const [ loading, setLoading ] = useState(false);

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            await api.ppm.payment.verifyACH(formValues);

            await dispatch(fetchPaymentInfo());
            navigate(props.closeTo, { replace: true });

            pageMessage.success('Bank account verified');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while verifying bank account information', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            maxWidth="xs"
            title="Verify bank account"
            loading={!paymentInfo}
            {...props}
        >
            <DialogContent>
                <Typography>
                    We sent two test deposits to your bank account ending in{' '}

                    <b>{paymentInfo?.last4}</b>. Please enter the amount, in cents, of those two deposits.
                </Typography>

                <form
                    id={formID}
                    noValidate
                    onSubmit={handleSubmit}
                    className={styles.form}
                >
                    <FormProvider {...formMethods}>
                        <TextField
                            name="amountOne"
                            label="Deposit one"
                            maskProps={{}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        disableTypography
                                        className={styles.adornment}
                                    >
                                        $0.
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    maxLength: 2
                                }
                            }}
                            required
                        />

                        <TextField
                            name="amountTwo"
                            label="Deposit two"
                            maskProps={{}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        disableTypography
                                        className={styles.adornment}
                                    >
                                        $0.
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    maxLength: 2
                                }
                            }}
                            required
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    form={formID}
                    variant="contained"
                >
                    Submit
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
