import {
    Feed, FeedWithTokens, WidgetTheme
} from '@api';
import { Calculate, DynamicFeed } from '@mui/icons-material';
import {
    Button, DialogContent, MenuItem, Paper, TextField, Typography
} from '@mui/material';
import { getAppConfig } from '@redux/config';
import { getFeedsList, useEntitySelector } from '@redux/entities';
import { useSelector } from '@redux/store';
import { CopyButton, Dialog, DialogProps } from '@tsp-ui/components';
import {
    useMemo, useState
} from 'react';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import prism from 'react-syntax-highlighter/dist/esm/styles/prism/material-light';

import { SelectableButton } from '../../../components/SelectableButton';

import styles from './EmbedWidgetDialog.module.scss';


interface EmbedWidgetDialogProps extends Omit<DialogProps, 'title'> {
    feed?: Feed;
    theme?: WidgetTheme;
    calculatorId?: string;
}

export default function EmbedWidgetDialog({
    open,
    feed: feedProp,
    theme,
    calculatorId,
    ...props
}: EmbedWidgetDialogProps) {
    const { widgetAssetUrls } = useSelector(getAppConfig) || {};

    const [ feedId, setFeedId ] = useState(feedProp?.id || '');
    const [ variant, setVariant ] = useState<'rate' | 'calculator' | undefined>(feedProp ? 'rate' : undefined);

    const feeds = useEntitySelector({
        entityType: 'feeds',
        selector: getFeedsList,
        forceRefresh: true
    });

    const feed = feeds?.find((feed) => feed.id === feedId);
    const code = useEmbeddedCode(feed, theme, calculatorId);

    return (
        <Dialog
            {...props}
            title="Embed widget"
            open={open}
            keepMounted
            maxWidth={false}
            loading={!widgetAssetUrls || props.loading}
        >
            <DialogContent>
                {!feedProp && (
                    <>
                        <Typography align="center">
                            Which type of widget would you like to embed?
                        </Typography>

                        <div className={styles.widgetTypeButtons}>
                            <SelectableButton
                                selected={variant === 'rate'}
                                onClick={() => setVariant('rate')}
                                className={styles.widgetTypeButton}
                            >
                                <DynamicFeed
                                    color="secondary"
                                    className={styles.icon}
                                />

                                Feed rate widget
                            </SelectableButton>

                            <SelectableButton
                                selected={variant === 'calculator'}
                                onClick={() => {
                                    setVariant('calculator');
                                    setFeedId('');
                                }}
                                className={styles.widgetTypeButton}
                            >
                                <Calculate
                                    color="secondary"
                                    className={styles.icon}
                                />

                                Calculator widget
                            </SelectableButton>
                        </div>
                    </>
                )}

                {!feedProp && variant === 'rate' && (
                    <>
                        <Typography align="center">
                            Which feed would you like to embed the rate widget for?
                        </Typography>

                        <div className={styles.feedSelectContainer}>
                            <TextField
                                label="Feed to embed"
                                value={feedId}
                                onChange={(event) => setFeedId(event.target.value)}
                                select
                            >
                                {feeds?.map((feed => (
                                    <MenuItem
                                        key={feed.id}
                                        value={feed.id}
                                    >
                                        {feed.name}
                                    </MenuItem>
                                )))}
                            </TextField>
                        </div>
                    </>
                )}

                {(variant === 'calculator' || (variant === 'rate' && feedId)) && (
                    <>
                        <Typography className={styles.instructions}>
                            Copy the following code and insert it into the HTML of your website

                            <CopyButton
                                textToCopy={code}
                                successMessage="Widget code copied"
                                children={({ onClick }) => (
                                    <Button onClick={onClick}>
                                        Copy code
                                    </Button>
                                )}
                            />
                        </Typography>

                        <Paper
                            variant="outlined"
                            className={styles.codePaper}
                        >
                            <SyntaxHighlighter
                                language="html"
                                style={prism}
                            >
                                {code}
                            </SyntaxHighlighter>
                        </Paper>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}

function useEmbeddedCode(
    feed: FeedWithTokens | undefined,
    theme: WidgetTheme | undefined,
    calculatorId: string | undefined
) {
    const { apiUrl, widgetAssetUrls } = useSelector(getAppConfig) || {};

    return useMemo(() => (`<div id="${feed ? 'ppm-rate-widget' : 'ppm-calculator-widget'}"></div>
<script>
    const cssPromise = injectLink('https://ice-products-dev-widget-bucket.s3.us-east-1.amazonaws.com/ppm-widgets.css');
    const fontPromise = injectLink('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
    
    function ppm_onWidgetLoad() {
        window.PPMWidgets.registerAssetsToAwait(cssPromise, fontPromise);
        
        window.PPMWidgets.configure({
            urlBase: '${apiUrl}/products/ppm',
            theme: ${!theme ? 'undefined' : `{
                primaryColor: '${theme.primaryColor}',
                secondaryColor: '${theme.secondaryColor}',
                calculatorBackgroundColor: '${theme.calculatorBackgroundColor}',
                rateWidgetBackgroundColor: '${theme.rateWidgetBackgroundColor}'
            }`} 
        });
        
        ${feed ? `window.PPMWidgets.initRateWidget(document.getElementById('ppm-rate-widget'), {
            id: '${feed?.id}',
            feedToken: '${feed?.feedTokens?.[0].token}'
        });` : `window.PPMWidgets.initCalculator(document.getElementById('ppm-calculator-widget'), {
            id: '${calculatorId}'
        });`}
    }
    
    function injectLink(url) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
    
        const promise = new Promise((resolve) => {
            link.addEventListener('load', () => setTimeout(resolve, 50));
        });
        
        document.head.appendChild(link);
        
        return promise;
    }
</script>
<script
    async
    src="${widgetAssetUrls?.js}"
    onload="ppm_onWidgetLoad()"
></script>`), [
        apiUrl, widgetAssetUrls, feed, theme, calculatorId
    ]);
}
