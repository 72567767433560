import { apiUtils } from '../api-utils';

import { LoanProgram } from './scenario-api';


export function getFeeds(): Promise<FeedWithTokens[]> {
    return apiUtils.get('/products/ppm/feed');
}

export function createFeed(feed: Feed): Promise<Feed> {
    return apiUtils.post('/products/ppm/feed', feed);
}

export function updateFeed(feed: Feed): Promise<Feed> {
    return apiUtils.put('/products/ppm/feed', feed);
}

export function testFeed(feed: Feed): Promise<FeedResult> {
    return apiUtils.post('/products/ppm/feed/test', feed);
}

export async function deleteFeed(feedID: string): Promise<void> {
    await apiUtils.delete(`/products/ppm/feed?id=${feedID}`);
}

export function getFeedTokens(feedID: string): Promise<FeedToken[]> {
    return apiUtils.get(`/products/ppm/feed/token?feedID=${feedID}`);
}

export function createFeedToken(token: FeedToken) {
    return apiUtils.post('/products/ppm/feed/token', token);
}

export function updateFeedToken(token: FeedToken) {
    return apiUtils.put('/products/ppm/feed/token', token);
}

export function deleteFeedToken(feedID: string, tokenID: string): Promise<void> {
    return apiUtils.delete(`/products/ppm/feed/token?feedID=${feedID}&tokenID=${tokenID}`);
}

export interface Feed {
    id: string;
    name: string;
    productGroups: ProductGroup[];
    lastAccessedDate: string | null;
    createdDate: string;
}

export interface FeedWithTokens extends Feed {
    feedTokens: FeedToken[];
}

export interface ProductGroup {
    title: string | null;
    products: FeedProduct[];
}

export interface FeedProduct {
    productId: string;
    scenarioId: string;
}

export interface FeedToken {
    id: string;
    token: string;
    name: string;
    description: string;
    feedId: string;
}

export interface FeedResult {
    name: string;
    productGroupResults: ProductGroupResult[];
}

interface ProductGroupResult {
    title: string | null;
    productResults: FeedProductResult[];
}

interface FeedProductResult {
    productId: string;
    scenarioId: string;
    loanProgram: LoanProgram;
}
