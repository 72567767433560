import { ErrorMessage } from '@hookform/error-message';
import { Category, List } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { RadioGroup, TextField } from '@tsp-ui/components';
import { useFormState } from 'react-hook-form';

import { FeedFormValues } from '../FeedForm';

import styles from './FeedDetailsFields.module.scss';
import FeedTypeButton from './FeedTypeButton';
import * as FeedTypeHelpText from './FeedTypeHelpText';


interface FeedDetailsFieldsProps {
    feedName: string;
}

export default function FeedDetailsFields({ feedName }: FeedDetailsFieldsProps) {
    const { feedType: feedTypeErrors } = useFormState<FeedFormValues>().errors;

    return (
        <>
            <Typography fontWeight={500}>
                {feedName}
            </Typography>

            <TextField<FeedFormValues>
                name="name"
                label="Feed name"
                autoFocus
                required
                helperText="The feed name is just for you, it won't be shown to your customers."
            />

            <div>
                <Typography
                    className={styles.feedTypeLabel}
                    color={feedTypeErrors
                        ? 'error'
                        : 'textSecondary'}
                >
                    Feed type *
                </Typography>

                <RadioGroup<FeedFormValues>
                    name="feedType"
                    className={styles.feedTypeContainer}
                    rules={{
                        required: 'Feed type is required'
                    }}
                >
                    <FeedTypeButton
                        Icon={List}
                        value="flat"
                        tooltipText={FeedTypeHelpText.FLAT_FEED_TYPE}
                    >
                        Flat product list
                    </FeedTypeButton>

                    <FeedTypeButton
                        Icon={Category}
                        value="grouped"
                        tooltipText={FeedTypeHelpText.GROUPED_FEED_TYPE}
                    >
                        Grouped product list
                    </FeedTypeButton>
                </RadioGroup>

                <ErrorMessage
                    name="feedType"
                    render={({ message }) => (
                        <Typography
                            variant="caption"
                            color="error"
                        >
                            {message}
                        </Typography>
                    )}
                />
            </div>
        </>
    );
}
