import {
    ActivityLogType, PaymentMethodUpdate
} from '@api';
import {
    DiffContainer, DiffLabeledValue, DiffIcon
} from '@tsp-ui/components';
import { useState } from 'react';

import PaymentTypeIcon from '../../../../ppm/account/components/PaymentTypeIcon';
import { extractActivityLogEntities } from '../utils/extractActivityLogEntities';

import { ActivityLogCard, ActivityLogCardImplProps } from './ActivityLogCard';


const { PAYMENT_METHOD_UPDATED } = ActivityLogType;

export function PaymentMethodActivityLogCard({ activityLog, usersMap }: ActivityLogCardImplProps<PaymentMethodUpdate>) {
    const [ billingInfo, updatedBillingInfo ] = extractActivityLogEntities(activityLog);

    const allowHideUnchanged = activityLog.type === PAYMENT_METHOD_UPDATED;
    const [ hideUnchanged, setHideUnchanged ] = useState(allowHideUnchanged);

    const diffProps = {
        original: billingInfo,
        updated: updatedBillingInfo,
        hideIfUnchanged: hideUnchanged
    };

    return (
        <ActivityLogCard
            activityLog={activityLog}
            usersMap={usersMap}
            showExpand
            entityNameDisplay={null}
        >
            <DiffContainer
                hideUnchanged={hideUnchanged}
                setHideUnchanged={setHideUnchanged}
                allowHideUnchanged={allowHideUnchanged}
            >
                <DiffLabeledValue
                    {...diffProps}
                    label="Payment type"
                    property="paymentType"
                    renderValue={(value) => (
                        <DiffIcon>
                            <PaymentTypeIcon paymentType={value} />
                        </DiffIcon>
                    )}
                />

                <DiffLabeledValue
                    {...diffProps}
                    label="Payment account"
                    property="last4"
                    renderValue={(value) => `*${value}`}
                />
            </DiffContainer>
        </ActivityLogCard>
    );
}
