import {
    ActivityLogType, Address, Tenant
} from '@api';
import {
    DiffContainer, DiffLabeledValue, EmailLink, AddressDisplay, PhoneTypography, DiffHeader
} from '@tsp-ui/components';
import { useState } from 'react';

import PrimaryContactDisplay from '../../../../ppm/account/components/PrimaryContactDisplay';
import { extractActivityLogEntities } from '../utils/extractActivityLogEntities';

import { ActivityLogCard, ActivityLogCardImplProps } from './ActivityLogCard';


export function AccountActivityLogCard({ activityLog, usersMap }: ActivityLogCardImplProps<Tenant>) {
    const [ tenant, updatedTenant ] = extractActivityLogEntities(activityLog, TENANT_CREATED).map(getTenantWithAddress);

    const allowHideUnchanged = activityLog.type === TENANT_UPDATED;
    const [ hideUnchanged, setHideUnchanged ] = useState(allowHideUnchanged);

    const diffProps = {
        original: tenant,
        updated: updatedTenant,
        hideIfUnchanged: hideUnchanged
    };

    return (
        <ActivityLogCard
            activityLog={activityLog}
            usersMap={usersMap}
            showExpand
            entityNameDisplay={null}
        >
            <DiffContainer
                hideUnchanged={hideUnchanged}
                setHideUnchanged={setHideUnchanged}
                allowHideUnchanged={allowHideUnchanged}
            >
                <DiffHeader
                    header="Account details"
                    hideUnchanged={hideUnchanged}
                />

                <DiffLabeledValue
                    {...diffProps}
                    label="Account name"
                    property="name"
                />

                <DiffLabeledValue
                    {...diffProps}
                    label="Phone"
                    property="phoneNumber"
                    renderValue={(phoneNumber) => (
                        <PhoneTypography>
                            {phoneNumber}
                        </PhoneTypography>
                    )}
                />

                <DiffLabeledValue
                    {...diffProps}
                    label="Billing email"
                    property="invoiceEmail"
                    renderValue={(email) => (
                        <EmailLink email={email} />
                    )}
                />

                <DiffLabeledValue
                    {...diffProps}
                    label="Primary contact"
                    property="primaryContact"
                    renderValue={(contact) => (
                        <PrimaryContactDisplay contact={contact} />
                    )}
                />

                <DiffLabeledValue
                    {...diffProps}
                    label="Address"
                    property="address"
                    renderValue={(address) => (
                        <AddressDisplay address={address} />
                    )}
                />
            </DiffContainer>
        </ActivityLogCard>
    );
}

const { TENANT_CREATED, TENANT_UPDATED } = ActivityLogType;

interface TenantWithAddress extends Tenant {
    address: Address;
}

function getTenantWithAddress(tenant: Tenant | undefined): TenantWithAddress | undefined {
    return tenant && {
        ...tenant,
        address: {
            street: tenant.street,
            city: tenant.city,
            state: tenant.state,
            zip: tenant.zip
        }
    };
}
