import clsx from 'clsx';
import { HTMLProps } from 'react';
import {
    FieldValues, FormProvider, UseFormReturn
} from 'react-hook-form';

import styles from './Form.module.scss';


interface FormProps<TFieldValues extends FieldValues> extends HTMLProps<HTMLFormElement> {
    loading?: boolean;
    formMethods: UseFormReturn<TFieldValues>;
}

export default function Form<TFieldValues extends FieldValues>({
    children, className, loading, onSubmit, formMethods, ...props
}: FormProps<TFieldValues>) {
    return (
        <form
            className={clsx(styles.root, className)}
            noValidate
            {...props}
            onSubmit={onSubmit}
        >
            <FormProvider {...formMethods}>
                {children}
            </FormProvider>
        </form>
    );
}
