import { Header } from '@components';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { setConfig } from '@redux/config';
import { useDispatch } from '@redux/store';
import * as Sentry from '@sentry/react';
import {
    SentryRoutes,
    ConfirmDialog,
    ConfirmDialogContext,
    useConfirmDialogValue,
    PageMessageContext,
    usePageMessageValue
} from '@tsp-ui/components';
import { useAsyncEffect } from '@tsp-ui/utils';
import AuthenticatedRouteSwitch from '@views/common/AuthenticatedRouteSwitch';
import LandingPage from '@views/common/LandingPage';
import LogInPage from '@views/common/LogInPage';
import GlobalErrorMessage from '@views/common/components/GlobalErrorMessage';
import ContactUsDialog from '@views/ppm/billing/components/ContactUsDialog';
import { useCallback, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { SWRConfig } from 'swr';

import {
    AuthenticationContext,
    useAuthenticationContextValue
} from './AuthenticationContext';
import configureApp from './config/configureApp';
import { theme } from './config/mui-theme';


export default function App() {
    const dispatch = useDispatch();

    const [ isConfigError, setConfigError ] = useState(false);

    const confirmDialogValue = useConfirmDialogValue();
    const pageMessageValue = usePageMessageValue();

    const authenticationValue = useAuthenticationContextValue();
    const { initializeSession } = authenticationValue;

    useAsyncEffect(useCallback(async () => {
        try {
            const config = await configureApp();
            dispatch(setConfig(config)); // TODO config

            const { js, css } = config.widgetAssetUrls;

            if (!document.querySelector(`[src="${js}"]`)) {
                const widgetScript = document.createElement('script');
                widgetScript.src = js;
                widgetScript.type = 'application/javascript';
                document.head.appendChild(widgetScript);
            }

            if (!document.querySelector(`[href="${css}"]`)) {
                const widgetCssLink = document.createElement('link');
                widgetCssLink.rel = 'stylesheet';
                widgetCssLink.href = css;
                document.head.appendChild(widgetCssLink);
            }
        } catch (error) {
            Sentry.captureException(error);
            setConfigError(true);
            return;
        }

        try {
            await initializeSession();
        } catch (error) {
            if (error !== 'No current user') {
                Sentry.captureException(error);
            }
        }
    }, [ dispatch, initializeSession ]));

    return (
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ConfirmDialogContext.Provider value={confirmDialogValue}>
                            <PageMessageContext.Provider value={pageMessageValue}>
                                <AuthenticationContext.Provider value={authenticationValue}>
                                    <SWRConfig
                                        value={{
                                            errorRetryCount: 0
                                        }}
                                    >
                                        <CssBaseline />

                                        <ConfirmDialog />

                                        <ContactUsDialog />

                                        {isConfigError ? (
                                            <>
                                                <Header />

                                                <GlobalErrorMessage hideLogout />
                                            </>
                                        ) : (
                                            <SentryRoutes>
                                                <Route
                                                    path="/"
                                                    element={<LandingPage />}
                                                />

                                                <Route
                                                    path="/login"
                                                    element={<LogInPage />}
                                                />

                                                <Route
                                                    path="*"
                                                    element={<AuthenticatedRouteSwitch />}
                                                />
                                            </SentryRoutes>
                                        )}
                                    </SWRConfig>
                                </AuthenticationContext.Provider>
                            </PageMessageContext.Provider>
                        </ConfirmDialogContext.Provider>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    );
}
