import { AccountBalance, CreditCard } from '@mui/icons-material';
import { Button } from '@mui/material';

import styles from './SelectPaymentMethod.module.scss';


interface SelectPaymentMethodProps {
    onCreditCardClick: () => void;
    onBankClick: () => void;
}

export default function SelectPaymentMethod({ onCreditCardClick, onBankClick }: SelectPaymentMethodProps) {
    return (
        <div className={styles.root}>
            <Button
                variant="outlined"
                className={styles.paymentButton}
                onClick={onCreditCardClick}
            >
                <CreditCard
                    color="secondary"
                    className={styles.icon}
                />

                Pay by credit card
            </Button>

            <Button
                variant="outlined"
                className={styles.paymentButton}
                onClick={onBankClick}
            >
                <AccountBalance
                    color="secondary"
                    className={styles.icon}
                />

                Pay by bank transfer
            </Button>
        </div>
    );
}
