import { Header } from '@components';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';


export default function LandingPage() {
    return (
        <>
            <Header />

            <div style={{ margin: 24 }}>
                Stay tuned, more to come
            </div>

            <Button
                component={Link}
                to="/login"
                variant="contained"
                style={{
                    marginLeft: 24
                }}
            >
                Login
            </Button>
        </>
    );
}
